import React from "react";

import { useBirthday } from "../../../hooks/useBirthday";
import Choice from "../../../images/choice.png";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { EmployeeMaintenanceFormType } from "../../../types/api/employeeMaintenance";

type InputProps = {
  formSelectStyle: string;
  register: UseFormRegister<EmployeeMaintenanceFormType>;
  isPreviewVisible: boolean;
  watch: UseFormWatch<EmployeeMaintenanceFormType>;
};

{
  /********************************************************
   * TODO: 粒度を細かくするリファクタの必要性あり
   *******************************************************/
}
export const EmployeeEditBirthdayForm = ({
  register,
  isPreviewVisible,
  formSelectStyle,
  watch,
}: InputProps) => {
  const { setYear, setMonth, setDay } = useBirthday();

  const normalBirthdayFormStyle =
    "ml-5 border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  const checkBirthdayFormStyle =
    "ml-5 rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white ";

  const formStyle = !isPreviewVisible
    ? normalBirthdayFormStyle
    : checkBirthdayFormStyle;

  return (
    <div className="flex relative leading-relaxed">
      <div className={formSelectStyle}>
        <select
          className="appearance-none rounded-lg w-28 h-12 pl-4
          focus:outline-none disabled:opacity-100"
          id="birth-year"
          disabled={isPreviewVisible}
          defaultValue={watch("year")}
          {...register("year")}
        >
          {setYear().map((year) => (
            <option key={year} value={String(year)}>
              {`${year}年`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
      <div className={formStyle}>
        <select
          className="appearance-none rounded-lg w-20 h-12 pl-4
        focus:outline-none disabled:opacity-100"
          id="birth-month"
          disabled={isPreviewVisible}
          defaultValue={watch("month")}
          {...register("month")}
        >
          {setMonth().map((month) => (
            <option key={month} value={String(month)}>
              {`${month}月`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
      <div className={formStyle}>
        <select
          className="appearance-none rounded-lg w-20 h-12 pl-4
        focus:outline-none disabled:opacity-100"
          id="birth-day"
          disabled={isPreviewVisible}
          defaultValue={watch("day")}
          {...register("day")}
        >
          {setDay().map((day) => (
            <option key={day} value={String(day)}>
              {`${day}日`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
    </div>
  );
};
