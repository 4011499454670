import React, { memo, useState } from "react";
import ReactPaginate from "react-paginate";

import EmployeeIcon from "../../../images/employee.png";
import ListIcon from "../../../images/list-icon.png";
import GridIcon from "../../../images/grid-icon.png";
import { Title } from "../../atoms/commons/Title";
import { EmployeeTable } from "../../atoms/employees/EmployeeTable";
import { EmployeeType } from "../../../types/api/employeeList";

export const EmployeeList = memo(
  (props: { employeeList: EmployeeType[]; screenSwitchHandle: () => void }) => {
    const { employeeList, screenSwitchHandle } = props;
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);

    const handlePageChange = (data: { selected: number }) => {
      // eslint-disable-next-line
      let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
      setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
    };

    return (
      <>
        <div className="flex justify-between min-h-147">
          <Title src={EmployeeIcon}>社員紹介</Title>
          <div className="flex mt-14">
            <div className="flex w-32 h-10 py-2.5 pl-5 pr-4 rounded-lg bg-lightGreen-200 bg-opacity-10 relative">
              <img className="w-[21px] h-[20px]" src={ListIcon} />
              <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                リスト
              </span>
            </div>
            <button
              onClick={() => screenSwitchHandle()}
              type="button"
              className="flex w-36 h-10 py-2.5 pl-5 pr-4 rounded-lg relative"
            >
              <img className="w-[21px] h-[20px]" src={GridIcon} />
              <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                グリッド
              </span>
            </button>
          </div>
        </div>
        {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
        {/* <div className="h-12 mt-20 md:flex md:justify-between">
        <p className="text-md leading-relaxed ml-14 md:text-center py-2">
          1 - {offset + perPage} 人 / 全 {employees.length}人中
        </p>
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          表示人数
        </p>
        <CountSelectForm />
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          検索
        </p>
        <EmployeeSearchForm />
        <SearchButton />
      </div> */}
        {/*********************************************************************************/}

        <div className="mt-5 justify-center flex flex-col w-full">
          <table className="py-5 text-md justify-center mx-auto w-full">
            <thead>
              <tr className="border-b leading-none text-lightGreen-200">
                <th className="text-left py-5 w-10"></th>
                <th className="text-left py-5 w-24">名前</th>
                <th className="text-left py-5 w-24">部署</th>
                <th className="text-left py-5 w-14">役職</th>
                <th className="text-left py-5 w-14">職種</th>
                <th className="text-left py-5 w-20"></th>
              </tr>
            </thead>
            {employeeList.slice(offset, offset + perPage).map((employee, i) => (
              <EmployeeTable
                key={i}
                id={employee.id}
                businessName={employee.businessName}
                position={employee.position}
                occupation={employee.occupation}
                departmentName={employee.departmentName}
                profileImage={employee.profileImage}
              />
            ))}
          </table>
        </div>
        <div className="h-40 w-full mt-11 mb-10 flex justify-center">
          <p className="mr-auto text-lg leading-relaxed text-gray-700">
            1 - {offset + perPage} 人 / 全 {employeeList.length}人中
          </p>
          <div className="flex mr-80">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(employeeList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
              marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
              pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
              onPageChange={handlePageChange} // クリック時のfunction
              containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
              activeClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // アクティブなページのliに着くクラス名
              previousClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「<」のliに着けるクラス名
              nextClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「>」のliに着けるクラス名
              disabledClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 使用不可の「<,>」に着くクラス名
              breakClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「...」に着くクラス名
              pageClassName={
                "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              }
              // aタグのクラス名
              pageLinkClassName={"block w-10 h-full text-center no-underline"}
              previousLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              nextLinkClassName={"block w-10 h-full text-center no-underline"}
              disabledLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              breakLinkClassName={"block w-10 h-full text-center no-underline"}
            />
          </div>
        </div>
      </>
    );
  }
);
