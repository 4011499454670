import React, { memo } from "react";

import { useEmployee } from "../../../hooks/employees/useEmployee";
import { useFetchEmployeeList } from "../../../hooks/employees/useFetchEmployeeList";
import { EmployeeGrid } from "../../templates/employees/EmployeeGrid";
import { EmployeeList } from "../../templates/employees/EmployeeList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

export const Employees = memo(() => {
  const { isLoading, employeeList } = useFetchEmployeeList();
  const { isListTable, screenSwitchHandle } = useEmployee();

  if (isLoading) {
    return <LoadingTemplate />;
  }

  return (
    <>
      {isListTable ? (
        <EmployeeGrid
          employeeList={employeeList}
          screenSwitchHandle={screenSwitchHandle}
        />
      ) : (
        <EmployeeList
          employeeList={employeeList}
          screenSwitchHandle={screenSwitchHandle}
        />
      )}
    </>
  );
});
