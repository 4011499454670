import { useCallback } from "react";

import { postContact } from "../../api/apiContact";

export const useSaveContact = () => {
  const saveContact = useCallback(async (e, content) => {
    e.preventDefault();
    const res = await postContact(content);

    if (res.status !== 200) {
      alert("お問い合わせ先のデータの保存に失敗しました");
    }
    alert("お問い合わせ先のデータの保存に成功しました");
  }, []);

  return {
    saveContact,
  };
};
