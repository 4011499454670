import React, { memo, useContext } from "react";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import { RollLinkButton } from "../../atoms/stressCheckManagements/RollLinkButton";
import { StressCheckManagementResponseType } from "../../../types/api/stressCheckManagement";
import { StressCheckSummaryDate } from "../../atoms/stressCheckManagements/StressCheckSummaryDate";
import { StressCheckSummaryOffice } from "../../atoms/stressCheckManagements/StressCheckSummaryOffice";
import { LoginUserContext } from "../../providers/LoginUserProvider";

export const StressCheckToEmployeesManagementList = memo(
  (props: { stressCheckManagement: StressCheckManagementResponseType }) => {
    const { stressCheckManagement } = props;
    const user = useContext(LoginUserContext);
    const userRollAdminBool =
      user.loginUser?.data.user_roll === "01" ? true : false;
    //******ページネーションこのファイルの下部にあるコメントを解除する際に下記の処理もコメント解除する******/
    // const [offset, setOffset] = useState(0);
    // const [perPage] = useState(50);

    // const handlePageChange = (data: { selected: number }) => {
    //   // eslint-disable-next-line
    //   let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    //   setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
    // };

    return (
      <>
        <Title src={StressCheckManagement}>ストレスチェック管理</Title>
        <div className="w-full py-[30px] mt-[40px] border-t border-lightGray-100">
          <div>
            <span className="font-bold text-lg text-lightGreen-200">
              テスト名
            </span>
            <span className="ml-[110px] font-normal text-lg">
              {stressCheckManagement.stress_check_frame.name}
            </span>
          </div>
        </div>
        <div className="py-[40px] mb-[30px] border-y border-lightGray-100">
          <div className="w-full flex space-x-[30px] mb-[30px]">
            <div className="flex-1">
              <RollLinkButton path="employees" buttonFlag={false}>
                ストレスチェック対象者一覧
              </RollLinkButton>
            </div>
            <div className="flex-1">
              <RollLinkButton
                path="subjects"
                buttonFlag={
                  !stressCheckManagement.stress_check_frame.available_api
                    .set_sc_targets
                }
              >
                ストレスチェック対象者選定
              </RollLinkButton>
            </div>
            <div className="flex-1">
              <RollLinkButton
                path="interviews"
                buttonFlag={
                  userRollAdminBool
                    ? !stressCheckManagement.stress_check_frame.available_api
                        .set_interview_targets
                    : true
                }
              >
                面談対象者選定
              </RollLinkButton>
            </div>
            <div className="flex-1">
              <RollLinkButton
                path="sendmail"
                buttonFlag={
                  userRollAdminBool
                    ? !stressCheckManagement.stress_check_frame.available_api
                        .send_mail
                    : true
                }
              >
                メール送信
              </RollLinkButton>
            </div>
          </div>
          <div className="w-full flex space-x-[30px]">
            <div className="flex-1">
              <RollLinkButton
                path="company-analytics"
                buttonFlag={
                  userRollAdminBool
                    ? !stressCheckManagement.stress_check_frame.available_api
                        .get_company_report
                    : true
                }
              >
                全社分析レポート
              </RollLinkButton>
            </div>
            <div className="flex-1">
              <RollLinkButton path="" buttonFlag={userRollAdminBool}>
                集団分析レポート
              </RollLinkButton>
            </div>
            <div className="flex-1">
              <RollLinkButton
                path="interview-guidance/edit"
                buttonFlag={!userRollAdminBool}
              >
                面談指導要否文
              </RollLinkButton>
            </div>
            <div className="flex-1"></div>
          </div>
        </div>
        {userRollAdminBool ? (
          <>
            <StressCheckSummaryDate />
            <StressCheckSummaryOffice
              stressCheckManagement={stressCheckManagement}
            />
          </>
        ) : (
          <></>
        )}

        {/* 下の隙間だけ入れるためのdiv要素 */}
        <div className="pt-[150px]"></div>
        {/********************TODO: 4月リリースの段階では実装しないため、コメントアウト*********************/}
        {/********************************************************
         * TODO: グレーアウトを外すときは直下のdiv要素を削除すること。
         *******************************************************/}
        {/* <div className="bg-[#C4C4C4] text-[#C4C4C4]" aria-disabled={true}>
        <div className="w-full flex items-center mb-[65px]">
          <div className="flex items-center">
            <span className="font-bold text-lg text-lightGreen-200">
              表示件数
            </span>
            <div className="relative leading-relaxed ml-[15px]">
              <img
                className="w-2 h-2 absolute top-1 right-0 m-4 pointer-events-none"
                src={Choice}
              ></img>
              <select className="lg:border border-lightGreen-200 rounded-lg h-[50px] py-2.5 pr-10 pl-7 hover:border-gray-400 focus:outline-none appearance-none">
                <option value={50}>50件</option>
                <option value={100}>100件</option>
                <option value={250}>250件</option>
                <option value={"ALL"}>ALL</option>
              </select>
            </div>
          </div>
          <div className="flex items-center">
            <span className="ml-[50px] font-bold text-lg text-lightGreen-200">
              検索
            </span>
            <div className="w-80 h-12">
              <input
                className="ml-[30px] h-[50px] w-[300px] pl-4 pt-3 pb-1.5 border border-lightGreen-200 rounded-lg"
                placeholder="氏名・社員Noを入力"
              />
            </div>
            <button className="ml-[50px] bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[10px] px-[16px]">
              <span className="font-bold text-lg text-white">検索</span>
            </button>
          </div>
        </div>
        <div className="mt-5 justify-center container flex flex-col">
          <table className="py-5 text-md  justify-center container mx-auto">
            <thead>
              <tr className="border-b leading-none">
                <th className="text-left w-60 font-bold text-lg text-lightGreen-200">
                  氏名
                </th>
              </tr>
            </thead>
            {List.slice(offset, offset + perPage).map((EmployeeList) => (
              <StressCheckToEmployeesManagementListTable
                key={EmployeeList.id}
                name={EmployeeList.name}
              />
            ))}
          </table>
        </div>
        <div className="h-40 w-full px-9 mt-11 mb-10 flex justify-center">
          <p className="mr-auto text-lg leading-relaxed text-gray-700">
            1 - {offset + perPage} 件 / 全 {List.length}件中
          </p>
          <div className="flex mr-80">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(List.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
              marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
              pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
              onPageChange={handlePageChange} // クリック時のfunction
              containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
              activeClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // アクティブなページのliに着くクラス名
              previousClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「<」のliに着けるクラス名
              nextClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「>」のliに着けるクラス名
              disabledClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 使用不可の「<,>」に着くクラス名
              breakClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「...」に着くクラス名
              pageClassName={
                "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              }
              // aタグのクラス名
              pageLinkClassName={"block w-10 h-full text-center no-underline"}
              previousLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              nextLinkClassName={"block w-10 h-full text-center no-underline"}
              disabledLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              breakLinkClassName={"block w-10 h-full text-center no-underline"}
            />
          </div>
        </div>
      </div> */}
        {/*******************************************************************************************/}
      </>
    );
  }
);
