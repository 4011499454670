import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { EmployeeProfileDetail } from "../../templates/employees/EmployeeProfile";
import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { Sidebar } from "../../templates/sidebar/Sidebar";

export const EmployeesProfile = memo(() => {
  const params = useParams();
  const employeeId = params.id;
  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9">
          <EmployeeProfileDetail />
        </div>
        <Footer />
      </div>
    </>
  );
});
