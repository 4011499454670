import { useCallback, useEffect, useState } from "react";

import { getPastOrganizationHistoryList } from "../../api/apiHistory";
import { DepartmentHistoryTreeOutputType } from "../../types/api/organizationHistory";

export const useFetchHistoryDepartmentTree = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pastHistoryList, setPastHistoryList] =
    useState<DepartmentHistoryTreeOutputType>({
      id: 0,
      save_date: "",
      department_history_trees: [
        {
          id: 0,
          department_id: 0,
          parent_id: 0,
          name: "",
          employee_numbers: 0,
          children: [],
        },
      ],
    });

  const fetchPastOrganizationHistoryList = useCallback(
    async (
      organizationFrameId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await getPastOrganizationHistoryList(organizationFrameId);
      if (res.status !== 200) {
        setIsLoading(false);
        return alert("過去組織図のデータ取得に失敗しました");
      }
      setPastHistoryList(res.data.data);
      setLoading(false);
      return {
        pastHistoryList,
      };
    },
    []
  );

  return {
    isLoading,
    pastHistoryList,
    fetchPastOrganizationHistoryList,
  };
};
