import React, { memo } from "react";

import { useUpdateAdminReader } from "../../../hooks/settings/useUpdateAdminReader";
import { EmployeeRollUpdateForm } from "../../templates/settings/EmployeeRollUpdateForm";

export const EmployeeRollUpdate = memo(() => {
  const updateRollObj = useUpdateAdminReader();
  return (
    <>
      <EmployeeRollUpdateForm updateRollObj={updateRollObj} />
    </>
  );
});
