import React, { memo } from "react";

import FaceIcons from "../../../../images/face-icons-3.png";

export const AnalyticsExplanation = memo(() => {
  return (
    <div className="mt-[30px] pb-[40px] pt-[30px] border-t border-lightGray-100">
      <div className="flex">
        <span className="font-bold text-[18px] text-lightGreen-200">
          評価計算の方法
        </span>
        <div className="pl-[50px]">
          <div className="mb-[5px] flex justify-start items-center">
            <div className="min-w-[180px]">
              <span className="font-bold text-[18px]">総合評価：</span>
            </div>
            <span>
              管理医師が各カテゴリの評価等を基に総合的ストレス状況を評価したものです。
            </span>
          </div>
          <div className="mb-[5px] flex justify-start items-center">
            <div className="min-w-[180px]">
              <span className="font-bold text-[18px]">各カテゴリの評価：</span>
            </div>
            <span>各評価項目の評価を相加平均したものです。</span>
          </div>
          <div className="mb-[5px] flex justify-start items-center">
            <div className="min-w-[180px]">
              <span className="font-bold text-[18px]">各評価項目：</span>
            </div>
            <span>
              全受検者の「各評価項目(5 段階)」を相加平均したものです。
            </span>
          </div>
        </div>
      </div>
      <div className="mt-[30px] flex">
        <span className="block basis-1/5 font-bold text-[18px] text-lightGreen-200">
          評価の見方
        </span>

        <img
          src={FaceIcons}
          alt="face-icons.png"
          width={600}
          height={60}
          className=""
        />
      </div>
    </div>
  );
});
