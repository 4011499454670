import React, { FC } from "react";

type Props = {
  cx: number;
  cy: number;
  stroke: string;
};

export const CustomizedDotTheGroup: FC<Props> = (props) => {
  const { cx, cy, stroke } = props;

  return (
    <svg x={cx - 12} y={cy - 12} width={26} height={26} fill="green">
      <circle
        stroke={stroke}
        strokeWidth="2"
        cx="12"
        cy="12"
        r="10"
        fill={stroke}
        fillOpacity="100%"
      />
    </svg>
  );
};
