import React, { memo, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { PastEmployeeGrid } from "../../templates/histories/DepartmentPastEmployeeGrid";
import { PastEmployeeList } from "../../templates/histories/DepartmentPastEmployeeList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { useFetchPastEmployeeList } from "../../../hooks/histories/useFetchPastEmployeeList";
import { usePaginate } from "../../../hooks/usePaginate";

export const PastEmployees = memo(() => {
  const params = useParams();
  const departmentHistoryId = params.id;
  const currentUrl = useLocation();
  const departmentId = currentUrl.pathname.charAt(23);
  const paginateObj = usePaginate();
  const { isLoading, employeeList } = useFetchPastEmployeeList(
    departmentHistoryId,
    departmentId
  );
  const [isListTable, setIsListTable] = useState(true);
  const screenSwitchHandle = () => {
    isListTable ? setIsListTable(false) : setIsListTable(true);
  };
  if (isLoading) {
    return <LoadingTemplate />;
  }
  return (
    <>
      {isListTable ? (
        <PastEmployeeGrid
          employeeList={employeeList}
          screenSwitchHandle={screenSwitchHandle}
          paginateObj={paginateObj}
        />
      ) : (
        <PastEmployeeList
          employeeList={employeeList}
          screenSwitchHandle={screenSwitchHandle}
          paginateObj={paginateObj}
        />
      )}
    </>
  );
});
