import React, { memo } from "react";

import { useSaveContact } from "../../../hooks/contact/useContact";
import { useFetchContact } from "../../../hooks/contact/useFetchContact";
import { ContactsForm } from "../../templates/contacts/ContactsForm";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

export const Contacts = memo(() => {
  const { saveContact } = useSaveContact();
  const { isLoading, contactContent, changeInputValue, setContactContent } =
    useFetchContact();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <ContactsForm
          saveContact={saveContact}
          contactContent={contactContent}
          changeInputValue={changeInputValue}
          setContactContent={setContactContent}
        />
      )}
    </>
  );
});
