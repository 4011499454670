import { useCallback, useState, useEffect } from "react";

import {
  getEmployeeTaskList,
  postEmployeeTaskList,
} from "../../api/apiEmployeeTask";
import {
  EmployeeTaskResponseType,
  updateEmployeeTaskResponseType,
} from "../../types/api/EmployeeTask";

export const useEmployeeTaskList = () => {
  const [checkTask, setcheckTask] = useState(false);
  const [checkTaskBoolean, setcheckTaskBoolean] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isListTable, setIsListTable] = useState(true);
  const screenSwitchHandle = () => {
    isListTable ? setIsListTable(false) : setIsListTable(true);
  };
  const updateEmployeeTask = useCallback(
    async (data: updateEmployeeTaskResponseType) => {
      const req = {
        employee_id: data.employee_id,
        task_id: data.task_id,
        completed: !data.completed,
      };
      const res = await postEmployeeTaskList(req);
      if (res.status !== 204) {
        return alert("タスクの登録に失敗しました");
      }
      setIsLoading(false);
      setcheckTask(req.completed);
    },

    []
  );
  const updateEmployeeTaskList = useCallback(
    async (data: updateEmployeeTaskResponseType) => {
      const req = {
        employee_id: data.employee_id,
        task_id: data.task_id,
        completed: !data.completed,
      };
      const res = await postEmployeeTaskList(req);
      if (res.status !== 204) {
        return alert("タスクの登録に失敗しました");
      }
      setIsLoading(false);
      setcheckTaskBoolean(req.completed);
    },
    []
  );
  const [employeeTaskList, setEmployeeTaskList] =
    useState<EmployeeTaskResponseType>({
      employment: {
        employees: [
          {
            id: 0,
            last_name: "",
            first_name: "",
            task_status: [
              {
                task_id: 0,
                completed: false,
              },
            ],
          },
        ],
        tasks: [
          {
            id: 0,
            name: "",
          },
        ],
      },
      retirement: {
        employees: [
          {
            id: 0,
            last_name: "",
            first_name: "",
            task_status: [
              {
                task_id: 0,
                completed: false,
              },
            ],
          },
        ],
        tasks: [
          {
            id: 0,
            name: "",
          },
        ],
      },
    });
  const fetchEmployeeTaskList = useCallback(async () => {
    const res = await getEmployeeTaskList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("従業員タスクの取得に失敗しました");
    }
    setEmployeeTaskList(res.data.data);

    setIsLoading(false);
  }, []);
  useEffect(() => {
    setcheckTask(false);
    setcheckTaskBoolean(true);
    fetchEmployeeTaskList();
  }, []);

  return {
    isListTable,
    screenSwitchHandle,
    isLoading,
    employeeTaskList,
    checkTaskBoolean,
    checkTask,
    setcheckTaskBoolean,
    updateEmployeeTaskList,
    updateEmployeeTask,
  };
};
