import React from "react";

import StressCheck from "../../../images/stress-check.png";
import { Title } from "../../atoms/commons/Title";
import { SelfCareAdvice } from "../../organisms/stressChecks/SelfCareAdvice";
import { useFetchStressCheckResult } from "../../../hooks/stressChecks/useFetchStressCheckResult";
import { StressCheckGraphAndReport } from "../../organisms/stressChecks/StressCheckGraphAndReport";
import { StressCheckResultBackButton } from "../../atoms/stressChecks/StressCheckResultBackButton";
import FaceIcons from "../../../images/face-icons-2.png";

export const StressCheckResult = () => {
  const { stressCheckResult } = useFetchStressCheckResult();

  return (
    <>
      {stressCheckResult && (
        <>
          <Title src={StressCheck}>{stressCheckResult.name}</Title>
          <div className="mt-[60px]">
            <p className="font-bold text-2xl text-lightGreen-200 mb-[16px]">
              総合評価
            </p>
            <div className="border-y border-lightGray-100 py-[30px]">
              <div className="bg-lightGreen-100 w-full py-[25px] text-center rounded-lg">
                <span className="font-bold text-lg text-lightGreen-200">
                  {stressCheckResult.result.summary}
                </span>
              </div>
            </div>
          </div>
          <div className="py-[30px] border-b border-lightGray-100 ">
            <div className="flex items-center bg-lightGreen-100 w-full py-[25px] text-center rounded-lg">
              <div className="font-bold text-xl text-lightGreen-200 basis-1/5 text-center">
                <p>
                  面談指導要否<br></br>について
                </p>
              </div>
              <div className="basis-4/5 pr-5 text-left">
                <p>{stressCheckResult.result.interview}</p>
              </div>
            </div>
          </div>
          <div className="py-[30px] border-b border-lightGray-100">
            <div className="flex items-center bg-lightGreen-100 w-full py-[25px] text-center rounded-lg">
              <p className="font-bold text-xl text-lightGreen-200 basis-1/5 text-centers">
                解説
              </p>
              <div className="basis-4/5 pr-10 text-left">
                <p>{stressCheckResult.result.detail}</p>
              </div>
            </div>
          </div>
          <div className="pt-[40px] pb-[60px] border-b">
            <p className="font-bold text-2xl text-lightGreen-200">
              顔アイコン・レーダーチャートの見方
            </p>
            <div className="mt-[40px] flex justify-center">
              <img
                src={FaceIcons}
                alt="face-icons.png"
                width={950}
                height={187}
              />
            </div>
          </div>
          {stressCheckResult.result.sections.map((section, index) => (
            <div key={index}>
              <StressCheckGraphAndReport section={section} />
            </div>
          ))}
          <SelfCareAdvice />
          <StressCheckResultBackButton />
        </>
      )}
    </>
  );
};
