import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  path: string;
  placementClassName: string;
  children: React.ReactNode;
};

export const LinkButton: FC<Props> = (props) => {
  const { path, placementClassName, children } = props;

  return (
    <div className={placementClassName}>
      <Link to={path}>
        <button
          className="relative flex-1 py-2 px-4 rounded-lg focus:outline-none border-lightGreen-200 border hover:bg-lightGreen-200 hover:text-white text-lightGreen-200"
          style={{ width: 136, height: 50 }}
        >
          {/********************************************************
           * TODO: 全角の文字が入ってのはキモいので修正したい
           *******************************************************/}
          <span className="text-lg font-w6 leading-relaxed">＋ {children}</span>
        </button>
      </Link>
    </div>
  );
};
