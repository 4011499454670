import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";

export const DepartmentTransitionList = memo(
  (props: {
    HistoryDate: [{ id: number; save_date: string }];
    HistoryRecords: [
      {
        department_id: number;
        continuous_abolition_count: number;
        department_histories: [
          {
            id: number;
            parent_id: number | null;
            name: string;
            abolition: boolean;
          }
        ];
      }
    ];
  }) => {
    const { HistoryDate, HistoryRecords } = props;
    const navigate = useNavigate();
    return (
      <>
        <Title src={EmployeeManagement}>組織図変遷</Title>
        <span className="w-full py-11 text-sm inline-block border-b">
          組織図の変遷を左右のスクロールでご確認ください。
        </span>
        <div className="py-13 overflow-x-auto">
          <table>
            <thead>
              <tr>
                {HistoryDate.map((date) => (
                  <th key={date.id} className="text-left pl-8">
                    <button
                      type="submit"
                      onClick={() =>
                        navigate(`/departments/histories/${date.id}`)
                      }
                      className="pr-20 mb-4 text-left"
                    >
                      <span className="py-3 px-4 font-bold text-lg text-lightGreen-200 hover:bg-[#6CA893]/10 rounded-lg">
                        {date.save_date}
                      </span>
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {HistoryRecords.map((data) => {
                return [
                  <tr key={data.department_id} className="odd:bg-lightGray-50">
                    {/* tdリファクタ→atoms_or_templatesでリファクタ案件 */}
                    {data.department_histories.map((department) => {
                      if (department.abolition === true) {
                        return [
                          <td
                            key={department.id}
                            className="w-80 pr-20 py-2 pl-8"
                          >
                            <span className="py-2 px-6 text-lg bg-[#E9E9E9] rounded-lg">
                              廃止
                            </span>
                          </td>,
                        ];
                      } else if (department.abolition === false) {
                        return [
                          <td
                            key={department.id}
                            className="w-80 pr-20 py-2 pl-8"
                          >
                            <span className="block w-80 pr-20 py-2 text-lg">
                              {department.name}
                            </span>
                          </td>,
                        ];
                      }
                    })}
                  </tr>,
                ];
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
);
