import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import { EmployeeProfileEditFromType } from "../types/api/employee";

// 社員紹介ページリスト取得
export const getEmployeeList = (URL: string) => {
  return api
    .get(URL)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 自己紹介の取得
export const getEmployeeProfileApi = (employeeId: string | undefined) => {
  return api
    .get(`/merchant/employees/${employeeId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 自己紹介編集
export const putEmployeeProfileEdit = (
  data: EmployeeProfileEditFromType,
  employeeId: string | undefined
) => {
  return api
    .put(`/merchant/employees/${employeeId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
