import React, { FC, memo } from "react";

import { useGroupAnalytics } from "../../../../hooks/stressCheckManagements/useGroupAnalytics";
import { AnalyticsResultGraph } from "./AnalyticsResultGraph";
import { GroupAnalyticsListTableHeader } from "../../../atoms/stressCheckManagements/groupAnalytics/GroupAnalyticsListTableHeader";

type Props = {
  comprehensiveEvaluationList: string[];
  num_of_grades: number;
  thresholds: number[][];
  table: {
    the_group_name: string;
    the_group_score: number;
    overall_score: number;
    explanation: string;
    stress_check_info: {
      examinees: number;
      overall_examinees: number;
      highstress: number;
      overall_highstress: number;
      highstress_rate: number;
      overall_highstress_rate: number;
      doctor_interview_targets: number;
      overall_doctor_interview_targets: number;
    };
    sections: {
      name: string;
      score: number;
      overall_score: number;
      comment: string;
      scales: {
        name: string;
        score: number;
        overall_score: number;
      }[];
    }[];
  };
  isFirst: boolean;
};
export const GroupAnalyticsIndividual: FC<Props> = memo((props) => {
  const {
    comprehensiveEvaluationList,
    num_of_grades,
    thresholds,
    table,
    isFirst,
  } = props;

  const { faceIconHandleChange } = useGroupAnalytics();

  if (table.stress_check_info.examinees === 0)
    return (
      <>
        <div className="mt-[140px] pb-[100px]">
          <div className="mt-6">
            <div className="flex pb-[50px]">
              <p
                className={
                  isFirst
                    ? "text-2xl text-lightGreen-200 pb-10 font-bold mr-8"
                    : "hidden"
                }
              >
                個別
              </p>
              <p className="text-lg text-lightGreen-200 font-bold">
                組織名：{table.the_group_name}
              </p>
            </div>
            <div className="w-full flex justify-center">
              <p className="text-4xl">データが存在しません。</p>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className="mt-[140px]">
        <div className="mt-6 flex">
          <div className="w-[500px] whitespace-nowrap">
            <div className="block items-center text-left">
              <div className="flex items-center">
                <p
                  className={
                    isFirst
                      ? "text-2xl text-lightGreen-200 pb-10 font-bold"
                      : "hidden"
                  }
                >
                  個別
                </p>
                <p
                  className={
                    isFirst
                      ? "text-lg text-lightGreen-200 font-bold pb-10 ml-8"
                      : "text-lg text-lightGreen-200 font-bold pb-[44px]"
                  }
                >
                  組織名：{table.the_group_name}
                </p>
              </div>
              <GroupAnalyticsListTableHeader
                list={comprehensiveEvaluationList}
              />
            </div>
          </div>
          <div className="mx-[15px] border-r border-lightGray-100"></div>
          <div className="flex w-full ">
            <div className="pr-[15px] w-1/2">
              <div className="block text-center">
                <p className="h-[43px] w-full text-lg block text-lightGreen-200 font-bold">
                  当該集団
                </p>
                <div className="mt-[30px] w-full text-lg flex justify-center">
                  <div className="flex items-center h-[45px]">
                    <img
                      src={faceIconHandleChange(
                        table.the_group_score,
                        thresholds
                      )}
                      alt="face-icon.png"
                      width={40}
                      height={40}
                    />
                    <div className="ml-5 flex items-end">
                      <span className="text-3xl font-bold">
                        {table.the_group_score}
                      </span>
                      <span className="pl-[7px] text-lg font-bold">/</span>
                      <span className="pl-[5px] text-lg font-bold">
                        {num_of_grades}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal bg-lightGray-50 flex text-center">
                  <p className="w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap">
                    {table.stress_check_info.examinees}
                  </p>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal flex text-center">
                  <p
                    className={
                      table.stress_check_info.highstress_rate >= 50
                        ? "w-[180px] h-[26px] y-[30px] block m-auto inline-block font-bold text-white bg-[#C73333] whitespace-nowrap"
                        : "w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap"
                    }
                  >
                    {table.stress_check_info.highstress}（
                    {table.stress_check_info.highstress_rate}％）
                  </p>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal bg-lightGray-50 flex text-center">
                  <p className="w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap">
                    {table.stress_check_info.doctor_interview_targets}
                  </p>
                </div>
              </div>
            </div>
            <div className="pl-[15px] w-1/2  border-l border-lightGray-100">
              <div className="block text-center">
                <p className="h-[43px] w-full text-lg block text-lightGreen-200 font-bold">
                  全体
                </p>
                <div className="mt-[30px] w-full text-lg flex justify-center">
                  <div className="flex items-center h-[45px]">
                    <img
                      src={faceIconHandleChange(
                        table.overall_score,
                        thresholds
                      )}
                      alt="face-icon.png"
                      width={40}
                      height={40}
                    />
                    <div className="ml-5 flex items-end">
                      <span className="text-3xl font-bold">
                        {table.overall_score}
                      </span>
                      <span className="pl-[7px] text-lg font-bold">/</span>
                      <span className="pl-[5px] text-lg font-bold">
                        {num_of_grades}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal bg-lightGray-50 flex text-center">
                  <p className="w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap">
                    {table.stress_check_info.overall_examinees}
                  </p>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal flex text-center">
                  <p
                    className={
                      table.stress_check_info.overall_highstress_rate >= 50
                        ? "w-[180px] h-[26px] y-[30px] block m-auto inline-block font-bold text-white bg-cautionColor-red whitespace-nowrap"
                        : "w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap"
                    }
                  >
                    {table.stress_check_info.overall_highstress}（
                    {table.stress_check_info.overall_highstress_rate}％）
                  </p>
                </div>
                <div className="h-[45px] w-full mt-2 text-md block font-normal bg-lightGray-50 flex text-center">
                  <p className="w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap">
                    {table.stress_check_info.overall_doctor_interview_targets}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-14 flex bg-lightGreen-400 rounded-[8px]">
          <div className="flex items-center">
            <p className="px-[67px] py-14 text-lightGreen-200 font-bold whitespace-nowrap">
              解説
            </p>
          </div>
          <p className="py-14 pr-14 text-md">{table.explanation}</p>
        </div>
        <p className="text-lightGreen-200 font-bold text-2xl mt-20">
          改善のアドバイス
        </p>
        {table.sections.map((section) => (
          <div
            key={section.name}
            className="mt-11 flex bg-lightGreen-400 rounded-[8px]"
          >
            <div className="w-1/3 px-[47px] flex items-center text-center">
              <p className="  py-8 text-lightGreen-200 font-bold">
                {section.name}
              </p>
            </div>
            <p className="w-2/3 py-8 pr-14 text-md">{section.comment} </p>
          </div>
        ))}
        {/* グラフ */}
        <div className="w-full pb-[100px]">
          {table.sections.map((section) => (
            <div key={section.name}>
              <AnalyticsResultGraph
                the_group_data={section}
                num_of_grades={num_of_grades}
                thresholds={thresholds}
              />
              <div className="mt-11 flex bg-lightGreen-400 rounded-[8px] justify-center">
                <p className=" py-8 px-14 text-lg text-center">
                  {section.comment}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});
