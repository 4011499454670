import React from "react";
import {
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  UseFormHandleSubmit,
  FieldErrors,
  UseFormGetValues,
  UseFormReset,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  FieldArrayWithId,
} from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee-management.png";
import EmployeeManagementPlusBtn from "../../../images/employee-plus-btn.png";
import { EmployeeCreateBirthdayForm } from "../../atoms/employeeMaintenance/CreateBirthdayForm";
import Choice from "../../../images/choice.png";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { RegistrationButton } from "../../atoms/employeeMaintenance/RegistrationButton";
import { ReturnButton } from "../../atoms/commons/ReturnButton";
import { FormLabel } from "../../atoms/commons/FormLabel";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";
import { useFetchOfficeList } from "../../../hooks/offices/useFetchOfficeList";
import { useFetchDepartmentTree } from "../../../hooks/department/useFetchDepartmentTree";
import { EmployeeMaintenanceFormType } from "../../../types/api/employeeMaintenance";

type InputProps = {
  register: UseFormRegister<EmployeeMaintenanceFormType>;
  watch: UseFormWatch<EmployeeMaintenanceFormType>;
  handleSubmit: UseFormHandleSubmit<EmployeeMaintenanceFormType>;
  errors: FieldErrors<EmployeeMaintenanceFormType>;
  onSubmit: SubmitHandler<EmployeeMaintenanceFormType>;
  required: boolean;
  isPreviewVisible: boolean;
  hidePreview: () => void;
  createEmployee: () => void;
  getValues: UseFormGetValues<EmployeeMaintenanceFormType>;
  reset: UseFormReset<EmployeeMaintenanceFormType>;
  append: UseFieldArrayAppend<EmployeeMaintenanceFormType, "departmentList">;
  remove: UseFieldArrayRemove;
  fields: FieldArrayWithId<
    EmployeeMaintenanceFormType,
    "departmentList",
    "id"
  >[];
};

export const EmployeeMaintenanceCreateForm = ({
  register,
  onSubmit,
  watch,
  handleSubmit,
  errors,
  isPreviewVisible,
  hidePreview,
  createEmployee,
  append,
  remove,
  fields,
}: InputProps) => {
  const { officeList } = useFetchOfficeList();
  const { departmentList } = useFetchDepartmentTree();
  // 投稿フォームのinputタグのCSS
  const normalFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  // 投稿フォームのselectタグのCSS
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 確認フォームのinputタグのCSS
  const normalSelectFormStyle =
    "border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  // 確認フォームのselectタグのCSS
  const checkSelectFormStyle =
    "rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white";

  // 投稿フォームと確認フォームをinput/selectタグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const formSelectStyle = !isPreviewVisible
    ? normalSelectFormStyle
    : checkSelectFormStyle;
  return (
    <>
      <div className="flex justify-between min-h-147" id="concept">
        <Title src={EmployeeManagement}>社員登録</Title>
      </div>
      <div className="flex">
        <span className="my-11 text-sm">
          社員情報を入力し、登録ボタンを押してください
        </span>
      </div>
      <div className="flex">
        <span className="text-2xl text-lightGreen-200 my-2 text-sm font-bold leading-relaxed">
          基本情報
        </span>
      </div>
      <div className="mt-5 mb-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 border-t items-center flex relative">
            <FormLabel
              title="社員番号"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("employeeNo")}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("employeeNo")}
            />
          </div>
          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="姓"
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("lastName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("lastName")}
                />
              </div>
              {errors.lastName?.types?.required && (
                <span className="text-red-600 absolute bottom-0 left-0">
                  この項目は必須です
                </span>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="名"
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("firstName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("firstName")}
                />
              </div>
              {errors.firstName?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <RequiredFormLabel
                title="姓 (カナ)"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 static"
              />
              <input
                {...register("lastNameKana", {
                  required: true,
                  pattern: /^[ァ-ンヴーｧ-ﾝﾞﾟ\-]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("lastNameKana")}
              />
              {errors.lastNameKana?.types?.required && (
                <span className="text-red-600 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.lastNameKana?.types?.pattern && (
                <span className="text-red-600 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title="名 (カナ)"
                className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("firstNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("firstNameKana")}
              />
              {errors.firstNameKana?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.firstNameKana?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title={`ビジネスネーム\n姓`}
                  className="w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
                />
                <input
                  {...register("businessLastName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("businessLastName")}
                />
              </div>
              {errors.businessLastName?.types?.required && (
                <span className="text-red-600 absolute bottom-0 left-0">
                  この項目は必須です
                </span>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title={`ビジネスネーム\n名`}
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
                />
                <input
                  {...register("businessFirstName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("businessFirstName")}
                />
              </div>
              {errors.businessFirstName?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
            </div>
          </div>
          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <RequiredFormLabel
                title={`ビジネスネーム\n姓 (カナ)`}
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 static whitespace-pre-wrap"
              />
              <input
                {...register("businessLastNameKana", {
                  required: true,
                  pattern: /^[ァ-ンヴーｧ-ﾝﾞﾟ\-]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("businessLastNameKana")}
              />
              {errors.businessLastNameKana?.types?.required && (
                <span className="text-red-600 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.businessLastNameKana?.types?.pattern && (
                <span className="text-red-600 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title={`ビジネスネーム\n名 (カナ)`}
                className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
              />
              <input
                {...register("businessFirstNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("businessFirstNameKana")}
              />
              {errors.businessFirstNameKana?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.businessFirstNameKana?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex">
            <RequiredFormLabel
              title="性別"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div className={formSelectStyle}>
              <select
                className="appearance-none rounded-lg w-24 h-12 pl-4 focus:outline-none disabled:opacity-100"
                {...register("sex")}
                name={"sex"}
                disabled={isPreviewVisible}
              >
                <option value="0">男性</option>
                <option value="1">女性</option>
              </select>
              {!isPreviewVisible && (
                <img
                  className="w-3 h-2.5 absolute top-0 right-0 mr-4 mt-5"
                  src={Choice}
                ></img>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex">
            <RequiredFormLabel
              title="生年月日"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <EmployeeCreateBirthdayForm
              watch={watch}
              register={register}
              formSelectStyle={formSelectStyle}
              isPreviewVisible={isPreviewVisible}
            />
          </div>

          <div className="py-5 border-t border-b items-center flex relative">
            <RequiredFormLabel
              title="事業場"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div className={formSelectStyle}>
              <select
                className="appearance-none rounded-lg w-80 h-12 pl-4
                focus:outline-none disabled:text-black disabled:opacity-100"
                {...register("officeId", {
                  required: true,
                })}
                disabled={isPreviewVisible}
                defaultValue={watch("officeId")}
              >
                <option value="">事業場を選んでください</option>
                {officeList.map((office) => (
                  <option key={office.id} value={office.id}>
                    {office.name}
                  </option>
                ))}
              </select>
              {!isPreviewVisible && (
                <img
                  className="w-3 h-2.5 absolute top-0 right-0 mr-8 mt-5"
                  src={Choice}
                ></img>
              )}
            </div>
            {errors.officeId?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です
              </span>
            )}
          </div>
          {fields.map((field, index) => {
            return (
              <div key={field.id} className="py-5 items-center flex relative">
                <RequiredFormLabel
                  title={index === 0 ? "部署(メイン)" : "部署(兼任)"}
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <div className={formSelectStyle}>
                  <select
                    className="appearance-none rounded-lg w-80 h-12 pl-4 focus:outline-none disabled:text-black disabled:opacity-100"
                    {...register(`departmentList.${index}.value`, {
                      required: true,
                    })}
                    key={index}
                    name={`departmentList.${index}.value`}
                    defaultValue={field.value}
                    disabled={isPreviewVisible}
                  >
                    <option value="">部署を選んでください</option>
                    {departmentList.map((department) => {
                      return (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      );
                    })}
                  </select>
                  {!isPreviewVisible && (
                    <img
                      className="w-3 h-2.5 absolute top-0 right-0 mr-5 mt-5"
                      src={Choice}
                    ></img>
                  )}
                </div>
                <input
                  type="checkbox"
                  disabled={isPreviewVisible}
                  className="w-6 h-6 ml-10 default:ring-2 accent-white text-lightGreen-200 border rounded-lg"
                  {...register(`departmentList.${index}.responsibilityType`)}
                />
                <span className="w-30 ml-5 min-w-[100px]	text-sm font-semibold text-lightGreen-200">
                  責任者の場合はチェック
                </span>
                {index === 0 && !isPreviewVisible && (
                  <button
                    type="button"
                    onClick={() =>
                      append({
                        value: "",
                      })
                    }
                  >
                    <img
                      src={EmployeeManagementPlusBtn}
                      className="ml-10 h-10"
                    />
                  </button>
                )}
                {index > 0 && !isPreviewVisible && (
                  <button
                    type="button"
                    disabled={isPreviewVisible}
                    className="flex justify-center items-center w-20 ml-7 h-13 border rounded-lg text-sm text-lightGreen-200 hover:bg-lightGreen-200 hover:text-white"
                    onClick={() => remove(index)}
                  >
                    <span>削除</span>
                  </button>
                )}
                {errors.departmentList?.[index]?.value?.types?.required && (
                  <span className="text-red-600 absolute bottom-0 left-0">
                    この項目は必須です
                  </span>
                )}
              </div>
            );
          })}

          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <FormLabel
                  title="役職"
                  className="w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("position")}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("position")}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <FormLabel
                  title="職種"
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("occupation")}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("occupation")}
                />
              </div>
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <FormLabel
                title="E-mail"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("emailAddress")}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("emailAddress")}
              />
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title="電話番号"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 ml-10"
              />
              <input
                {...register("phoneNumber", {
                  required: true,
                  pattern: /^0[0-9]{9,10}$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("phoneNumber")}
                placeholder="例）09012345678"
              />
              {errors.phoneNumber?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.phoneNumber?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  ハイフンなしの10桁もしくは11桁の半角数字で入力してください
                </span>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="郵便番号"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("postcode", {
                required: true,
                pattern: /^[0-9]{7}$/i,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("postcode")}
              placeholder="例）1234567"
            />
            {errors.postcode?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です
              </span>
            )}
            {errors.postcode?.types?.pattern && (
              <span className="text-red-600 absolute bottom-0 left-0">
                ハイフンなしの7桁の半角数字で入力してください
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="住所"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("address", {
                required: true,
              })}
              className={formStyle + " min-w-[860px]"}
              disabled={isPreviewVisible}
              value={watch("address")}
            />
            {errors.address?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です
              </span>
            )}
          </div>

          <div className="pt-10	pb-20 text-center border-t">
            {!isPreviewVisible ? (
              <>
                <ReturnButton />
                <ConfirmationButton>確認</ConfirmationButton>
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={createEmployee}>
                  登録
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
