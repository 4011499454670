import React, { memo } from "react";
import { Link } from "react-router-dom";

import defaultProfileImage from "../../../images/default-profile.png";

export const EmployeeCard = memo(
  (props: {
    id: number;
    businessName: string;
    businessNameKana: string;
    cardTheme: string;
    departmentName: string;
    profileImage: string;
  }) => {
    const {
      id,
      businessName,
      businessNameKana,
      cardTheme,
      departmentName,
      profileImage,
    } = props;

    return (
      <div
        className={
          `w-80 h-36 border leading-relaxed rounded-lg truncate ` +
          `${cardTheme}`
        }
      >
        <Link target="_blank" to={`../detail/` + `${id}`}>
          <div className="p-5 flex">
            {profileImage ? (
              <img
                src={profileImage}
                alt="picture"
                className="w-24 h-24 rounded-full"
              />
            ) : (
              <img
                src={defaultProfileImage}
                alt="picture"
                className="w-24 h-24 rounded-full"
              />
            )}
            <div className="pl-5 space-y-2">
              <div className="truncate w-40">
                <p title={businessName} className="inline text-xl font-bold">
                  {businessName}
                </p>
              </div>
              <div className="truncate w-40">
                <p title={businessNameKana} className="inline text-xs">
                  {businessNameKana}
                </p>
              </div>
              <div className="truncate w-40">
                <p title={departmentName} className="inline text-xs">
                  {departmentName}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
);
