import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import { ContactResponseType } from "../types/api/contact";

export const getContact = () => {
  return api
    .get<ContactResponseType>("/merchant/maintenance/contact")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const postContact = (content: string) => {
  return api
    .post("merchant/maintenance/contact", { content: content })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
