import React, { FC } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import ExcellentIcon from "../../../images/excellent-2.png";
import GoodIcon from "../../../images/good-2.png";
import UsuallyIcon from "../../../images/usually-2.png";
import BadIcon from "../../../images/bad-2.png";
import WorstIcon from "../../../images/worst-2.png";
import { CustomizedMostPastDotOverall } from "../../atoms/stressChecks/CustomizedMostPastDotOverall";
import { CustomizedPastDotOverall } from "../../atoms/stressChecks/CustomizedPastDotOverall";
import { CustomizedDotTheGroup } from "../../atoms/stressChecks/CustomizeDotOverall";
import { TitleAxisTick } from "../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/TitleAxisTick";
import { ScoreAxisTick } from "../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/ScoreAxisTick";

type GraphAndReport = {
  section: {
    name: string;
    total_score: number;
    max_score: number;
    evaluate: number;
    scales: [
      {
        name: string;
        score: number;
        past_score: number;
        most_past_score: number;
      }
    ];
    comment: string;
    advice: string;
  };
};

export const StressCheckGraphAndReport: FC<GraphAndReport> = (props) => {
  const { section } = props;

  const faceIconHandleChange = (evaluate: number) => {
    switch (evaluate) {
      case 1:
        return WorstIcon;
      case 2:
        return BadIcon;
      case 3:
        return UsuallyIcon;
      case 4:
        return GoodIcon;
      case 5:
        return ExcellentIcon;
    }
  };

  return (
    <div className="border-b border-lightGray-100 pt-[50px] pb-[30px]">
      <div className="basis-2/5 mr-[10px]">
        <p className="font-bold text-2xl text-lightGreen-200 mb-[40px]">
          {section.name}
        </p>
        <div className="flex items-center justify-between pb-5">
          <div className="flex items-center">
            <span className="text-lightGreen-200 font-bold">評価</span>
            <span className="text-lightGreen-200 font-bold pl-14 pr-5">
              全体
            </span>
            <div>
              <img
                src={faceIconHandleChange(section.evaluate)}
                alt="face-icon.png"
                width={60}
                height={60}
              />
            </div>
            <div className="text-lightGreen-300 font-bold text-2xl pl-5">
              <span className="pr-[5px]">{section.total_score}</span>/
              <span className="pl-[5px]">{section.max_score}</span>
            </div>
          </div>
          <div className="flex items-center">
            <div>
              <svg x={12} y={12} width={40} height={36} fill="green">
                <line
                  x1="20"
                  y1="18"
                  x2="40"
                  y2="18"
                  stroke="#6CA893"
                  strokeWidth={3}
                />
                <circle
                  stroke="#6CA893"
                  strokeWidth="2"
                  cx="12"
                  cy="18"
                  r="10"
                  fill="#6CA893"
                />
              </svg>
            </div>
            <p className="text-md ml-2">今回</p>
            {section.scales[0].past_score === 0 ? (
              <div className="w-[80px]"></div>
            ) : (
              <div className="flex items-center">
                <div className="ml-10">
                  <svg x={12} y={12} width={40} height={36} fill="green">
                    <line
                      x1="20"
                      y1="18"
                      x2="40"
                      y2="18"
                      stroke="#BED6CD"
                      strokeWidth={3}
                    />
                    <circle
                      stroke="#BED6CD"
                      strokeWidth="2"
                      cx="12"
                      cy="18"
                      r="10"
                      fill="#BED6CD"
                    />
                  </svg>
                </div>
                <p className="text-md ml-2">前回</p>
              </div>
            )}

            {section.scales[0].most_past_score === 0 ? (
              <div className="w-[80px]"></div>
            ) : (
              <div className="flex items-center">
                <div className="ml-10">
                  <svg x={12} y={12} width={40} height={36} fill="green">
                    <line
                      x1="20"
                      y1="18"
                      x2="40"
                      y2="18"
                      stroke="#BAEDDA"
                      strokeWidth={3}
                    />
                    <circle
                      stroke="#BAEDDA"
                      strokeWidth="2"
                      cx="12"
                      cy="18"
                      r="10"
                      fill="#FFFFFF"
                    />
                  </svg>
                </div>
                <p className="text-md ml-2">前々回</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <LineChart
            layout="vertical"
            width={800}
            height={80 * section.scales.length}
            data={section.scales}
            margin={{ top: 20, right: 50, left: 250, bottom: 40 }}
          >
            <CartesianGrid horizontal={false} />
            <XAxis
              type="number"
              domain={[1, 5]}
              tickLine={false}
              axisLine={false}
              tickMargin={20}
              tick={({ ...props }) => <ScoreAxisTick {...props} />}
            />
            <YAxis
              dataKey="name"
              type="category"
              tickLine={false}
              axisLine={false}
              tickMargin={60}
              tick={({ ...props }) => <TitleAxisTick {...props} />}
              padding={{ bottom: 25, top: 25 }}
            />
            <Tooltip
              formatter={(value: number) => value.toFixed(1)}
              itemSorter={(item) => {
                switch (item.dataKey) {
                  case "score":
                    return 0;
                  case "past_score":
                    return 1;
                  case "most_past_score":
                    return 2;
                  default:
                    return 3;
                }
              }}
            />

            {section.scales[0].most_past_score === 0 ? (
              <></>
            ) : (
              <Line
                name="前々回"
                type="linear"
                dataKey="most_past_score"
                strokeWidth={7}
                stroke="#BAEDDA"
                opacity={0.75}
                dot={({ ...props }) => (
                  <CustomizedMostPastDotOverall {...props} />
                )}
              />
            )}

            {section.scales[0].past_score === 0 ? (
              <></>
            ) : (
              <Line
                name="前回"
                type="linear"
                dataKey="past_score"
                strokeWidth={5}
                stroke="#BED6CD"
                opacity={0.75}
                dot={({ ...props }) => <CustomizedPastDotOverall {...props} />}
              />
            )}

            <Line
              name="今回"
              type="linear"
              dataKey="score"
              strokeWidth={3}
              stroke="#6CA893"
              opacity={0.75}
              dot={({ ...props }) => <CustomizedDotTheGroup {...props} />}
            />
          </LineChart>
        </div>
      </div>
      <div className="basis-3/5 ml-[10px] px-[10px]">
        <div className="flex items-center bg-lightGreen-100 rounded-lg my-[40px] py-[25px]">
          <p className="basis-1/5 font-bold text-center text-lightGreen-200">
            解説
          </p>
          <p className="basis-4/5 pr-10">{section.comment}</p>
        </div>
        <div className="flex items-center bg-lightGreen-100 rounded-lg my-[40px] py-[25px]">
          <p className="basis-1/5 font-bold text-center text-lightGreen-200">
            アドバイス
          </p>
          <p className="basis-4/5 pr-10">{section.advice}</p>
        </div>
      </div>
    </div>
  );
};
