import React, { memo } from "react";

import { InterviewSubjectSelectionList } from "../../templates/stressCheckMaintenance/InterviewSubjectSelectionList";

export const InterviewSubjectSelection = memo(() => {
  return (
    <>
      <InterviewSubjectSelectionList />
    </>
  );
});
