import React, { memo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useEmployee } from "../../../hooks/employees/useEmployee";
import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";
import EmployeeDetailEditIcon from "../../../images/employee-detail-edit.png";
import EmployeeDetailAvatar from "../../../images/employee-detail-avatar.png";
import EmployeeProfileNoImage from "../../../images/ProfileNoImage.png";
import { useLoginUser } from "../../../hooks/useLoginUser";

export const EmployeeProfileDetail = memo(() => {
  const { detailEmployee, fetchDetailEmployeeProfile } = useEmployee();
  const params = useParams();
  const employeeId = params.employee_id;
  const navigate = useNavigate();
  useEffect(() => {
    fetchDetailEmployeeProfile(employeeId);
  }, []);
  const loginUserDetail = useLoginUser();
  const loginUserId = loginUserDetail.loginUser?.data.user_id;

  return (
    <>
      <main>
        <div className="flex items-center justify-between">
          <Title src={EmployeeManagement}>プロフィール</Title>
          <div>
            {loginUserId &&
            parseInt(loginUserId, 10) === detailEmployee.employee_id ? (
              <button
                onClick={() => navigate(`/employees/detail/${employeeId}/edit`)}
                className="flex items-center py-1 px-5 text-white rounded-lg focus:outline-none border-lightGreen-200 border"
              >
                <img src={EmployeeDetailEditIcon} alt="picture" />
                <span className="text-lg font-w6 leading-relaxed text-lightGreen-200 pl-2">
                  編集
                </span>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex py-16">
          <div className="text-center w-3/12 2xl:w-2/12">
            {detailEmployee.profile_image ? (
              <img
                src={detailEmployee.profile_image}
                alt="picture"
                className="pb-3 h-52 w-52 rounded-full"
              />
            ) : (
              <img
                src={EmployeeDetailAvatar}
                alt="picture"
                className="pb-3 h-52 w-52 rounded-full"
              />
            )}
            <div className="flex justify-center text-2xl">
              <b>
                {detailEmployee.business_last_name}{" "}
                {detailEmployee.business_first_name}
              </b>
            </div>
            <div className="flex justify-center text-md">
              <span>
                {detailEmployee.business_last_name_kana}{" "}
                {detailEmployee.business_first_name_kana}
              </span>
            </div>
          </div>
          <div className="w-full pl-10 text-lg">
            <div className="border"></div>
            <div className="py-12">
              <div className="flex bg-[#F0F6F4] py-6 px-10 rounded-lg">
                <b className="text-lightGreen-200 w-24">ひとこと</b>
                <p>{detailEmployee.word}</p>
              </div>
              <p className="pt-5">{detailEmployee.self_introduction}</p>
            </div>
            <div className="border"></div>
            <div className="py-12">
              <b className="text-lightGreen-200">趣味・特技</b>
              <div className="flex justify-between pt-9">
                <div className="md:w-[356px] 2xl:w-[34rem]">
                  {detailEmployee.interest_image_1 ? (
                    <img
                      src={detailEmployee.interest_image_1}
                      alt="個人プロフィール、趣味の画像"
                      width={356}
                      height={200}
                      className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                    />
                  ) : (
                    <img
                      src={EmployeeProfileNoImage}
                      alt="個人プロフィール、趣味の画像"
                      width={356}
                      height={200}
                      className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                    />
                  )}
                  <span>{detailEmployee.interest_content_1}</span>
                </div>
                <div className="md:w-[356px] 2xl:w-[34rem]">
                  {detailEmployee.interest_image_2 ? (
                    <img
                      src={detailEmployee.interest_image_2}
                      alt="個人プロフィール、趣味の画像"
                      width={356}
                      height={200}
                      className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                    />
                  ) : (
                    <img
                      src={EmployeeProfileNoImage}
                      alt="個人プロフィール、趣味の画像"
                      width={356}
                      height={200}
                      className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                    />
                  )}
                  <span>{detailEmployee.interest_content_2}</span>
                </div>
              </div>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">E-mail(会社)</b>
              <span>{detailEmployee.email_address}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">E-mail(個人)</b>
              <span>{detailEmployee.private_email_address}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">電話番号</b>
              <span>{detailEmployee.private_phone_number}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">内線番号</b>
              <span>{detailEmployee.extention_number}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">事業場</b>
              <span>{detailEmployee.office_name}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">役職</b>
              <span>{detailEmployee.position}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">部署</b>
              <div>
                <div className="mb-6 flex">
                  <p>現在 :</p>
                  <span>{detailEmployee.department_name}</span>
                </div>
                {detailEmployee.department_histories.map((history) =>
                  history.assign_start_date != history.assign_end_date
                    ? [
                        <div key={history.department_id} className="flex mb-2">
                          <p>
                            {history.assign_start_date} ~{" "}
                            {history.assign_end_date}
                          </p>
                          <p className="pl-4">{history.name}</p>
                        </div>,
                      ]
                    : [
                        <div key={history.department_id} className="flex mb-2">
                          {history.assign_end_date}
                          <p className="pl-4">{history.name}</p>
                        </div>,
                      ]
                )}
              </div>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">職種</b>
              <span>{detailEmployee.occupation}</span>
            </div>
            <div className="border"></div>
            <div className="py-9 flex">
              <b className="w-36 text-lightGreen-200">社員番号</b>
              <span>{detailEmployee.employee_no}</span>
            </div>
            <div className="border"></div>
          </div>
        </div>
      </main>
    </>
  );
});
