import React from "react";

export const SelfCareAdvice = () => {
  return (
    <div className="my-[40px]">
      <p className="font-bold text-2xl text-lightGreen-200">
        セルフケアアドバイス
      </p>
      <div className="flex my-[40px] py-10 items-center bg-lightGreen-100 rounded-lg">
        <div className="basis-1/5 py-[5px] pl-[5px]">
          <span className="font-bold text-lg text-center block text-lightGreen-200">
            運動
          </span>
        </div>
        <div className="basis-4/5 pr-10 leading-7">
          <div>
            <p className="font-bold mt-[10px]">&lt; ストレスへの効果 &gt;</p>
            <p>
              運動は体や脳だけでなく、気持ちや感情にも直接的な変化をもたらします。
              運動を行うことで、心地よい疲労感で気分が晴れたり、満足感や達成感などの快感も得られ自信回復効果もあります。
              また脳と体の疲労のアンバランスを解消し、睡眠障害を改善する効果も期待できます。
            </p>
          </div>
          <div>
            <p className="font-bold mt-[15px]">
              &lt; ストレス解消のポイント &gt;
            </p>
            <p>
              一定のリズムを意識して繰り返す運動をすると、心の安定に関わる脳内ホルモン「セロトニン」の分泌が高まります。
            </p>
          </div>
          <div className="bg-white p-5 rounded-lg mt-5">
            <p className="font-bold">
              セロトニンを分泌する取り入れやすい運動の例
            </p>
            <p className="whitespace-pre-line leading-4 pl-3">
              {`
            ・1駅前からウォーキングする\n
            ・いつもより遠いところへランチに行く\n
            ・移動の際に階段を積極的に使う\n
            ・フラダンス
            `}
            </p>
          </div>
        </div>
      </div>
      <div className="flex my-[40px] py-10 items-center bg-lightGreen-100 rounded-lg">
        <div className="basis-1/5 py-[5px] pl-[5px]">
          <span className="font-bold text-lg text-center block text-lightGreen-200">
            休息
          </span>
        </div>
        <div className="basis-4/5 pr-10 leading-7">
          <div>
            <p className="font-bold mt-[10px]">&lt; ストレスへの効果 &gt;</p>
            <p>
              適切な休息は体内のホルモンバランスを整え、生活のリズムや精神状態を健全に保ちます。
              休息の中でも特に重要なものは睡眠です。
              睡眠時間は質、量ともにしっかりと確保することで、うつ病の予防につながります。
            </p>
          </div>
          <div>
            <p className="font-bold mt-[15px]">
              &lt; ストレス解消のポイント &gt;
            </p>
            <p>
              就寝前に興奮性のホルモン(ドーパミンやノルアドレナリン)を分泌させないことで睡眠の質が高まります。
            </p>
          </div>
          <div className="bg-white p-5 rounded-lg mt-5">
            <p className="font-bold">睡眠の質を高める行動の例</p>
            <p className="whitespace-pre-line leading-4 pl-3">
              {`
            ・就寝前にアルコールやカフェインを控える\n
            ・就寝前にPCやスマートフォンの画面は見ない\n
            ・深夜にたくさんの食事を控える\n
            ・ゆったりと入浴し、適度なストレッチを行う
            `}
            </p>
          </div>
        </div>
      </div>
      <div className="flex my-[40px] py-10 items-center bg-lightGreen-100 rounded-lg">
        <div className="basis-1/5 py-[5px] pl-[5px]">
          <span className="font-bold text-lg text-center block text-lightGreen-200">
            食事
          </span>
        </div>
        <div className="basis-4/5 pr-10 leading-7">
          <div>
            <p className="font-bold mt-[10px]">&lt; ストレスへの効果 &gt;</p>
            <p>
              ストレスに強い体づくりを、まずは食事から始めてみましょう。
              糖質を含んだ朝食を食べることで、日中から脳に十分にエネルギーを供給でき、集中力を高めることができます。
              また、長時間残業が続くと高血圧や高脂血症になりやすくなるため、塩分や脂質のとりすぎに普段から気をつけましょう。
            </p>
          </div>
          <div>
            <p className="font-bold mt-[15px]">
              &lt; ストレス解消のポイント &gt;
            </p>
            <p>
              硫化アリルや辛味成分、ビタミンCを含んだ料理や、減塩・低脂肪な料理はストレスに強い体づくりを支援します。
            </p>
          </div>
          <div className="bg-white p-5 rounded-lg mt-5">
            <p className="font-bold">ストレス解消に役立つ食べ物の例</p>
            <p className="whitespace-pre-line leading-4 pl-3">
              {`
            ・比較的低脂肪・高タンパクな魚、豆腐、大豆類\n
            ・硫化アリルを多く含むネギ類\n
            ・ビタミンCが多く含まれる緑黄色野菜\n
            `}
            </p>
            <p>食生活は徐々に変えていくことがポイント！</p>
          </div>
        </div>
      </div>
    </div>
  );
};
