import React, { FC } from "react";

import { useGroupAnalytics } from "../../../../hooks/stressCheckManagements/useGroupAnalytics";

type Props = {
  num_of_grades: number;
  thresholds: number[][];
  sections: {
    name: string;
    score: number;
    comment: string;
    scales: {
      name: string;
      score: number;
    }[];
  };
  marginTop?: boolean;
};

export const GroupAnalyticsListTableValue: FC<Props> = (props) => {
  const { num_of_grades, thresholds, sections, marginTop } = props;
  const { isCautionScore } = useGroupAnalytics();

  return (
    <>
      <div
        className={
          marginTop
            ? "leading-[45px] w-[148px] text-lg block text-center mt-8"
            : "leading-[45px] w-[148px] text-lg block text-center"
        }
      >
        <div>
          <span className="text-3xl font-bold">
            {sections.score !== 0 ? sections.score : "-"}
          </span>
          <span className="pl-[7px] text-lg font-bold">/</span>
          <span className="pl-[5px] text-lg font-bold">{num_of_grades}</span>
        </div>
      </div>
      {sections.scales.map((item, index) => (
        <div
          key={index}
          className={
            (index + 1) % 2 === 0
              ? "h-[45px] w-[148px] mt-2 text-lg block font-normal text-center flex"
              : "h-[45px] w-[148px] mt-2 text-lg flex font-normal bg-lightGray-50 text-center"
          }
        >
          <p className={isCautionScore(item.score, thresholds)}>
            {item.score !== 0 ? item.score : "-"}
          </p>
        </div>
      ))}
    </>
  );
};
