import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  path: string;
  children: string;
};

export const ReturnHomeButton: FC<Props> = (props) => {
  const { path, children } = props;
  return (
    <>
      <div className="h-[530px] mt-24 text-center">
        <Link
          to={path}
          className="border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200 py-5 px-28"
        >
          {children}
        </Link>
      </div>
    </>
  );
};
