import React, { memo } from "react";

import { SendMailList } from "../../templates/stressCheckMaintenance/SendMailList";

export const SendMailSelection = memo(() => {
  return (
    <>
      <SendMailList />
    </>
  );
});
