import React, { FC } from "react";

type Props = {
  x: number;
  y: number;
  payload: { value: number | string };
};

export const TitleAxisTick: FC<Props> = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={7}
        textAnchor="end"
        className="text-lg"
        fill="#3A3F3D"
      >
        {payload.value}
      </text>
    </g>
  );
};
