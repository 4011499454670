import React, { FC, memo } from "react";

import ExcellentIcon from "../../../../images/excellent-2.png";
import GoodIcon from "../../../../images/good-2.png";
import UsuallyIcon from "../../../../images/usually-2.png";
import BadIcon from "../../../../images/bad-2.png";
import WorstIcon from "../../../../images/worst-2.png";
import { ComprehensiveEvaluationTable } from "./ComprehensiveEvaluationTable";

type Props = {
  num_of_grades: number;
  thresholds: number[][];
  overall_score: number;
  overall_comment: string;

  stress_check_info: {
    targets: number;
    offices: number;
    examinees: number;
    highstress: number;
    examination_rate: number;
    highstress_rate: number;
  };
};

export const ComprehensiveEvaluationForm: FC<Props> = memo((props) => {
  const {
    num_of_grades,
    thresholds,
    overall_score,
    overall_comment,
    stress_check_info,
  } = props;

  const faceIconHandleChange = (evaluate: number, thresholds: number[][]) => {
    if (thresholds[0][0] <= evaluate && evaluate <= thresholds[0][1]) {
      return WorstIcon;
    } else if (thresholds[1][0] <= evaluate && evaluate <= thresholds[1][1]) {
      return BadIcon;
    } else if (thresholds[2][0] <= evaluate && evaluate <= thresholds[2][1]) {
      return UsuallyIcon;
    } else if (thresholds[3][0] <= evaluate && evaluate <= thresholds[3][1]) {
      return GoodIcon;
    } else if (thresholds[4][0] <= evaluate && evaluate <= thresholds[4][1]) {
      return ExcellentIcon;
    } else {
      return ExcellentIcon;
    }
  };

  return (
    <div className="mt-[30px] pt-[30px] border-t border-lightGray-100">
      <div className="flex justify-between">
        <div className="basis-2/5">
          <p className="font-bold text-[24px] text-lightGreen-200">総合評価</p>
          <div className="mt-[40px] ml-[60px] mb-[90px] flex items-center">
            <img
              src={faceIconHandleChange(overall_score, thresholds)}
              alt="face-icon.png"
              width={100}
              height={100}
            />
            <div className="ml-[40px]">
              <span className="text-[64px] font-bold">{overall_score}</span>
              <span className="pl-[15px] text-[40px] font-bold">/</span>
              <span className="pl-[7px] text-[40px] font-bold">
                {num_of_grades}
              </span>
            </div>
          </div>
        </div>
        <div className="container flex flex-col basis-3/5">
          <ComprehensiveEvaluationTable stress_check_info={stress_check_info} />
        </div>
      </div>
      <div className="flex items-center rounded-lg bg-lightGreen-100 px-[65px] py-[30px]">
        <span className="font-bold text-lg text-lightGreen-200 min-w-[30px]">
          解説
        </span>
        <div className="max-w-[850px] min-h-[100px] pl-[65px] flex justify-center items-center">
          <span>{overall_comment}</span>
        </div>
      </div>
      <div className="flex justify-end items-center mt-[20px]">
        <span className="text-[12px]">
          *1 受検者数の割合は受検対象者数に対する受検者数の割合です。
        </span>
        <span className="text-[12px] pl-[5px]">
          *2 高ストレス者数の割合は受検者数に対する高ストレス者数の割合です。
        </span>
      </div>
    </div>
  );
});
