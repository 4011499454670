import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  SendMailFlagStatusResponseType,
  StressCheckManagementResponseType,
  StressCheckManagementListResponseType,
  SendMailResponseType,
  CompanyReportResponseType,
  StressCheckSubjectEmployeesRequestType,
  IntervieweeCandidateListResponseType,
  IntervieweeTargetListRequestType,
  InterviewGuidanceFormType,
  StressCheckSubjectEmployeeResponseType,
} from "../types/api/stressCheckManagement";

// メールのフラグステータスデータ取得
export const getSendMailFlagStatus = (stressCheckId: string | undefined) => {
  return api
    .get<SendMailFlagStatusResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/mail`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック管理一覧データ取得
export const getStressCheckManagementList = () => {
  return api
    .get<StressCheckManagementListResponseType[]>(
      "/merchant/maintenance/stresscheck"
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック管理サマリーのデータ取得
export const getStressCheckManagement = (stressCheckId: string | undefined) => {
  return api
    .get<StressCheckManagementResponseType[]>(
      `/merchant/maintenance/stresscheck/${stressCheckId}`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 全社分析のデータ取得
export const getStressCheckReport = (stressCheckId: string | undefined) => {
  return api
    .get<CompanyReportResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/company_report`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック対象者選定の社員データ取得
export const getStressCheckSubjectEmployeeList = (
  stressCheckId: string | undefined
) => {
  return api
    .get<StressCheckSubjectEmployeeResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/candidates`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック対象者のデータ送信
export const postStressCheckSubjectEmployees = (
  stressCheckId: string | undefined,
  employeesResponseData: StressCheckSubjectEmployeesRequestType
) => {
  return api
    .put<StressCheckSubjectEmployeesRequestType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/targets`,
      employeesResponseData
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談指導対象者選定用の社員データ取得
export const getIntervieweeCandidateList = (
  stressCheckId: string | undefined
) => {
  return api
    .get<IntervieweeCandidateListResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/interviewee_candidates`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談指導対象者のデータ送信
export const postInterviewTargets = (
  stressCheckId: string | undefined,
  data: IntervieweeTargetListRequestType
) => {
  return api
    .post<IntervieweeTargetListRequestType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/interview_targets`,
      data
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談指導要否文のデータ取得
export const getInterviewGuidance = (stressCheckId: string | undefined) => {
  return api
    .get<InterviewGuidanceFormType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/interview_sentence`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談指導要否文のデータ送信
export const postInterviewGuidance = (
  data: InterviewGuidanceFormType,
  stressCheckId: string | undefined
) => {
  return api
    .post(
      `/merchant/maintenance/stresscheck/${stressCheckId}/interview_sentence`,
      {
        sentence_for_target: data.sentence_for_target,
        sentence_for_not_target: data.sentence_for_not_target,
      }
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ガイダンスメールのデータ取得
export const getGuidanceMail = (stressCheckId: string | undefined) => {
  return api
    .get<SendMailResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/mail/guidance`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// リマインドメールのデータ取得
export const getRemindMail = (stressCheckId: string | undefined) => {
  return api
    .get<SendMailResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/mail/remind`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談要否メールのデータ取得
export const getInterviewNotifyMail = (stressCheckId: string | undefined) => {
  return api
    .get<SendMailResponseType>(
      `/merchant/maintenance/stresscheck/${stressCheckId}/mail/interview_notify`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ガイダンスメールの送信リクエスト
export const postGuidanceMail = (stressCheckId: string | undefined) => {
  return api
    .post(`/merchant/maintenance/stresscheck/${stressCheckId}/mail/guidance`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// リマインドメールの送信リクエスト
export const postRemindMail = (stressCheckId: string | undefined) => {
  return api
    .post(`/merchant/maintenance/stresscheck/${stressCheckId}/mail/remind`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 面談指導要否メールの送信リクエスト
export const postInterviewNotifyMail = (stressCheckId: string | undefined) => {
  return api
    .post(
      `/merchant/maintenance/stresscheck/${stressCheckId}/mail/interview_notify`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getStressCheckManagementEmployeeList = (
  scFrameID: string | undefined
) => {
  return api
    .get(`/merchant/maintenance/stresscheck/${scFrameID}/target_employees`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
