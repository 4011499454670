import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useFetchDepartmentTree } from "../../../hooks/department/useFetchDepartmentTree";
import { useEmployeeMaintenance } from "../../../hooks/employeeMaintenance/useEmployeeMaintenance";
import { useFetchOfficeList } from "../../../hooks/offices/useFetchOfficeList";
import { EmployeeMaintenanceRequestType } from "../../../types/api/employeeMaintenance";
import { EmployeeMaintenanceEditFormComplete } from "../../templates/employeeMaintenance/EmployeeMaintenanceEditComplete";
import { EmployeeMaintenanceEditForm } from "../../templates/employeeMaintenance/EmployeeMaintenanceEditForm";
import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { Sidebar } from "../../templates/sidebar/Sidebar";

export const EmployeeMaintenanceEdit = memo(() => {
  const { isComplete, updateEmployee } = useEmployeeMaintenance();
  const [updateRequestData, setUpdateRequestData] =
    useState<EmployeeMaintenanceRequestType>({
      office_id: "",
      employee_no: "",
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      business_last_name: "",
      business_first_name_kana: "",
      business_last_name_kana: "",
      business_first_name: "",
      position: "",
      occupation: "",
      email_address: "",
      sex: "",
      birthday: "",
      postcode: "",
      address: "",
      phone_number: "",
      departments: [
        {
          id: "0",
          responsibility_type: "0",
          concurrent: "0",
        },
      ],
    });
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const params = useParams();
  const employeeId = params.id;

  const updateEmployeeEvent = () => {
    updateEmployee(updateRequestData, employeeId);
  };

  // スクロール時にscrollIntoViewを使用するためにて定義してる
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isPreviewVisible, isComplete]);

  const { detailEmployee, fetchDetailEmployee, loading } =
    useEmployeeMaintenance();

  const { officeList } = useFetchOfficeList();
  const { departmentList } = useFetchDepartmentTree();

  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div
          className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9"
          ref={scrollBottomRef}
        >
          {!isComplete ? (
            <EmployeeMaintenanceEditForm
              setIsPreviewVisible={setIsPreviewVisible}
              isPreviewVisible={isPreviewVisible}
              updateEmployeeEvent={updateEmployeeEvent}
              setUpdateRequestData={setUpdateRequestData}
              employeeId={employeeId}
              fetchDetailEmployee={fetchDetailEmployee}
              detailEmployee={detailEmployee}
              officeList={officeList}
              departmentList={departmentList}
              loading={loading}
            />
          ) : (
            <EmployeeMaintenanceEditFormComplete />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
});
