import { AxiosResponse } from "axios";
import { api } from "./axiosConfig";

import {
  EmployeeTaskResponseType,
  updateEmployeeTaskResponseType,
  TaskListResponseType,
  TaskListRequestType,
} from "../types/api/EmployeeTask";

// 社員タスクリスト取得
export const getEmployeeTaskList = () => {
  return api
    .get<EmployeeTaskResponseType>(
      "/merchant/maintenance/employment_retirement_tasks/employees"
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 社員タスクの保存
export const postEmployeeTaskList = (data: updateEmployeeTaskResponseType) => {
  return api
    .put("/merchant/maintenance/employment_retirement_tasks/employee", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// タスク一覧を取得
export const getTaskList = () => {
  return api
    .get<TaskListResponseType>(
      "/merchant/maintenance/employment_retirement_tasks"
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// タスクを保存
export const postTask = (data: TaskListRequestType) => {
  return api
    .post("/merchant/", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
