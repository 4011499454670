import React, { memo } from "react";

export const ImprovementAdvice = memo((props: { advice: string }) => {
  const { advice } = props;

  return (
    <div className="mt-[30px] pt-[30px] border-t border-lightGray-100">
      <div className="flex justify-between mb-[30px]">
        <p className="font-bold text-[24px] text-lightGreen-200">
          職場改善のアドバイス
        </p>
      </div>
      <div className="flex items-center rounded-lg bg-lightGreen-100 p-[50px]">
        <div className="min-h-[50px] flex justify-center items-center">
          <p className="font-normal whitespace-pre-wrap">{advice}</p>
        </div>
      </div>
    </div>
  );
});
