import React, { FC } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { TitleAxisTick } from "../../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/TitleAxisTick";
import { ScoreAxisTick } from "../../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/ScoreAxisTick";
import { CustomizedDotOverall } from "../../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/CustomizedDotOverall";
import { CustomizedDotTheGroup } from "../../../atoms/stressCheckManagements/groupAnalytics/analyticsGraph/CustomizedDotTheGroup";
import { useGroupAnalytics } from "../../../../hooks/stressCheckManagements/useGroupAnalytics";

type Props = {
  the_group_data: {
    name: string;
    score: number;
    overall_score: number;
    comment: string;
    scales: {
      name: string;
      score: number;
      overall_score: number;
    }[];
  };
  num_of_grades: number;
  thresholds: number[][];
};

export const AnalyticsResultGraph: FC<Props> = (props) => {
  const { the_group_data, num_of_grades, thresholds } = props;
  const { faceIconHandleChange } = useGroupAnalytics();

  return (
    <>
      <div className="pt-[80px]">
        <p className="text-2xl text-lightGreen-200 font-bold ">
          {the_group_data.name}
        </p>
      </div>
      <div className="pt-10 flex justify-between">
        {/* 評価表示部 */}
        <div className="flex items-center">
          <p className="text-lg text-lightGreen-200 font-bold">評価</p>
          <p className="text-lg text-lightGreen-200 font-bold ml-[60px]">
            当該集団
          </p>
          <div className="flex items-center h-[45px] ml-5">
            <img
              src={faceIconHandleChange(the_group_data.score, thresholds)}
              alt="face-icon.png"
              width={40}
              height={40}
            />
            <div className="ml-5 flex items-end">
              <span className="text-3xl font-bold">{the_group_data.score}</span>
              <span className="pl-[7px] text-lg font-bold">/</span>
              <span className="pl-[5px] text-lg font-bold">
                {num_of_grades}
              </span>
            </div>
          </div>
          <p className="text-lg text-lightGreen-200 font-bold ml-10">全体</p>
          <div className="flex items-center h-[45px] ml-5">
            <img
              src={faceIconHandleChange(
                the_group_data.overall_score,
                thresholds
              )}
              alt="face-icon.png"
              width={40}
              height={40}
            />
            <div className="ml-5 flex items-end">
              <span className="text-3xl font-bold">
                {the_group_data.overall_score}
              </span>
              <span className="pl-[7px] text-lg font-bold">/</span>
              <span className="pl-[5px] text-lg font-bold">
                {num_of_grades}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {/* グラフ案内表示部 */}
          <div>
            <svg x={12} y={12} width={40} height={36} fill="green">
              <line
                x1="20"
                y1="18"
                x2="40"
                y2="18"
                stroke="#6CA893"
                strokeWidth={3}
              />
              <circle
                stroke="#6CA893"
                strokeWidth="2"
                cx="12"
                cy="18"
                r="10"
                fill="#6CA893"
              />
            </svg>
          </div>
          <p className="text-md ml-2">当該集団平均</p>
          <div className="ml-10">
            <svg x={12} y={12} width={50} height={40} fill="green">
              <line
                x1="20"
                y1="20"
                x2="50"
                y2="20"
                stroke="#EAAD3F"
                strokeWidth={3}
              />
              <circle
                stroke="#EAAD3F"
                strokeWidth="3"
                cx="15"
                cy="20"
                r="13"
                fill="#FADD9F"
              />
            </svg>
          </div>
          <p className="text-md ml-2">全体平均</p>
        </div>
      </div>
      {/* グラフ表示 */}
      <div className="w-full flex justify-center">
        <LineChart
          layout="vertical"
          width={800}
          height={80 * the_group_data.scales.length}
          data={the_group_data.scales}
          margin={{ top: 20, right: 50, left: 250, bottom: 40 }}
        >
          <CartesianGrid horizontal={false} />
          <XAxis
            type="number"
            domain={[1, 5]}
            tickLine={false}
            axisLine={false}
            tickMargin={20}
            tick={({ ...props }) => <ScoreAxisTick {...props} />}
          />
          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={false}
            tickMargin={60}
            tick={({ ...props }) => <TitleAxisTick {...props} />}
            padding={{ bottom: 25, top: 25 }}
          />
          <Tooltip
            formatter={(value: number) => value.toFixed(1)}
            itemSorter={(item) => {
              switch (item.dataKey) {
                case "name":
                  return 0;
                case "score":
                  return 1;
                default:
                  return 2;
              }
            }}
          />
          <Line
            name="全体"
            type="linear"
            dataKey="overall_score"
            strokeWidth={5}
            stroke="#EAAD3F"
            opacity={0.75}
            dot={({ ...props }) => <CustomizedDotOverall {...props} />}
          />
          <Line
            name="当該集団"
            type="linear"
            dataKey="score"
            strokeWidth={3}
            stroke="#6CA893"
            opacity={0.75}
            dot={({ ...props }) => <CustomizedDotTheGroup {...props} />}
          />
        </LineChart>
      </div>
    </>
  );
};
