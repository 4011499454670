import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { LangEnAnswerSheet } from "../../templates/stressChecks/LangEnAnswerSheet";
import { LangJpAnswerSheet } from "../../templates/stressChecks/LangJpAnswerSheet";
import { useFetchStressCheckQuestions } from "../../../hooks/stressChecks/useFetchStressCheckQuestions";
import { useSaveStressCheckAnswers } from "../../../hooks/stressChecks/useSaveStressCheckAnswers";

import { AnswersArrayType } from "../../../types/api/stressCheck";

export const AnswersContext = createContext<AnswersArrayType[]>([]);

export const StressCheckToAnswerSheet = () => {
  const location = useLocation();
  const stressCheckId = location.state as number;

  const { stressCheckQuestions } = useFetchStressCheckQuestions(stressCheckId);
  const { saveStressCheckAnswers } = useSaveStressCheckAnswers();

  const [isLangEnFlag, setLangEnFlag] = useState<boolean>(false);
  const onLangEnFlag = (): void => {
    setLangEnFlag(true);
  };
  const offLangEnFlag = (): void => {
    setLangEnFlag(false);
  };

  const [answers, setAnswers] = useState<AnswersArrayType[]>([]);

  const onClickAnswerHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    secId: number,
    queId: number
  ) => {
    const answersCopy = answers.slice();
    const section = answers.find((sec) => sec.sectionId == secId);
    if (section) {
      const question = section.questions.find(
        (que) => que.questionNumber == queId
      );
      if (question) {
        // 回答が同じだった場合中断
        if (question.answer === parseInt(e.target.value)) return;

        // 違う回答の場合、以前の回答削除
        const deleteQuestion = section.questions.findIndex(
          (question) => question.questionNumber === queId
        );
        section.questions.splice(deleteQuestion, 1);
      }
      // 質問に対する回答を追加とソートする処理
      const a = section.questions;
      a.push({ questionNumber: queId, answer: parseInt(e.target.value) }); // 回答を配列内に追加
      a.sort((a, b) => a.questionNumber - b.questionNumber); // 追加した後の配列を並び替え
      section.questions = a;

      // セクションの作り直しとソートする処理
      const deleteSection = answersCopy.findIndex(
        (answer) => answer.sectionId === secId
      );
      answersCopy.splice(deleteSection, 1);
      const setData = [...answersCopy, section];
      setData.sort((a, b) => a.sectionId - b.sectionId);

      // state更新
      setAnswers(setData);
    } else {
      // セクションごと回答を追加してstate更新
      setAnswers([
        ...answers,
        {
          sectionId: secId,
          questions: [
            { questionNumber: queId, answer: parseInt(e.target.value) },
          ],
        },
      ]);
    }
  };

  // data配列に識別するキーを付与する
  const dataAddKey = async (questionAnswer: number[][], data: object) => {
    answers.map((_ans, index) => {
      Object.defineProperty(data, `${stressCheckQuestions[index].section_id}`, {
        value: questionAnswer[index],
        writable: true,
        enumerable: true,
      });
    });
    return { answers: data };
  };

  const submitStressCheckToAnswer = async () => {
    if (!window.confirm("本当に送信しても宜しいですか？")) {
      return;
    }
    // 送信するオブジェクトを整形する処理
    const data = {};
    // 配列を作ってる
    const questionAnswer = answers.map((ans) => {
      const arr: number[] = [];
      ans.questions.map((que) => {
        arr.push(que.answer);
      });
      return arr;
    });

    // awaitで上記の処理が終わるまで処理を待つ
    const postData = await dataAddKey(questionAnswer, data);

    saveStressCheckAnswers(stressCheckId, postData);
  };

  return (
    <>
      {stressCheckQuestions.length && (
        <AnswersContext.Provider value={answers}>
          {!isLangEnFlag ? (
            <LangJpAnswerSheet
              onLangEnFlag={onLangEnFlag}
              stressCheckQuestions={stressCheckQuestions}
              onClickAnswerHandleChange={onClickAnswerHandleChange}
              submitStressCheckToAnswer={submitStressCheckToAnswer}
            />
          ) : (
            <LangEnAnswerSheet
              offLangEnFlag={offLangEnFlag}
              stressCheckQuestions={stressCheckQuestions}
              onClickAnswerHandleChange={onClickAnswerHandleChange}
              submitStressCheckToAnswer={submitStressCheckToAnswer}
            />
          )}
        </AnswersContext.Provider>
      )}
    </>
  );
};
