import React, { memo } from "react";
import ReactPaginate from "react-paginate";

export const Paginate = memo(
  (props: {
    offset: number;
    perPage: number;
    listLength: number;
    hight: number;
    handlePageChange: (data: { selected: number }) => void;
  }) => {
    const { offset, perPage, listLength, handlePageChange, hight } = props;

    const frameClassName = `h-${String(
      hight
    )} w-full mt-11 mb-10 flex justify-center`;
    return (
      <div className={frameClassName}>
        <p className="mr-auto text-lg leading-relaxed text-gray-700">
          1 - {offset + perPage} 件 / 全 {listLength}件中
        </p>
        <div className="flex mr-80">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(listLength / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
            marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
            pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
            onPageChange={handlePageChange} // クリック時のfunction
            containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
            activeClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // アクティブなページのliに着くクラス名
            previousClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「<」のliに着けるクラス名
            nextClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「>」のliに着けるクラス名
            disabledClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 使用不可の「<,>」に着くクラス名
            breakClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「...」に着くクラス名
            pageClassName={
              "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            }
            // aタグのクラス名
            pageLinkClassName={"block w-10 h-full text-center no-underline"}
            previousLinkClassName={"block w-10 h-full text-center no-underline"}
            nextLinkClassName={"block w-10 h-full text-center no-underline"}
            disabledLinkClassName={"block w-10 h-full text-center no-underline"}
            breakLinkClassName={"block w-10 h-full text-center no-underline"}
          />
        </div>
      </div>
    );
  }
);
