import React, { memo } from "react";

import EmployeeIcon from "../../../images/employee.png";
import ListIcon from "../../../images/list-icon.png";
import GridIcon from "../../../images/grid-icon.png";
import { Title } from "../../atoms/commons/Title";
import { PastEmployeeTable } from "../../atoms/departments/PastEmployeeTable";
import { EmployeeType } from "../../../types/api/employeeList";
import { Paginate } from "../Paginate";

export const PastEmployeeList = memo(
  (props: {
    employeeList: EmployeeType[];
    screenSwitchHandle: () => void;
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
  }) => {
    const { employeeList, screenSwitchHandle, paginateObj } = props;

    return (
      <>
        <div className="flex justify-between min-h-147">
          <Title src={EmployeeIcon}>社員紹介</Title>
          <div className="flex mt-14">
            <div className="flex w-32 h-10 py-2.5 pl-5 pr-4 rounded-lg bg-lightGreen-200 bg-opacity-10 relative">
              <img className="w-[21px] h-[20px]" src={ListIcon} />
              <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                リスト
              </span>
            </div>
            <button
              onClick={() => screenSwitchHandle()}
              type="button"
              className="flex w-36 h-10 py-2.5 pl-5 pr-4 rounded-lg relative"
            >
              <img className="w-[21px] h-[20px]" src={GridIcon} />
              <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                グリッド
              </span>
            </button>
          </div>
        </div>
        {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
        {/* <div className="h-12 mt-20 md:flex md:justify-between">
        <p className="text-md leading-relaxed ml-14 md:text-center py-2">
          1 - {offset + perPage} 人 / 全 {employees.length}人中
        </p>
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          表示人数
        </p>
        <CountSelectForm />
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          検索
        </p>
        <EmployeeSearchForm />
        <SearchButton />
      </div> */}
        {/*********************************************************************************/}

        <div className="mt-5 justify-center flex flex-col w-full">
          <table className="py-5 text-md justify-center mx-auto w-full">
            <thead>
              <tr className="border-b leading-none text-lightGreen-200">
                <th className="text-left py-5 w-10"></th>
                <th className="text-left py-5 w-24">名前</th>
                <th className="text-left py-5 w-24">部署</th>
                <th className="text-left py-5 w-20"></th>
                <th className="text-left py-5 w-20"></th>
                <th className="text-left py-5 w-20"></th>
              </tr>
            </thead>
            {employeeList.map((employee, i) => (
              <PastEmployeeTable
                key={i}
                id={employee.id}
                businessName={employee.businessName}
                departmentName={employee.departmentName}
                profileImage={employee.profileImage}
              />
            ))}
          </table>
        </div>
        <Paginate
          handlePageChange={paginateObj.handlePageChange}
          offset={paginateObj.offset}
          perPage={50}
          listLength={employeeList.length}
          hight={40}
        />
      </>
    );
  }
);
