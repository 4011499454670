import React, { FC } from "react";

type Props = {
  list: string[];
  marginTop?: boolean;
};

export const GroupAnalyticsListTableHeader: FC<Props> = (props) => {
  const { list, marginTop } = props;

  return (
    <>
      <p
        className={
          marginTop
            ? "leading-[45px] text-lg block text-lightGreen-200 font-bold mt-8"
            : "leading-[45px] text-lg block text-lightGreen-200 font-bold"
        }
      >
        {list[0]}
      </p>
      {list.map((item, index) => (
        <div key={index}>
          {index !== 0 && (
            <p
              className={
                (index + 1) % 2 === 0
                  ? "leading-[45px] pl-[12px] mt-2 text-lg block font-normal bg-lightGray-50"
                  : "leading-[45px] pl-[12px] mt-2 text-lg block font-normal"
              }
            >
              {item}
            </p>
          )}
        </div>
      ))}
    </>
  );
};
