import React, { memo, useState } from "react";
import ReactPaginate from "react-paginate";

import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company.png";
import { OfficeListTable } from "../../atoms/offices/OfficeListTable";
import { LinkButton } from "../../atoms/commons/LinkButton";
import { OfficeListResponseType } from "../../../types/api/office";

export const OfficeList = memo(
  (props: { officeList: OfficeListResponseType[] }) => {
    const { officeList } = props;

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);

    const handlePageChange = (data: { selected: number }) => {
      // eslint-disable-next-line
      let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
      setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
    };

    return (
      <>
        {officeList.length !== 0 ? (
          <>
            <Title src={Company}>事業場一覧</Title>
            <LinkButton
              path="/offices/create"
              placementClassName="absolute top-[65px] right-[35px]"
            >
              新規登録
            </LinkButton>
            <div className="mt-5 justify-center flex flex-col w-full">
              <table className="py-5 text-md justify-center mx-auto w-full">
                <thead>
                  <tr className="border-b leading-none text-lightGreen-200">
                    <th className="text-left py-5 w-60">事業場名</th>
                    <th className="text-right py-5 w-40">アクション</th>
                  </tr>
                </thead>
                {officeList.slice(offset, offset + perPage).map((office) => (
                  <OfficeListTable
                    key={office.id}
                    name={office.name}
                    officeId={office.id}
                  />
                ))}
              </table>
            </div>
            <div className="h-40 w-full mt-11 mb-10 flex justify-center">
              <p className="mr-auto text-lg leading-relaxed text-gray-700">
                1 - {offset + perPage} 件 / 全 {officeList.length}件中
              </p>
              <div className="flex mr-80">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(officeList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
                  marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
                  pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
                  onPageChange={handlePageChange} // クリック時のfunction
                  containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
                  activeClassName={
                    "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  } // アクティブなページのliに着くクラス名
                  previousClassName={
                    "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  } // 「<」のliに着けるクラス名
                  nextClassName={
                    "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  } // 「>」のliに着けるクラス名
                  disabledClassName={
                    "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  } // 使用不可の「<,>」に着くクラス名
                  breakClassName={
                    "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  } // 「...」に着くクラス名
                  pageClassName={
                    "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                  }
                  // aタグのクラス名
                  pageLinkClassName={
                    "block w-10 h-full text-center no-underline"
                  }
                  previousLinkClassName={
                    "block w-10 h-full text-center no-underline"
                  }
                  nextLinkClassName={
                    "block w-10 h-full text-center no-underline"
                  }
                  disabledLinkClassName={
                    "block w-10 h-full text-center no-underline"
                  }
                  breakLinkClassName={
                    "block w-10 h-full text-center no-underline"
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="border-b pb-10">
              <Title src={Company}>事業場一覧</Title>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-full pt-40 static">
              <span className="font-bold text-3xl text-lightGreen-200">
                データが存在しません
              </span>
            </div>
          </>
        )}
      </>
    );
  }
);
