import React, { memo } from "react";
import {
  StressCheckManagementListResponseType,
  StressCheckManagementResponseType,
} from "../../../types/api/stressCheckManagement";

export const StressCheckSummaryOffice = memo(
  (props: { stressCheckManagement: StressCheckManagementResponseType }) => {
    const { stressCheckManagement } = props;
    return (
      <>
        {!stressCheckManagement.office_summaries.length ? (
          <div className="flex justify-center items-center w-full pt-[100px] border-t border-lightGray-100">
            <span className="font-bold text-[28px] text-lightGreen-200">
              対象データがありません。
            </span>
          </div>
        ) : (
          <>
            {stressCheckManagement.office_summaries.map((summarie, index) => (
              <div
                key={index}
                className="w-full py-[30px] border-t border-lightGray-100"
              >
                <div className="mb-[30px]">
                  <span className="font-bold text-[24px] text-lightGreen-200">
                    {summarie.office_name}
                  </span>
                </div>
                <div className="flex justify-center items-center bg-lightGray-50 rounded-lg py-[30px]">
                  <div>
                    <span className="font-bold text-lg text-lightGreen-200">
                      在籍労働者数：
                    </span>
                    <span className="pl-[10px] font-normal text-lg">
                      {summarie.employee_count}人
                    </span>
                  </div>
                  <div className="ml-[80px]">
                    <span className="font-bold text-lg text-lightGreen-200">
                      検査を受けた労働者数：
                    </span>
                    <span className="pl-[10px] font-normal text-lg">
                      {summarie.stress_checked_count}人
                    </span>
                  </div>
                  <div className="ml-[80px]">
                    <span className="font-bold text-lg text-lightGreen-200">
                      面接対象者数：
                    </span>
                    <span className="pl-[10px] font-normal text-lg">
                      {summarie.interviewee_count}人
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </>
    );
  }
);
