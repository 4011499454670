import { useCallback, useEffect, useState } from "react";

import { getDepartmentTransition } from "../../api/apiHistory";
import { DepartmentTransitionResponseType } from "../../types/api/department";

export const useFetchDepartmentTransition = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transitionList, setTransitionList] =
    useState<DepartmentTransitionResponseType>({
      organization_history_frames: [
        {
          id: 0,
          save_date: "",
        },
      ],
      department_history_records: [
        {
          department_id: 0,
          continuous_abolition_count: 0,
          department_histories: [
            {
              id: 0,
              parent_id: null,
              name: "",
              abolition: true,
            },
          ],
        },
      ],
    });

  const fetchOrganizationHistoryList = useCallback(async () => {
    const res = await getDepartmentTransition();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("組織図変遷のデータ取得に失敗しました");
    }
    setIsLoading(false);

    setTransitionList(res.data.data);
  }, []);

  useEffect(() => {
    fetchOrganizationHistoryList();
  }, []);

  return {
    isLoading,
    transitionList,
  };
};
