import { useCallback, useEffect, useState } from "react";

import { getEmployeeMaintenanceList } from "../../api/apiEmployeeMaintenance";
import { EmployeeMaintenanceResponseType } from "../../types/api/employeeMaintenance";

// 社員一覧を取得
export const useFetchEmployeeMaintenanceList = () => {
  const [employeeMaintenanceList, setEmployeeMaintenanceList] = useState<
    Array<EmployeeMaintenanceResponseType>
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchEmployeeMaintenanceList = useCallback(async () => {
    const res = await getEmployeeMaintenanceList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("社員一覧取得に失敗しました");
    }
    setIsLoading(false);
    setEmployeeMaintenanceList(res.data.data.employees);
  }, []);

  useEffect(() => {
    fetchEmployeeMaintenanceList();
  }, []);

  return {
    isLoading,
    employeeMaintenanceList,
  };
};
