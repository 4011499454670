import React, { memo } from "react";

import { useFetchEmployeeMaintenanceList } from "../../../hooks/employeeMaintenance/useFetchEmployeeMaintenanceList";
import { EmployeeMaintenanceList } from "../../templates/employeeMaintenance/EmployeeMaintenanceList";

import { LoadingTemplate } from "../../templates/LoadingTemplate";

export const EmployeeMaintenance = memo(() => {
  const { isLoading, employeeMaintenanceList } =
    useFetchEmployeeMaintenanceList();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <EmployeeMaintenanceList
          employeeMaintenanceList={employeeMaintenanceList}
        />
      )}
    </>
  );
});
