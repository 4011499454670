import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getStressCheckManagementEmployeeList } from "../../api/apiStressCheckManagement";
import { LoginUserContext } from "../../components/providers/LoginUserProvider";
import { scManagementEmployeeListResponseType } from "../../types/api/stressCheckManagementEmployees";

export const useFetchStressCheckManagementEmployeeList = () => {
  const params = useParams();
  const scFrameID = params.id;
  const navigate = useNavigate();
  const user = useContext(LoginUserContext);
  const userRoll = user.loginUser?.data.user_roll;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    stressCheckManagementEmployeeList,
    setStressCheckManagementEmployeeList,
  ] = useState<scManagementEmployeeListResponseType>({
    high_stress_count: "",
    interviewee_count: "",
    stress_check_status: {
      complete_count: "",
      incomplete_count: "",
    },
    sc_target_employees: [],
  });
  const InterviewTitle = `面談指導要否(${stressCheckManagementEmployeeList.interviewee_count})`;
  const HighStressTitle = `高ストレス(${stressCheckManagementEmployeeList.high_stress_count})`;
  const StressCheckStatus = `受検(済: ${stressCheckManagementEmployeeList.stress_check_status.complete_count} 未: ${stressCheckManagementEmployeeList.stress_check_status.incomplete_count})`;
  const titleObj = {
    InterviewTitle,
    HighStressTitle,
    StressCheckStatus,
  };

  const onClickHandler = (stressCheckID: number) => {
    window.open(`/stress-checks/${stressCheckID}/result`);
  };

  const fetchStressCheckManagement = useCallback(async () => {
    const res = await getStressCheckManagementEmployeeList(scFrameID);

    if (res.status !== 200) {
      alert("ストレスチェック対象者一覧のデータ取得に失敗しました");
      setIsLoading(false);
      return navigate("/");
    }

    setIsLoading(false);
    setStressCheckManagementEmployeeList(res.data.data);
  }, []);

  useEffect(() => {
    fetchStressCheckManagement();
  }, []);

  return {
    isLoading,
    userRoll,
    titleObj,
    onClickHandler,
    stressCheckManagementEmployeeList,
  };
};
