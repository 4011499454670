import React, { FC, memo } from "react";

type Props = {
  name: string;
  executed_date: string;
  stress_checkId: number;
  status: string;
  agree_btn_status: string;
  onClickHandler: (stress_checkId: number, status: string) => void;
  onChangeModalFlag: () => void;
};

export const StressCheckListTable: FC<Props> = memo((props) => {
  const {
    name,
    executed_date,
    stress_checkId,
    status,
    agree_btn_status,
    onClickHandler,
    onChangeModalFlag,
  } = props;

  const actionButtonHandler = (status: string) => {
    switch (status) {
      case "0":
        return "受検する";
      case "1":
        return "未回答（期限切れ）";
      case "2":
        return "詳細";
    }
  };

  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="text-lightGreen-200 overflow-ellipsis truncate max-w-0 min-w-60">
          {name}
        </td>
        <td className="overflow-ellipsis truncate max-w-0">
          <p />
          {executed_date.length === 0 ? "未実施" : executed_date}
          <p />
        </td>
        <td className="mt-6 flex justify-end">
          <div className="flex justify-end">
            {/* 面談対象者選定が済んでいればボタン表示 */}
            <div className="w-[94px] mr-4">
              {agree_btn_status === "1" && (
                <button
                  onClick={() => onChangeModalFlag()}
                  className="py-[5px] px-[10px] text-sm rounded-lg focus:outline-none border-lightGreen-200 border hover:opacity-[40%]"
                >
                  <p className="text-lg font-w6 leading-relaxed text-lightGreen-200 w-[72px]">
                    同意取得
                  </p>
                </button>
              )}
            </div>

            <div className="w-[184px] flex justify-center">
              <button
                onClick={() => onClickHandler(stress_checkId, status)}
                disabled={status === "1" ? true : false}
                className={
                  status === "1"
                    ? "py-[5px] px-[10px] text-sm rounded-lg focus:outline-none border-lightGray-200 border"
                    : "py-[5px] px-[10px] text-sm rounded-lg focus:outline-none border-lightGreen-200 border hover:opacity-[40%]"
                }
              >
                <p
                  className={
                    status === "1"
                      ? "text-lg font-w6 leading-relaxed text-lightGray-200 whitespace-nowrap"
                      : "text-lg font-w6 leading-relaxed text-lightGreen-200 w-[72px]"
                  }
                >
                  {actionButtonHandler(status)}
                </p>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
});
