import React, { memo } from "react";
import {
  FieldError,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";

import SettingsIcon from "../../../images/settings.png";
import { AdminReaderUpdateFormType } from "../../../types/api/settingsRoll";
import { Title } from "../../atoms/commons/Title";
import SelectTag from "../../../images/choice.png";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { ErrorMassage } from "../../atoms/commons/ErrorMassage";

export const EmployeeRollUpdateForm = memo(
  (props: {
    updateRollObj: {
      register: UseFormRegister<AdminReaderUpdateFormType>;
      handleSubmit: UseFormHandleSubmit<AdminReaderUpdateFormType>;
      watch: UseFormWatch<AdminReaderUpdateFormType>;
      onSubmit: SubmitHandler<AdminReaderUpdateFormType>;
      errors: {
        emailAddress?: FieldError | undefined;
        roll?: FieldError | undefined;
      };
    };
  }) => {
    const { register, handleSubmit, watch, onSubmit, errors } =
      props.updateRollObj;

    return (
      <>
        <div className="mb-10 min-h-full">
          <Title src={SettingsIcon}>権限更新</Title>
        </div>
        <div className="mt-8 mb-10 border-t">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-14 pb-6 items-center flex relative">
              <RequiredFormLabel
                title="権限"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
              />

              <div className="border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative">
                <select
                  className="appearance-none rounded-lg w-80 h-12 pl-4
                  focus:outline-none disabled:text-black disabled:opacity-100"
                  {...register("roll", {
                    required: true,
                  })}
                  name={"roll"}
                >
                  <option value="">権限内容を選んでください</option>
                  <option value="01">管理者</option>
                  <option value="02">閲覧者</option>
                </select>
                <img
                  className="w-3 h-2.5 absolute top-0 right-0 mr-8 mt-5"
                  src={SelectTag}
                ></img>
              </div>
              {errors.roll?.type === "required" && (
                <ErrorMassage error="required" style="employee" />
              )}
            </div>
            <div className="py-6 items-center flex relative">
              <RequiredFormLabel
                title="E-mail"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("emailAddress", {
                  required: true,
                  pattern:
                    /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i,
                })}
                className="w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200"
                value={watch("emailAddress")}
              />
              {errors.emailAddress?.type === "required" && (
                <ErrorMassage error="required" style="employee" />
              )}
              {errors.emailAddress?.type === "pattern" && (
                <ErrorMassage error="email" style="employee" />
              )}
            </div>
            <div className="pt-28	pb-60 text-center">
              <button
                type="submit"
                className="inline-block w-36 h-13 ml-7 rounded-lg text-sm border-lightGreen-200 border hover:bg-lightGreen-200 hover:text-white text-lightGreen-200"
              >
                更新
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
);
