import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SendMailResponseType } from "../../types/api/stressCheckManagement";
import {
  getGuidanceMail,
  getInterviewNotifyMail,
  getRemindMail,
  postGuidanceMail,
  postInterviewNotifyMail,
  postRemindMail,
} from "../../api/apiStressCheckManagement";

export const useSendMail = () => {
  const getParams = useParams();
  const stressCheckId = getParams.stressCheckId;

  const navigate = useNavigate();

  const [sendMail, setSendMail] = useState<SendMailResponseType>({
    stress_check_frame_id: "",
    subject: "",
    content: "",
  });

  // ガイダンスメールデータの取得
  const fetchGuidanceMail = useCallback(async () => {
    const res = await getGuidanceMail(stressCheckId);

    if (res.status !== 200) {
      alert("ガイダンスメールのデータ取得に失敗しました");
    }

    setSendMail(res.data.data);
  }, []);

  // リマインドメールデータの取得
  const fetchRemindMail = useCallback(async () => {
    const res = await getRemindMail(stressCheckId);

    if (res.status !== 200) {
      alert("リマインドメールのデータ取得に失敗しました");
    }

    setSendMail(res.data.data);
  }, []);

  // 面談要否メールデータの取得
  const fetchInterviewNotifyMail = useCallback(async () => {
    const res = await getInterviewNotifyMail(stressCheckId);

    if (res.status !== 200) {
      return alert("面談要否メールのデータ取得に失敗しました");
    }

    setSendMail(res.data.data);
  }, []);

  // ガイダンスメールの送信
  const sendGuidanceMail = useCallback(async () => {
    const res = await postGuidanceMail(stressCheckId);

    if (res.status !== 200) {
      switch (res.data.code) {
        case "SCF_CONDITION_ERROR":
          alert("ストレスチェックの状態が不適切です");
          break;
        default:
          alert("面談対象者の登録に失敗しました");
          break;
      }
      return navigate(`/stress-checks-management/${stressCheckId}`);
    }

    alert("ガイダンスメールの送信に成功しました");
    navigate(`/stress-checks-management/${stressCheckId}/sendmail`);
  }, []);

  // リマインドメールの送信
  const sendRemindMail = useCallback(async () => {
    const res = await postRemindMail(stressCheckId);

    if (res.status !== 200) {
      switch (res.data.code) {
        case "SCF_CONDITION_ERROR":
          alert("ストレスチェックの状態が不適切です");
          break;
        default:
          alert("面談対象者の登録に失敗しました");
          break;
      }
      return navigate(`/stress-checks-management/${stressCheckId}`);
    }

    alert("リマインドメールの送信に成功しました");
    navigate(`/stress-checks-management/${stressCheckId}/sendmail`);
  }, []);

  // 面談指導要否メール送信
  const sendInterviewNotifyMail = useCallback(async () => {
    const res = await postInterviewNotifyMail(stressCheckId);

    if (res.status !== 200) {
      switch (res.data.code) {
        case "SCF_CONDITION_ERROR":
          alert("ストレスチェックの状態が不適切です");
          break;
        default:
          alert("面談対象者の登録に失敗しました");
          break;
      }
      return navigate(`/stress-checks-management/${stressCheckId}`);
    }

    alert("面談指導要否メールの送信に成功しました");
    navigate(`/stress-checks-management/${stressCheckId}/sendmail`);
  }, []);

  const backButtonHandler = () => {
    navigate(`/stress-checks-management/${stressCheckId}/sendmail`);
  };

  return {
    fetchGuidanceMail,
    fetchRemindMail,
    fetchInterviewNotifyMail,
    sendMail,
    sendGuidanceMail,
    sendRemindMail,
    sendInterviewNotifyMail,
    backButtonHandler,
  };
};
