import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export const ReturnButton: FC = () => {
  const navigate = useNavigate();

  return (
    <button
      type="submit"
      onClick={() => navigate(`../`)}
      className="inline-block w-36 h-13 border rounded-lg border-lightGreen-200"
    >
      <span className="text-lightGreen-200 text-sm">戻る</span>
    </button>
  );
};
