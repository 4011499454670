import React, { memo, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import backIcon from "../../../images/back-icon.png";
import Choice from "../../../images/choice.png";
import {
  DepartmentFormType,
  DepartmentList,
} from "../../../types/api/department";
import { FormLabel } from "../commons/FormLabel";
import { useFetchDepartment } from "../../../hooks/department/useFetchDepartment";
import { useUpdateDepartment } from "../../../hooks/department/useUpdateDepartment";
import { ErrorMassage } from "../commons/ErrorMassage";
import { OpenAlertbutton } from "../../atoms/departments/OpenAlertButton";

export const ModalEditForm = memo(
  (props: {
    department_id: number;
    fetchDepartmentTree: () => Promise<void>;
    fetchDepartmentList: () => Promise<void>;
    onChangeModalFlag: () => void;
    departmentList: DepartmentList;
    submitCheckAlert: (id: number) => Promise<void>;
    parentId: number;
  }) => {
    const {
      department_id,
      onChangeModalFlag,
      fetchDepartmentTree,
      fetchDepartmentList,
      departmentList,
      submitCheckAlert,
      parentId,
    } = props;

    const [loading, setLoading] = useState(true);

    const { department, fetchDepartment } = useFetchDepartment();

    const { updateDepartment } = useUpdateDepartment();

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<DepartmentFormType>({
      defaultValues: { name: "", parent_id: "" },
    });

    useEffect(() => {
      fetchDepartment(department_id, setLoading);
      setValue("name", department.name);
      setValue("parent_id", department.parent_id);
    }, [loading]);

    const updateDepartmentSubmit: SubmitHandler<DepartmentFormType> = async (
      date
    ) => {
      await updateDepartment(department.id, date);
      await fetchDepartmentTree();
      await fetchDepartmentList();
      onChangeModalFlag();
    };

    return (
      <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
        <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
          編集フォーム
        </p>
        <button onClick={onChangeModalFlag} className="absolute top-5 right-5">
          <img src={backIcon} alt="back-icon" />
        </button>
        <form onSubmit={handleSubmit(updateDepartmentSubmit)}>
          <div className="w-full flex flex-col justify-center items-center border-t border-lightGray-100">
            <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
              <FormLabel
                title="部署名"
                className="text-lightGreen-200 font-semibold"
              />
              <div className="ml-[75px]">
                <input
                  {...register("name", {
                    required: true,
                    maxLength: 50,
                  })}
                  className="w-[400px] mb-1 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200"
                />
                {errors.name?.type === "required" && (
                  <ErrorMassage error="required" style="modal" />
                )}
                {errors.name?.type === "maxLength" && (
                  <ErrorMassage error="maxLength-50" style="modal" />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
              <FormLabel
                title="上位部署"
                className="text-lightGreen-200 font-semibold"
              />
              <div className="relative">
                <img
                  src={Choice}
                  alt="choice-icon"
                  className="w-3 h-3 absolute top-1 right-0 m-4 pointer-events-none"
                />
                <select
                  className="border border-lightGreen-200 appearance-none rounded-lg w-[400px] h-12 pl-4 ml-[75px]
                focus:outline-none disabled:text-black disabled:opacity-100"
                  {...register("parent_id")}
                >
                  <option value="0">上位部署なし</option>
                  {departmentList.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex mt-10 justify-between w-2/3">
              <button
                type="submit"
                className="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg hover:text-white hover:bg-lightGreen-200 text-lightGreen-200 text-lg"
              >
                <span className="text-lg font-w6 leading-relaxed">保存</span>
              </button>
              <button
                type="button"
                onClick={() => submitCheckAlert(parentId)}
                className="w-32 border-gray-400 border py-2 px-4 rounded-lg hover:bg-gray-400 text-lg hover:text-white"
              >
                <span className="text-lg font-w6 leading-relaxed">廃止</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
);
