import React, { FC, memo } from "react";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import Search from "../../../images/search.png";
import { GroupAnalyticsOverall } from "../../organisms/stressCheckManagements/groupAnalytics/GroupAnalyticsOverall";
import FaceIcons from "../../../images/face-icons-2.png";
import { GroupAnalyticsIndividual } from "../../organisms/stressCheckManagements/groupAnalytics/GroupAnalyticsIndividual";
import { GroupAnalyticsReportResponseType } from "../../../types/api/groupAnalytics";

type Props = {
  groupAnalyticsList: GroupAnalyticsReportResponseType | undefined;
  selectedTabName: string;
  isLoading: boolean;
  tabSwitchHandle: (tabName: string) => void;
};

export const GroupAnalyticsForm: FC<Props> = memo((props) => {
  const { groupAnalyticsList, selectedTabName, isLoading, tabSwitchHandle } =
    props;

  const tabList = [
    "事業場",
    "部署",
    "性別",
    "役職",
    "職種",
    "オプション1",
    "オプション2",
    "オプション3",
  ];

  const comprehensiveEvaluationList = [
    "総合評価",
    "受験者数",
    "高ストレス者数 (割合)",
    "医師面接指導対象者数",
  ];
  const stressFactorList = [
    "ストレスの原因と考えられる因子",
    "心理的な仕事の負担（量）",
    "心理的な仕事の負担（質）",
    "自覚的な身体的負担度",
    "職場の対人関係でのストレス",
    "職場環境によるストレス",
    "仕事のコントロール度",
    "技能の活用度",
    "仕事の適正度",
    "働きがい",
  ];
  const mentalAndPhysicalReactionList = [
    "ストレスによって起こる心身の反応",
    "活気",
    "イライラ感",
    "疲労感",
    "不安感",
    "抑うつ感",
    "身体愁訴",
  ];
  const supportStatus = [
    "ストレス反応に対する支援状況",
    "上司からのサポート",
    "同僚からのサポート",
    "家族や友人からのサポート",
    "仕事や生活の満足度",
  ];

  return (
    <>
      <>
        <div className="flex items-center justify-between">
          <Title src={StressCheckManagement}>集団分析レポート</Title>
          <div className="mt-[60px] pr-[40px]">
            <div className="flex items-center">
              <span className="font-bold text-lg text-lightGreen-200">
                実施期間
              </span>
              {groupAnalyticsList ? (
                <span className="pl-[16px]">
                  {groupAnalyticsList.start_date} ~{" "}
                  {groupAnalyticsList.end_date}
                </span>
              ) : (
                <span className="pl-[16px]">----/--/-- ~ ----/--/--</span>
              )}
            </div>
          </div>
        </div>
        <div className="mt-[30px] pt-[30px] border-t border-lightGray-100"></div>
        <div className="flex justify-between items-center">
          <div>
            <ul className="flex">
              {tabList.map((tabName, index) => (
                <div key={index}>
                  <li
                    className={
                      tabName === selectedTabName
                        ? "px-[18px] py-2 mr-2.5 rounded-lg bg-lightGreen-100"
                        : "px-[18px] py-2 mr-2.5 rounded-lg"
                    }
                  >
                    <button
                      className="hover:opacity-50"
                      onClick={() => tabSwitchHandle(tabName)}
                    >
                      <span className="text-lightGreen-200 text-lg">
                        {tabName}
                      </span>
                    </button>
                  </li>
                </div>
              ))}
            </ul>
          </div>
          <div>
            <button className="py-3 px-6 flex justify-center items-center border border-lightGreen-200 rounded-lg">
              <img
                src={Search}
                alt="icon"
                className="h-[18px] w-[18px] mr-2.5"
              />
              <span className="text-lightGreen-200 text-lg">絞り込み</span>
            </button>
          </div>
        </div>
        {!groupAnalyticsList || isLoading ? (
          <div className="h-full w-full flex flex-col justify-center items-center py-[30vh]">
            <div className="animate-spin h-16 w-16 border-4 border-lightGreen-200 rounded-full border-t-transparent"></div>
          </div>
        ) : (
          <>
            <GroupAnalyticsOverall
              comprehensiveEvaluationList={comprehensiveEvaluationList}
              stressFactorList={stressFactorList}
              mentalAndPhysicalReactionList={mentalAndPhysicalReactionList}
              supportStatus={supportStatus}
              num_of_grades={groupAnalyticsList.num_of_grades}
              thresholds={groupAnalyticsList.thresholds}
              tableList={groupAnalyticsList.the_group_list}
            />
            <div className="mt-[40px] flex justify-center">
              <img
                src={FaceIcons}
                alt="face-icons.png"
                width={950}
                height={187}
              />
            </div>
            {groupAnalyticsList.the_group_list.map((table, index) => (
              <div key={index}>
                <GroupAnalyticsIndividual
                  comprehensiveEvaluationList={comprehensiveEvaluationList}
                  num_of_grades={groupAnalyticsList.num_of_grades}
                  thresholds={groupAnalyticsList.thresholds}
                  table={table}
                  isFirst={index === 0}
                />
              </div>
            ))}
          </>
        )}
      </>
    </>
  );
});
