import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getEmployeeList } from "../../api/apiEmployee";
import {
  departmentType,
  EmployeeResponseType,
  EmployeeType,
} from "../../types/api/employeeList";

export const useFetchEmployeeList = () => {
  const [employeeList, setEmployeeList] = useState<EmployeeType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // department_idのクエリを取得する処理
  const query = new URLSearchParams(useLocation().search);
  const departmentId = query.get("department_id");

  /********************************************************
   * TODO: URLをそのまま関数に渡すとバグの原因になるかもなので/merchant/を固定してパラーメーターで文字列を渡すように処理を変更
   *******************************************************/
  // クエリが存在すればフィルター用のURLを格納し、なければ社員紹介用のURLを格納する
  const URL = departmentId
    ? `/merchant/employees?department_id=` + `${departmentId}`
    : "/merchant/employees";

  const fetchEmployeeList = useCallback(async () => {
    const res = await getEmployeeList(URL);
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("社員一覧取得に失敗しました");
    }

    const employees: EmployeeType[] = res.data.data.employees.map(
      (employee: EmployeeResponseType) => {
        const couplingDepartmentName = employee.departments
          .sort((a: departmentType, b: departmentType) => {
            if (a.concurrent === false && b.concurrent === true) {
              return -1;
            } else {
              return 1;
            }
          })
          .reduce((n: string, m: departmentType, i: number) => {
            if (i === 0) {
              return n + m.name;
            } else {
              return `${n}/${m.name}(兼任)`;
            }
          }, "");
        return {
          id: employee.id,
          businessName: employee.business_name,
          businessNameKana: employee.business_name_kana,
          position: employee.position,
          occupation: employee.occupation,
          departmentName: couplingDepartmentName,
          cordColor: employee.cord_color,
          profileImage: employee.profile_image,
        };
      }
    );
    setEmployeeList(employees);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchEmployeeList();
  }, []);

  return {
    isLoading,
    employeeList,
  };
};
