import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  DepartmentFormType,
  DepartmentListResponseType,
  OrganizationTreesResponseType,
  OrganizationListResponseType,
  DepartmentResumeResponseType,
} from "../types/api/department";

// 組織図取得
export const getDepartmentTree = () => {
  return api
    .get<OrganizationTreesResponseType>("/merchant/departments/trees")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署一覧
export const getDepartmentList = () => {
  return api
    .get<OrganizationListResponseType>("/merchant/maintenance/departments")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署リスト取得
export const getDepartments = () => {
  return api
    .get<DepartmentListResponseType>("/merchant/departments")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署単体取得
export const getDepartment = (id: number) => {
  return api
    .get(`/merchant/departments/${id}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署登録
/********************************************************
 * TODO: DepartmentFormTypeの型をRequest用に調整したい。
 *******************************************************/
export const postDepartment = (department: DepartmentFormType) => {
  return api
    .post("merchant/maintenance/department", department)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署編集
export const putDepartment = (
  id: number,
  department: { name: string; parent_id: string }
) => {
  return api
    .put(`merchant/maintenance/department/${id}`, department)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署再開
export const putDepartmentResume = (
  parentId: DepartmentResumeResponseType,
  departmentId: number
) => {
  return api
    .put(`/merchant/maintenance/department/${departmentId}/restart`, parentId)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 部署廃止
export const deleteDepartmentAbolition = (departmentId: number) => {
  return api
    .delete(`/merchant/maintenance/department/${departmentId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
