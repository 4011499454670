import { useState } from "react";

export const usePaginate = () => {
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const handlePageChange = (data: { selected: number }) => {
    // eslint-disable-next-line
    let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  };

  return {
    handlePageChange,
    offset,
    perPage,
  };
};
