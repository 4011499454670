import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SendMailFlagStatusResponseType } from "../../types/api/stressCheckManagement";
import { getSendMailFlagStatus } from "../../api/apiStressCheckManagement";

export const useFetchSendMailFlagStatus = () => {
  const getParams = useParams();
  const stressCheckId = getParams.stressCheckId;

  const [sendMailStatus, setSendMailStatus] =
    useState<SendMailFlagStatusResponseType>();

  const fetchSendMailFlagStatus = useCallback(async () => {
    const res = await getSendMailFlagStatus(stressCheckId);

    if (res.status !== 200) {
      alert("メールのボタンフラグステータスのデータ取得に失敗しました");
    }

    setSendMailStatus(res.data.data);
  }, []);

  useEffect(() => {
    fetchSendMailFlagStatus();
  }, []);

  return {
    sendMailStatus,
  };
};
