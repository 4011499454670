import React, { memo } from "react";

import { Title } from "../../atoms/commons/Title";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { FormBottons } from "../../atoms/commons/FormBottons";
import Mail from "../../../images/mail.png";
import { CreateFormLabel } from "../../atoms/commons/CreateFormLabel";

export const ContactsForm = memo(
  (props: {
    saveContact: (e: any, content: any) => Promise<void>;
    contactContent: string;
    changeInputValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    setContactContent: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    const { saveContact, contactContent, changeInputValue, setContactContent } =
      props;
    return (
      <>
        <div className="flex justify-between min-h-147">
          <Title src={Mail}>お問い合わせ先設定</Title>
        </div>
        <ExplanatoryText>
          メールに記載するお問い合わせ先を設定してください
        </ExplanatoryText>
        <form>
          <div className="py-5 border-t flex items-center">
            <CreateFormLabel
              title="本文"
              className="w-44 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <textarea
              name="content"
              value={contactContent}
              onChange={changeInputValue}
              className="w-9/12 min-h-[500px] p-5 whitespace-pre-wrap border rounded-lg text-sm border-lightGreen-200 resize-none"
            />
          </div>
          <FormBottons
            firstButtonOnClickIvent={() => setContactContent("")}
            seccondButtonOnClickIvent={(e) => saveContact(e, contactContent)}
            frameClassName="mt-10	pb-20 text-center"
            firstButtonClassName="inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200"
            seccondButtonClassName="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
            firstButtonChildren="リセット"
            seccondButtonChildren="更新"
          />
        </form>
      </>
    );
  }
);
