import React, { memo, useState } from "react";
import ReactPaginate from "react-paginate";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee-management.png";
import { LinkButton } from "../../atoms/commons/LinkButton";
import { EmployeeMaintenanceTable } from "../../atoms/employeeMaintenance/EmployeeMaintenanceTable";
import { EmployeeMaintenanceResponseType } from "../../../types/api/employeeMaintenance";

export const EmployeeMaintenanceList = memo(
  (props: { employeeMaintenanceList: EmployeeMaintenanceResponseType[] }) => {
    const { employeeMaintenanceList } = props;
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(50);

    const handlePageChange = (data: { selected: number }) => {
      // eslint-disable-next-line
      let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
      setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
    };

    return (
      <>
        <div className="flex justify-between min-h-147">
          <Title src={EmployeeManagement}>社員管理</Title>
          <LinkButton
            path="create"
            placementClassName="absolute top-[65px] right-[35px]"
          >
            新規登録
          </LinkButton>
        </div>
        {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
        {/* <div className="h-12 mt-20 md:flex md:justify-between">
        <p className="text-md leading-relaxed ml-14 md:text-center py-2">
          1 - {offset + perPage} 人 / 全 {employees.length}人中
        </p>
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          表示人数
        </p>
        <CountSelectForm />
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          検索
        </p>
        <EmployeeSearchForm />
        <SearchButton />
      </div> */}
        {/*********************************************************************************/}

        <div className="mt-5 justify-center flex flex-col w-full">
          <table className="py-5 text-md justify-center mx-auto w-full">
            <thead>
              <tr className="border-b leading-none text-lightGreen-200">
                <th className="text-left py-5 w-24">社員番号</th>
                <th className="text-left py-5 w-44">氏名</th>
                <th className="text-left py-5 w-24">就業状態</th>
                <th className="text-left py-5 w-24">事業場</th>
                <th className="text-left py-5 w-24">アカウント</th>
                <th className="text-left py-5 w-20"></th>
              </tr>
            </thead>
            {employeeMaintenanceList
              .slice(offset, offset + perPage)
              .map((employee, i) => (
                <EmployeeMaintenanceTable
                  key={i}
                  employeeNo={employee.employee_no}
                  id={employee.id}
                  lastName={employee.last_name}
                  firstName={employee.first_name}
                  employmentStatus={employee.status}
                  officeName={employee.office_name}
                  accountStatus={employee.account_status}
                />
              ))}
          </table>
        </div>
        <div className="h-40 w-full mt-11 mb-10 flex justify-center">
          <p className="mr-auto text-lg leading-relaxed text-gray-700">
            1 - {offset + perPage} 人 / 全 {employeeMaintenanceList.length}人中
          </p>
          <div className="flex mr-80">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(employeeMaintenanceList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
              marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
              pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
              onPageChange={handlePageChange} // クリック時のfunction
              containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
              activeClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // アクティブなページのliに着くクラス名
              previousClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「<」のliに着けるクラス名
              nextClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「>」のliに着けるクラス名
              disabledClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 使用不可の「<,>」に着くクラス名
              breakClassName={
                "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              } // 「...」に着くクラス名
              pageClassName={
                "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
              }
              // aタグのクラス名
              pageLinkClassName={"block w-10 h-full text-center no-underline"}
              previousLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              nextLinkClassName={"block w-10 h-full text-center no-underline"}
              disabledLinkClassName={
                "block w-10 h-full text-center no-underline"
              }
              breakLinkClassName={"block w-10 h-full text-center no-underline"}
            />
          </div>
        </div>
      </>
    );
  }
);
