import React from "react";

export const Footer = () => {
  return (
    <>
      <footer className="h-24 position right-0 buttom-0 text-right w-4/5">
        <p className="text-lightGreen-300 pt-5">@2022 CAPS株式会社</p>
      </footer>
    </>
  );
};
