import { useCallback, useState } from "react";

import {
  getDetailOffice,
  postOffice,
  putDetailOffice,
} from "../../api/apiOffice";
import { DetailOfficeType, OfficeRequestType } from "../../types/api/office";

export const useOffice = () => {
  const [detailOffice, setDetailOffice] = useState<DetailOfficeType>({
    id: "",
    name: "",
    address: "",
    postcode: "",
    phone_number: "",
  });
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const registerOffice = useCallback(async (data) => {
    const res = await postOffice(data);

    if (res.status !== 201) {
      return alert("事業場登録に失敗しました");
    }

    setIsComplete(true);
  }, []);

  const fetchDetailOffice = useCallback(
    async (
      officeId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await getDetailOffice(officeId);

      if (res.status !== 200) {
        return alert("事業場詳細のデータ取得に失敗しました");
      }

      setDetailOffice(res.data.data);
      setLoading(false);
    },
    []
  );

  const updateDetailOffice = useCallback(
    async (officeId: string | undefined, data: OfficeRequestType) => {
      const res = await putDetailOffice(officeId, data);

      if (res.status !== 201) {
        return alert("事業場詳細のデータ更新に失敗しました");
      }

      setDetailOffice(res.data.data);
      setIsComplete(true);
    },
    []
  );

  return {
    registerOffice,
    fetchDetailOffice,
    detailOffice,
    updateDetailOffice,
    isComplete,
  };
};
