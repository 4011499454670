import React, { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const LoginButton: FC<Props> = memo((props) => {
  const { children, onClick } = props;
  return (
    <button className="Group32 relative" style={{ width: 136, height: 50 }}>
      <p
        className="absolute text-lg font-w6 leading-none text-gray-700"
        style={{ left: 33, top: 16 }}
        onClick={onClick}
      >
        {children}
      </p>
      <div className="Rectangle132 w-full h-12 border rounded-lg border-gray-700" />
    </button>
  );
});
