import React, { memo } from "react";

export const AdminStressCheckTargetEmployeesTable = memo(
  (props: {
    employeeNo: string;
    stressCheckID: number;
    lastName: string;
    firstName: string;
    interviewTarget: boolean;
    hightStress: boolean;
    answered: boolean;
    onClickHandler: (stressCheckId: number) => void;
  }) => {
    const {
      employeeNo,
      stressCheckID,
      lastName,
      firstName,
      interviewTarget,
      hightStress,
      answered,
      onClickHandler,
    } = props;

    // SC回答済みであればボタンを活性化させるClassNameの値を付与する
    const answeredClassName = answered
      ? "h-10 w-20 text-sm rounded-lg border-lightGreen-200 border hover:bg-lightGreen-200 hover:text-white text-lightGreen-200"
      : "h-10 w-20 text-sm rounded-lg border-lightGreen-200 border bg-lightGray-100";

    return (
      <tbody>
        <tr className="text-lightGreen-200 border-b hover:bg-lightGreen-100 h-24">
          <td className="truncate max-w-0">
            <span title={employeeNo}>{employeeNo}</span>
          </td>
          <td className="text-lightGreen-200 truncate max-w-0">
            {lastName} {firstName}
          </td>

          <td className="truncate max-w-0 text-center">
            {interviewTarget ? "要" : ""}
          </td>
          <td className="truncate max-w-0 text-center">
            {hightStress ? "高ストレス" : ""}
          </td>

          <td className="truncate max-w-0 text-right">
            <button
              onClick={() => onClickHandler(stressCheckID)}
              disabled={!answered}
              className={answeredClassName}
            >
              <p className="text-lg font-w6 leading-relaxed">
                {answered ? "回答済み" : "未回答"}
              </p>
            </button>
          </td>
        </tr>
      </tbody>
    );
  }
);
