import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { InterviewGuidanceFormType } from "../../../types/api/stressCheckManagement";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { ReturnStressCheckManagementButton } from "../../atoms/stressCheckManagements/ReturnStressCheckManagementButton";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { useInterviewGuidance } from "../../../hooks/stressCheckManagements/useInterviewGuidance";

export const InterviewGuidanceEditForm = () => {
  const params = useParams();
  const stressCheckId = params.stressCheckId;

  const [loading, setLoading] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<InterviewGuidanceFormType>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      sentence_for_target: "",
      sentence_for_not_target: "",
    },
  });

  const { fetchInterviewGuidance, saveInterviewGuidance, interviewGuidance } =
    useInterviewGuidance();

  const onSubmit: SubmitHandler<InterviewGuidanceFormType> = (data) => {
    saveInterviewGuidance(stressCheckId, data);
  };

  useEffect(() => {
    fetchInterviewGuidance(stressCheckId, setLoading);
    if (interviewGuidance.sentence_for_not_target !== "") {
      setValue("sentence_for_target", interviewGuidance.sentence_for_target);
      setValue(
        "sentence_for_not_target",
        interviewGuidance.sentence_for_not_target
      );
    }
  }, [loading]);

  return (
    <>
      {interviewGuidance && (
        <>
          <Title src={StressCheckManagement}>面談指導要否文編集</Title>
          <ExplanatoryText>
            面談要否文を編集する場合は、内容を書き換えの上、更新ボタンを押してください。
          </ExplanatoryText>
          <div className="pt-[30px] border-t border-lightGray-100">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col justify-center items-center">
                <div className="mb-[30px]">
                  <RequiredFormLabel
                    title="面談対象者に表示する文章"
                    className="font-bold text-2xl  text-lightGreen-200"
                  />
                </div>
                <div>
                  {errors.sentence_for_target?.types?.required && (
                    <p className="text-red-600">この項目は必須です。</p>
                  )}
                  {errors.sentence_for_target?.types?.maxLength && (
                    <p className="text-red-600">
                      1000文字以内で入力してください。
                    </p>
                  )}
                  <textarea
                    {...register("sentence_for_target", {
                      required: true,
                      maxLength: 1000,
                    })}
                    placeholder="1000文字以内で入力してください。"
                    value={watch("sentence_for_target")}
                    className="resize-none w-[800px] h-[300px] px-5 py-1 border rounded-lg text-sm border-lightGreen-200"
                  />
                </div>
              </div>
              <div className="mt-[50px] pt-[50px] flex flex-col justify-center items-center border-t border-lightGray-100">
                <div className="mb-[30px]">
                  <RequiredFormLabel
                    title="面談非対象者に表示する文章"
                    className="font-bold text-2xl  text-lightGreen-200"
                  />
                </div>
                <div>
                  {errors.sentence_for_not_target?.types?.required && (
                    <p className="text-red-600">この項目は必須です。</p>
                  )}
                  {errors.sentence_for_not_target?.types?.maxLength && (
                    <p className="text-red-600">
                      1000文字以内で入力してください。
                    </p>
                  )}
                  <textarea
                    {...register("sentence_for_not_target", {
                      required: true,
                      maxLength: 1000,
                    })}
                    placeholder="1000文字以内で入力してください。"
                    value={watch("sentence_for_not_target")}
                    className="resize-none w-[800px] h-[300px] px-5 py-1 border rounded-lg text-sm border-lightGreen-200"
                  />
                </div>
                <div className="pt-10	pb-20 text-center">
                  <ReturnStressCheckManagementButton
                    stressCheckId={stressCheckId}
                  />
                  <ConfirmationButton>更新</ConfirmationButton>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
