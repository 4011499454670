import React, { ReactNode } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Sidebar } from "./sidebar/Sidebar";

export const Common = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <div>
          <Header />
          <div className="bg-white relative rounded-lg w-4/5 min-h-[600px] px-9">
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
