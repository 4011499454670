import React, { memo } from "react";

import { CompanyAnalyticsForm } from "../../templates/stressCheckMaintenance/CompanyAnalyticsForm";

export const CompanyAnalytics = memo(() => {
  return (
    <>
      <CompanyAnalyticsForm />
    </>
  );
});
