import React from "react";

export const ConfirmationButton = (props: { children: string }) => {
  const { children } = props;

  return (
    <button
      type="submit"
      className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
    >
      {children}
    </button>
  );
};
