import { useCallback, useEffect, useState } from "react";

import { getOrganizationHistoryList } from "../../api/apiHistory";
import { OrganizationHistoryListResponseType } from "../../types/api/organizationHistory";

export const useFetchOrganizationHistoryList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [historyList, setHistoryList] = useState<
    Array<OrganizationHistoryListResponseType>
  >([]);

  const fetchOrganizationHistoryList = useCallback(async () => {
    const res = await getOrganizationHistoryList();

    if (res.status !== 200) {
      setIsLoading(false);
      return alert("過去組織図一覧のデータ取得に失敗しました");
    }
    setIsLoading(false);

    setHistoryList(res.data.data.histories);
  }, []);

  useEffect(() => {
    fetchOrganizationHistoryList();
  }, []);

  return {
    isLoading,
    historyList,
  };
};
