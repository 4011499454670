import React, { FC, memo } from "react";

type Props = {
  name: string;
  startDate: string;
  endDate: string;
  status: string;
  stressCheckId: number;
  onClickHandler: (props: number) => void;
};

export const StressCheckManagementListTable: FC<Props> = memo((props) => {
  const { name, startDate, endDate, status, stressCheckId, onClickHandler } =
    props;

  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="text-lightGreen-200 overflow-ellipsis truncate max-w-0">
          {name}
        </td>
        <td className="overflow-ellipsis truncate max-w-0">
          <p />
          {status === "0" ? "未実施" : `${startDate}〜${endDate}`}
          <p />
        </td>
        <td className="mt-6 flex justify-end">
          <button
            onClick={() => onClickHandler(stressCheckId)}
            className="h-10 w-20 text-sm text-white rounded-lg focus:outline-none border-lightGreen-200 border"
          >
            <p className="text-lg font-w6 leading-relaxed text-lightGreen-200">
              詳細
            </p>
          </button>
        </td>
      </tr>
    </tbody>
  );
});
