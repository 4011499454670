import React, { FC, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import StressCheck from "../../../images/stress-check.png";
import { StressCheckListTable } from "../../atoms/stressChecks/StressCheckListTable";
import { StressCheckListResponseType } from "../../../types/api/stressCheck";
import { ModalDisplay } from "../../atoms/commons/ModalDisplay";
import { ModalAgreeForm } from "../../atoms/stressChecks/ModalAgreeForm";
import { useStressCheckDisclosureResponse } from "../../../hooks/stressChecks/useStressCheckDisclosureResponse";

type Props = { stressCheckList: StressCheckListResponseType[] };

export const StressCheckList: FC<Props> = (props) => {
  const { stressCheckList } = props;
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);
  const [showModalFlag, setShowModalFlag] = useState<boolean>(false);
  const [stressCheckFrameId, setStressCheckFrameId] = useState<number>(0);
  const {
    disclosureResponse,
    fetchStressCheckDisclosureResponse,
    updateStressCheckDisclosureResponse,
  } = useStressCheckDisclosureResponse();

  const handlePageChange = (data: { selected: number }) => {
    // eslint-disable-next-line
    let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  };

  const onChangeModalFlag = () => {
    setShowModalFlag(!showModalFlag);
  };

  const onChangeOpenModal = async (stress_check_frame_id: number) => {
    await fetchStressCheckDisclosureResponse(stress_check_frame_id);
    setStressCheckFrameId(stress_check_frame_id);
    onChangeModalFlag();
  };

  const navigate = useNavigate();

  const onClickHandler = (stressCheckId: number, status: string) => {
    switch (status) {
      case "0":
        navigate(`/stress-checks/${stressCheckId}/answer`, {
          state: stressCheckId,
        });
        break;
      case "1":
        alert("既に受検済みです");
        navigate("/stress-checks");
        break;
      case "2":
        navigate(`/stress-checks/${stressCheckId}/result`, {
          state: stressCheckId,
        });
        break;
    }
  };

  return (
    <>
      {stressCheckList.length !== 0 ? (
        <>
          <Title src={StressCheck}>ストレスチェック一覧</Title>
          <div className="mt-5 w-full">
            <table className="py-5 text-md mx-auto w-full table-fixed">
              <thead>
                <tr className="border-b leading-none text-lightGreen-200">
                  <th className="text-left py-5 w-[40%]">テスト</th>
                  <th className="text-left py-5 w-[30%]">実施日</th>
                  <th className="py-5 w-[294px]"></th>
                </tr>
              </thead>
              {stressCheckList
                .slice(offset, offset + perPage)
                .map((StressCheckList) => (
                  <StressCheckListTable
                    key={StressCheckList.stress_check_frame_id}
                    name={StressCheckList.stress_check_frame_name}
                    stress_checkId={StressCheckList.stress_check_frame_id}
                    executed_date={StressCheckList.examination_date}
                    status={StressCheckList.status}
                    onClickHandler={onClickHandler}
                    onChangeModalFlag={() =>
                      onChangeOpenModal(StressCheckList.stress_check_frame_id)
                    }
                    agree_btn_status={StressCheckList.agree_btn_available}
                  />
                ))}
            </table>
          </div>
          <div className="h-40 w-full mt-11 mb-10 flex justify-center">
            <p className="mr-auto text-lg leading-relaxed text-gray-700">
              1 - {offset + perPage} 件 / 全 {stressCheckList.length}件中
            </p>
            <div className="flex mr-80">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(stressCheckList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
                marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
                pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
                onPageChange={handlePageChange} // クリック時のfunction
                containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
                activeClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // アクティブなページのliに着くクラス名
                previousClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「<」のliに着けるクラス名
                nextClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「>」のliに着けるクラス名
                disabledClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 使用不可の「<,>」に着くクラス名
                breakClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「...」に着くクラス名
                pageClassName={
                  "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                }
                // aタグのクラス名
                pageLinkClassName={"block w-10 h-full text-center no-underline"}
                previousLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
                nextLinkClassName={"block w-10 h-full text-center no-underline"}
                disabledLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
                breakLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="border-b pb-10">
            <Title src={StressCheck}>ストレスチェック一覧</Title>
          </div>
          <div className="flex flex-col justify-center items-center w-full h-full pt-40 static">
            <span className="font-bold text-3xl text-lightGreen-200">
              データが存在しません
            </span>
          </div>
        </>
      )}

      <ModalDisplay showModal={showModalFlag}>
        <ModalAgreeForm
          onChangeModalFlag={onChangeModalFlag}
          disclosureResponse={disclosureResponse}
          stressCheckFrameId={stressCheckFrameId}
          updateStressCheckDisclosureResponse={
            updateStressCheckDisclosureResponse
          }
        />
      </ModalDisplay>
    </>
  );
};
