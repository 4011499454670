import { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  getStressCheckSubjectEmployeeList,
  postStressCheckSubjectEmployees,
} from "../../api/apiStressCheckManagement";
import {
  StressCheckSubjectEmployeeListCandidateResponseType,
  StressCheckSubjectEmployeeListResponseType,
  StressCheckSubjectEmployeeResponseType,
  CheckEmployeeType,
} from "../../types/api/stressCheckManagement";
import { usePaginate } from "../../hooks/usePaginate";

// SC対象者保存
export const useSaveStressCheckSubjectEmployees = () => {
  const navigate = useNavigate();
  const saveStressCheckManagement = useCallback(
    async (stressCheckId, employeesResponseData) => {
      const res = await postStressCheckSubjectEmployees(
        stressCheckId,
        employeesResponseData
      );
      if (res.status !== 201) {
        switch (res.data.message) {
          case "事業場未設定の従業員が存在します":
            alert("事業場未設定の社員がいるため、登録できませんでした");
            return navigate(`/stress-checks-management/${stressCheckId}`);
          case "対象のSC状態が適切でありません。":
            alert("ストレスチェックの状態が不適切です");
            return navigate(`/stress-checks-management/${stressCheckId}`);
          default:
            alert("ストレスチェック対象者の登録に失敗しました");
            return navigate(`/stress-checks-management/${stressCheckId}`);
        }
      }

      alert("ストレスチェック対象者の登録に成功しました");

      navigate(`/stress-checks-management/${stressCheckId}`);
    },
    []
  );

  return {
    saveStressCheckManagement,
  };
};

export const useStressCheckSelection = () => {
  // params取得
  const params = useParams();
  const stressCheckId = params.id;
  // path取得
  const navigate = useNavigate();
  // ローディング定義
  const [isLoading, setIsLoading] = useState(true);
  // 従業員を初期値を設定
  const [stressCheckSubjectEmployeeList, setStressCheckSubjectEmployeeList] =
    useState<StressCheckSubjectEmployeeListResponseType>({
      title: "",
      departments: [
        {
          department_id: 0,
          name: "",
        },
      ],
      offices: [
        {
          office_id: 0,
          name: "",
        },
      ],
      candidates: [
        {
          employee_id: 0,
          name: "",
          office_id: 0,
          office_name: "",
          selected: true,
          not_in_organization: false,
        },
      ],
    });
  // ページネーションを変数に代入
  const paginateObj = usePaginate();
  // useForm使用項目と初期値設定
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<StressCheckSubjectEmployeeResponseType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      employee_id: 0,
      office_id: "",
      department_id: "",
      position: "",
      occupation: "",
      sex: "",
      option1: "",
      option2: "",
      option3: "",
    },
  });
  // 従業員部署一覧を変数に代入
  const departmentlists = stressCheckSubjectEmployeeList.departments;
  // 従業員事業所を変数に代入
  const oficelists = stressCheckSubjectEmployeeList.offices;
  // 従業員保存を定義
  const { saveStressCheckManagement } = useSaveStressCheckSubjectEmployees();
  // チェックした従業員情報を管理
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  // チェックした従業員をセット
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(e.target.value, 10);
    if (checkedValues.includes(num)) {
      const setDate = checkedValues.filter(
        (checkedValue: number) => checkedValue !== num
      );
      setCheckedValues(setDate);
    } else {
      setCheckedValues([...checkedValues, num]);
    }
  };
  // 全てのチェックボックスを管理
  const [allCheckBox, setAllCheckBox] = useState<boolean>(false);
  // 全てにチェック
  const setCheckedEmployeeList = () => {
    if (!allCheckBox) {
      const allIdArry = stressCheckSubjectEmployeeList.candidates.map(
        (employee) => employee.employee_id
      );
      setCheckedValues(allIdArry);
      setAllCheckBox(true);
    } else {
      setCheckedValues([]);
      setAllCheckBox(false);
    }
  };

  // SC選定従業員の保存
  const onSubmit: SubmitHandler<
    StressCheckSubjectEmployeeResponseType
  > = async (data) => {
    await stressCheckSubjectEmployeeList.candidates;
    if (!window.confirm("本当に登録しても宜しいですか？")) {
      return;
    }
    if (!checkedValues.length) {
      return alert("1つ以上選択してください");
    }

    const checkEmployee = await stressCheckSubjectEmployeeList.candidates
      .map((employees: CheckEmployeeType) => {
        if (!checkedValues.includes(employees.employee_id)) {
          return;
        }
        return { employees };
      })
      .filter((employees) => employees);
    const dataArry = await checkEmployee.map((a) => {
      if (
        a?.employees.not_in_organization == true &&
        a.employees.employee_id === data.employee_id
      ) {
        return {
          employee_id: data.employee_id,
          not_in_organization: a.employees.not_in_organization,
          office_id: data.office_id,
          department_id: data.department_id,
          position: data.position,
          occupation: data.occupation,
          sex: data.sex,
          option1: data.option1,
          option2: data.option2,
          option3: data.option3,
        };
      } else {
        return {
          employee_id: a?.employees.employee_id,
          not_in_organization: a?.employees.not_in_organization,
          office_id: "",
          department_id: "",
          position: "",
          occupation: "",
          sex: "",
          option1: "",
          option2: "",
          option3: "",
        };
      }
    });
    const employeesResponseData = {
      employees: [...dataArry],
    };
    saveStressCheckManagement(stressCheckId, employeesResponseData);
  };
  // 従業員一覧を取得
  const fetchStressCheckSubjectEmployeeList = useCallback(async () => {
    const res = await getStressCheckSubjectEmployeeList(stressCheckId);
    if (res.status !== 200) {
      setIsLoading(false);
      alert("ストレスチェック対象者の一覧データ取得に失敗しました");
      return navigate(`/stress-checks-management/${stressCheckId}`);
    }
    setIsLoading(false);
    /********************************************************
     * TODO: これは別の処理なので切り出したい。
     *******************************************************/
    const candidateEmployeeList = res.data.data.candidates
      .filter(
        (
          candidateEmployee: StressCheckSubjectEmployeeListCandidateResponseType
        ) => candidateEmployee.selected == true
      )
      .map(
        (
          candidateEmployee: StressCheckSubjectEmployeeListCandidateResponseType
        ) => candidateEmployee.employee_id
      );
    setCheckedValues(candidateEmployeeList);
    res.data.data.candidates.map((te: any) => {
      setValue(`employee_id`, te.employee_id);
    });
    setStressCheckSubjectEmployeeList(res.data.data);
  }, []);
  //
  useEffect(() => {
    fetchStressCheckSubjectEmployeeList();
  }, []);

  return {
    isLoading,
    stressCheckSubjectEmployeeList,
    fetchStressCheckSubjectEmployeeList,
    checkedValues,
    setCheckedValues,
    paginateObj,
    register,
    watch,
    handleSubmit,
    errors,
    setValue,
    departmentlists,
    oficelists,
    setCheckedEmployeeList,
    handleCheckBoxChange,
    onSubmit,
  };
};
