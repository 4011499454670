import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const ErrorPage = () => {
  const location = useLocation();

  const errorPageDescription = (status: string) => {
    switch (status) {
      case "404":
        return "申し訳ございませんが、お探しのページが見つかりませんでした。";
      case "ConnectionRefused":
        return "通信に失敗しました。しばらく経ってからもう一度お試しください。";
      case "500":
        return "エラーが発生しました。しばらく経ってからもう一度お試しください。";
      case "503":
        return "エラーが発生しました。しばらく経ってからもう一度お試しください。";
      default:
        return "申し訳ございませんが、お探しのページが見つかりませんでした。";
    }
  };

  return (
    <>
      <div className="fixed bg-lightGreen-100 h-full w-full flex flex-col justify-center items-center">
        <h1 className="font-bold text-4xl">
          {errorPageDescription(
            (location.state ? location.state : "") as string
          )}
        </h1>
        <div className="mt-[50px]">
          <Link to="/">
            <span className="font-bold text-3xl text-lightGreen-200">
              TOPに戻る
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};
