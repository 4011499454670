import React, { memo, useState } from "react";

import { useFetchDepartmentList } from "../../../hooks/department/useFetchDepartmentList";
import { useShowModalFlagHandler } from "../../../hooks/department/useShowModalFlagHandler";
import { useFetchDepartmentAbolition } from "../../../hooks/department/useFetchDepartmentAbolition";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { DepartmentList } from "../../templates/departments/DepartmentList";
import { useSaveDepartment } from "../../../hooks/department/useSaveDepartment";

export const DepartmentLists = memo(() => {
  const { isLoading, departmentList, fetchOrganizationList } =
    useFetchDepartmentList();
  const { showResumeModalFlag, onChangeResumeModalFlag } =
    useShowModalFlagHandler();
  const { saveDepartmentResume } = useSaveDepartment();
  const { useDepartmentAbolition } = useFetchDepartmentAbolition();
  const submitCheckAlert = async (id: number) => {
    if (
      !window.confirm(
        `部署を廃止しますが、よろしいでしょうか？\n※ 配下の部署も同様に廃止されます`
      )
    ) {
      return;
    }
    await useDepartmentAbolition(id);
    fetchOrganizationList();
  };
  const [departmentId, setDepartmentId] = useState<number>(0);
  const [departmentname, setDepartmentname] = useState<string>("");

  const onClickResumeDepartment = (id: number, name: string) => {
    setDepartmentId(id);
    setDepartmentname(name);
    onChangeResumeModalFlag();
  };

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <DepartmentList
          departmentList={departmentList}
          showResumeModalFlag={showResumeModalFlag}
          onChangeResumeModalFlag={onChangeResumeModalFlag}
          fetchOrganizationList={fetchOrganizationList}
          saveDepartmentResume={saveDepartmentResume}
          submitCheckAlert={submitCheckAlert}
          onClickResumeDepartment={onClickResumeDepartment}
          departmentId={departmentId}
          departmentName={departmentname}
        />
      )}
    </>
  );
});
