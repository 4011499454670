import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

export type LoadingCheckContextType = {
  setLoading: Dispatch<SetStateAction<true | false>>;
  loading: true | false;
};

export const LoadingCheckContext = createContext<LoadingCheckContextType>(
  {} as LoadingCheckContextType
);

export const LoadingCheckProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [loading, setLoading] = useState(false);

  const values = {
    setLoading,
    loading,
  };

  return (
    <LoadingCheckContext.Provider value={values}>
      {children}
    </LoadingCheckContext.Provider>
  );
};
