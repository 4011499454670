import React, { memo } from "react";
import { useFetchStressCheckManagementList } from "../../../hooks/stressCheckManagements/useFetchStressCheckManagementList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

import { StressChecksMaintenanceList } from "../../templates/stressCheckMaintenance/StressChecksMaintenanceList";

export const StressCheckMaintenance = memo(() => {
  const { isLoading, onClickHandler, stressCheckManagementList } =
    useFetchStressCheckManagementList();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <StressChecksMaintenanceList
          stressCheckManagementList={stressCheckManagementList}
          onClickHandler={onClickHandler}
        />
      )}
    </>
  );
});
