import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  children: string;
  path: string;
  buttonFlag: boolean;
};

export const RollLinkButton: FC<Props> = (props) => {
  const { children, path, buttonFlag } = props;
  return (
    <>
      {buttonFlag ? (
        <>
          <button
            className="border rounded-lg border-lightGray-200 text-center py-[30px] w-full inline-block"
            disabled={true}
          >
            <span className="font-bold text-base text-lightGray-200">
              {children}
            </span>
          </button>
        </>
      ) : (
        <Link
          to={path}
          className="border rounded-lg border-lightGreen-200 text-center py-[30px] w-full inline-block"
        >
          <span className="font-bold text-base text-lightGreen-200">
            {children}
          </span>
        </Link>
      )}
    </>
  );
};
