import React from "react";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import HomeIcon from "../../images/home.png";

const routes = [
  { path: "/", breadcrumb: null },
  { path: "/employees", breadcrumb: "社員紹介" },
  { path: "/employees-management", breadcrumb: "社員管理" },
  { path: "/employees-management/create", breadcrumb: "社員登録" },
  { path: "/employees-management/:id", breadcrumb: null },
  { path: "/employees-management/detail/:id", breadcrumb: "社員詳細" },
  { path: "/employees/detail/:employee_id", breadcrumb: "自己紹介" },
  { path: "/employees/detail/:employee_id/edit", breadcrumb: "自己紹介編集" },
  { path: "/employees/:employee_id", breadcrumb: null },
  { path: "/stress-checks", breadcrumb: "ストレスチェック一覧" },
  {
    path: "/stress-checks/:id/answer",
    breadcrumb: "回答",
  },
  {
    path: "/stress-checks/:stressCheckId/result",
    breadcrumb: "結果",
  },
  {
    path: "/stress-checks/:stressCheckId",
    breadcrumb: null,
  },
  { path: "/stress-checks-management", breadcrumb: "ストレスチェック管理一覧" },
  {
    path: "/stress-checks-management/:id",
    breadcrumb: "ストレスチェック管理",
  },
  {
    path: "/stress-checks-management/:id/subjects",
    breadcrumb: "ストレスチェック対象者選定",
  },
  {
    path: "/stress-checks-management/:id/interviews",
    breadcrumb: "面談対象者選定",
  },
  {
    path: "/stress-checks-management/:id/employees",
    breadcrumb: "ストレスチェック対象者一覧",
  },
  {
    path: "/stress-checks-management/:stressCheckId/sendmail",
    breadcrumb: "メール送信",
  },
  {
    path: "/stress-checks-management/:stressCheckId/sendmail/:mailType",
    breadcrumb: null,
  },
  {
    path: "/stress-checks-management/:stressCheckId/sendmail/confirm",
    breadcrumb: "確認",
  },
  {
    path: "/stress-checks-management/:stressCheckId/interview-guidance",
    breadcrumb: null,
  },
  {
    path: "/stress-checks-management/:stressCheckId/interview-guidance/edit",
    breadcrumb: "面談指導要否文編集",
  },
  {
    path: "/stress-checks-management/:id/company-analytics",
    breadcrumb: "全社分析レポート",
  },
  {
    path: "/stress-checks-management/:id/group-analytics",
    breadcrumb: "集団分析レポート",
  },
  { path: "/offices", breadcrumb: "事業場一覧" },
  { path: "/offices/create", breadcrumb: "事業場登録" },
  { path: "/offices/detail", breadcrumb: null },
  { path: "/offices/detail/:id", breadcrumb: "事業場詳細" },
  { path: "/doctor-collaboration", breadcrumb: "産業医連携" },
  { path: "/contacts", breadcrumb: "お問い合わせ先設定" },
  { path: "/departments", breadcrumb: "組織図" },
  { path: "/departments/list", breadcrumb: "部署一覧" },
  { path: "/departments/histories", breadcrumb: "過去組織図一覧" },
  {
    path: "/departments/histories/:history_frame_id/:department_id/pastemployees",
    breadcrumb: "社員紹介",
  },
  {
    path: "/departments/histories/:history_frame_id/:department_id",
    breadcrumb: null,
  },
  { path: "/settings", breadcrumb: "設定" },
  { path: "/settings/employee-roll", breadcrumb: null },
  { path: "/settings/employee-roll/update", breadcrumb: "権限更新" },
  { path: "/departments/histories/:id", breadcrumb: "過去組織図詳細" },
  { path: "/departments/transition", breadcrumb: "組織図変遷" },
  { path: "/employment-retirement-tasks", breadcrumb: "入退職処理" },
  { path: "/employment-retirement-tasks/tasklist", breadcrumb: "タスク一覧" },
];

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <React.Fragment>
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <div key={i} className="flex justify-center items-center">
          {i === 0 ? (
            <img
              className="inline-block mr-3.5"
              src={HomeIcon}
              alt="home.png"
              width={28}
              height={28}
            />
          ) : null}
          <NavLink to={match.pathname} className=" hover:underline">
            <span className="text-lightGreen-300">{breadcrumb}</span>
          </NavLink>
          {breadcrumbs.length - 1 === i ? null : (
            <span className="text-lightGreen-300 px-3">&gt;</span>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};
