import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  DetailOfficeResponseType,
  OfficeListResponseType,
  OfficeRequestType,
} from "../types/api/office";

// 事業場一覧データ取得
export const getOfficeList = () => {
  return api
    .get<OfficeListResponseType>("/merchant/maintenance/offices")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 事業場データ送信
export const postOffice = (data: OfficeRequestType) => {
  return api
    .post("/merchant/maintenance/office", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 事業場データ取得
export const getDetailOffice = (officeId: string | undefined) => {
  return api
    .get<DetailOfficeResponseType>(`/merchant/maintenance/office/${officeId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 事業場更新データ送信
export const putDetailOffice = (
  officeId: string | undefined,
  data: OfficeRequestType
) => {
  return api
    .put(`/merchant/maintenance/office/${officeId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
