import React, { FC, memo } from "react";

type Props = {
  onClickRegisterButton: () => void;
};

export const NotInterviewTarget: FC<Props> = memo((props) => {
  const { onClickRegisterButton } = props;

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="mt-[60px] px-[30px]">
          <span className="font-bold text-3xl text-lightGreen-200">
            ストレスチェックの結果として、面談の候補者は存在しませんでした
          </span>
        </div>
        <div className="mt-[10px] mb-[50px] px-[30px]">
          <span className="font-bold text-base text-red-600">
            ※
            面談対象者メールの送信とストレスチェックの個人結果画面の面談指導要否文を表示させるために、確認ボタンを押してください。
          </span>
        </div>
        <button
          type="button"
          onClick={onClickRegisterButton}
          className="border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200 py-5 px-28"
        >
          <span>確認</span>
        </button>
      </div>
    </>
  );
});
