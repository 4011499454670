import { useCallback, useState, useEffect } from "react";

import { getTaskList } from "../../api/apiEmployeeTask";
import { TaskListResponseType } from "../../types/api/EmployeeTask";

export const useTaskList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isListTable, setIsListTable] = useState(true);
  const screenSwitchHandle = () => {
    isListTable ? setIsListTable(false) : setIsListTable(true);
  };
  const [taskList, setTaskList] = useState<TaskListResponseType>({
    employment: {
      tasks: [
        {
          id: 0,
          name: "",
          display: false,
        },
      ],
    },
    retirement: {
      tasks: [
        {
          id: 0,
          name: "",
          display: false,
        },
      ],
    },
  });

  const fetchTaskList = useCallback(async () => {
    const res = await getTaskList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("タスク一覧取得に失敗しました");
    }
    setTaskList(res.data.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchTaskList();
  }, []);

  return {
    isListTable,
    screenSwitchHandle,
    isLoading,
    taskList,
  };
};
