import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getIntervieweeCandidateList,
  postInterviewTargets,
} from "../../api/apiStressCheckManagement";
import {
  IntervieweeCandidateListResponseType,
  IntervieweeTargetListRequestType,
} from "../../types/api/stressCheckManagement";

export const useFetchStressCheckCandidateList = (
  stressCheckId: string | undefined
) => {
  const navigate = useNavigate();

  const [intervieweeCandidateList, setIntervieweeCandidateList] =
    useState<IntervieweeCandidateListResponseType>({
      title: "",
      interviewee_candidates: [],
    });

  const fetchStressCheckSubjectEmployeeList = useCallback(
    async (stressCheckId) => {
      const res = await getIntervieweeCandidateList(stressCheckId);

      if (res.status !== 200) {
        alert("面談対象者一覧データの取得に失敗しました");
        return navigate(`/stress-checks-management/${stressCheckId}`);
      }

      setIntervieweeCandidateList(res.data.data);
    },
    []
  );

  useEffect(() => {
    fetchStressCheckSubjectEmployeeList(stressCheckId);
  }, []);

  return {
    intervieweeCandidateList,
  };
};

export const useSaveStressCheckInterviewTargets = () => {
  const navigate = useNavigate();

  const saveStressCheckInterviewTargets = useCallback(
    async (
      stressCheckId: string | undefined,
      data: IntervieweeTargetListRequestType
    ) => {
      const res = await postInterviewTargets(stressCheckId, data);

      if (res.status !== 201) {
        switch (res.data.code) {
          case "INTERVIEW_SENTENCE_UNDEFINED":
            alert("面談指導要否文が未設定です");
            break;
          case "SCF_CONDITION_ERROR":
            alert("ストレスチェックの状態が不適切です");
            break;
          default:
            alert("面談対象者の登録に失敗しました");
            break;
        }
        return navigate(`/stress-checks-management/${stressCheckId}`);
      }

      alert("面談対象者の登録に成功しました");

      navigate(`/stress-checks-management/${stressCheckId}`);
    },
    []
  );

  return {
    saveStressCheckInterviewTargets,
  };
};
