import { useCallback, useEffect, useState } from "react";

import { getContact } from "../../api/apiContact";

export const useFetchContact = () => {
  const [contactContent, setContactContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchContact = useCallback(async () => {
    const res = await getContact();

    if (res.status !== 200) {
      setIsLoading(false);
      return alert("お問い合わせ先のデータ取得に失敗しました");
    }
    setIsLoading(false);
    setContactContent(res.data.data.content);
  }, []);

  const changeInputValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    switch (e.target.name) {
      case "content":
        return setContactContent(e.target.value);
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);

  return {
    isLoading,
    contactContent,
    changeInputValue,
    setContactContent,
  };
};
