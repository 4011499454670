import React, { memo } from "react";

import { useFetchStressCheckManagementEmployeeList } from "../../../hooks/stressCheckManagements/useFetchStressCheckEmployees";
import { usePaginate } from "../../../hooks/usePaginate";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { AdminStressCheckTargetEmployeesList } from "../../templates/stressCheckMaintenance/AdminStressCheckTargetEmployeesList";
import { ReaderStressCheckTargetEmployeesList } from "../../templates/stressCheckMaintenance/ReaderStressCheckTargetEmployeesList";

export const StressCheckTargetEmployees = memo(() => {
  const {
    isLoading,
    stressCheckManagementEmployeeList,
    userRoll,
    titleObj,
    onClickHandler,
  } = useFetchStressCheckManagementEmployeeList();
  const paginateObj = usePaginate();

  if (isLoading) {
    return <LoadingTemplate />;
  }

  return (
    <>
      {userRoll == "01" ? (
        <AdminStressCheckTargetEmployeesList
          stressCheckManagementEmployeeList={stressCheckManagementEmployeeList}
          paginateObj={paginateObj}
          titleObj={titleObj}
          onClickHandler={onClickHandler}
        />
      ) : (
        <ReaderStressCheckTargetEmployeesList
          stressCheckManagementEmployeeList={stressCheckManagementEmployeeList}
          paginateObj={paginateObj}
          titleObj={titleObj}
        />
      )}
    </>
  );
});
