import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGroupAnalytics } from "../../../hooks/stressCheckManagements/useGroupAnalytics";
import { GroupAnalyticsForm } from "../../templates/stressCheckMaintenance/GroupAnalyticsForm";

export const GroupAnalytics = memo(() => {
  const params = useParams();
  const { groupAnalyticsList, fetchGroupAnalyticsReport } = useGroupAnalytics();
  const [tabName, setTabName] = useState<string>("事業場");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tabSwitchHandle = (tab: string) => {
    setTabName(tab);
  };

  useEffect(() => {
    fetchGroupAnalyticsReport(params.id, tabName, setIsLoading);
  }, [tabName]);

  return (
    <>
      <GroupAnalyticsForm
        groupAnalyticsList={groupAnalyticsList}
        selectedTabName={tabName}
        isLoading={isLoading}
        tabSwitchHandle={tabSwitchHandle}
      />
    </>
  );
});
