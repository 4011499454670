import { useCallback } from "react";

import { putDepartment } from "../../api/apiDepartment";

export const useUpdateDepartment = () => {
  // 部署を更新
  const updateDepartment = useCallback(async (departmentId, department) => {
    const parentId = String(department.parent_id);
    const data = { name: department.name, parent_id: parentId };

    const res = await putDepartment(departmentId, data);

    if (res.status !== 200) {
      return alert("部署の変更に失敗しました");
    }
    return alert("部署の変更に成功しました");
  }, []);

  return {
    updateDepartment,
  };
};
