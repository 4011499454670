import { useCallback, useEffect, useState } from "react";

import { getDepartments, getDepartmentTree } from "../../api/apiDepartment";
import { DepartmentList, OrganizationTrees } from "../../types/api/department";

// 組織図と部署リストを取得
export const useFetchDepartmentTree = () => {
  const [departmentTree, setDepartmentTree] = useState<OrganizationTrees>([]);
  const [departmentList, setDepartmentList] = useState<DepartmentList>([]);

  const [isLoading, setIsLoading] = useState(true);

  const fetchDepartmentTree = useCallback(async () => {
    const res = await getDepartmentTree();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("組織図の取得に失敗しました");
    }
    setIsLoading(false);
    setDepartmentTree(res.data.data.organization_trees);
  }, []);

  const fetchDepartmentList = useCallback(async () => {
    const res = await getDepartments();
    if (res.status !== 200) {
      return alert("部署リストの取得に失敗しました");
    }
    setDepartmentList(res.data.data.departments);
  }, []);

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  return {
    fetchDepartmentTree,
    departmentTree,
    fetchDepartmentList,
    departmentList,
    isLoading,
  };
};
