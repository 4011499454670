import { useCallback, useEffect, useState } from "react";

import { getOfficeList } from "../../api/apiOffice";
import { OfficeListResponseType } from "../../types/api/office";

export const useFetchOfficeList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [officeList, setOfficeList] = useState<Array<OfficeListResponseType>>(
    []
  );

  const fetchOfficeList = useCallback(async () => {
    const res = await getOfficeList();

    if (res.status !== 200) {
      setIsLoading(false);
      return alert("事業場一覧のデータ取得に失敗しました");
    }
    setIsLoading(false);

    setOfficeList(res.data.data.offices);
  }, []);

  useEffect(() => {
    fetchOfficeList();
  }, []);

  return {
    isLoading,
    officeList,
  };
};
