import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";

import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { Sidebar } from "../../templates/sidebar/Sidebar";
import { EmployeesProfileEditForm } from "../../templates/employees/EmployeeProfileEditForm";
import { useEmployee } from "../../../hooks/employees/useEmployee";
import { EmployeeProfileEditFromType } from "../../../types/api/employee";

export const EmployeesProfileEdit = memo(() => {
  const params = useParams();
  const employeeId = params.employee_id;
  const { updateEmployeeProfile } = useEmployee();
  const [updateRequestData, setUpdateRequestData] =
    useState<EmployeeProfileEditFromType>({
      business_last_name: "",
      business_first_name_kana: "",
      business_last_name_kana: "",
      business_first_name: "",
      profile_image: "",
      word: "",
      self_introduction: "",
      interest_content_1: "",
      interest_image_1: "",
      interest_content_2: "",
      interest_image_2: "",
      card_color: "",
      private_email_address: "",
      private_phone_number: "",
      extention_number: "",
    });

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const updateEmployeeEvent = () => {
    updateEmployeeProfile(updateRequestData, employeeId);
  };
  const [colorSwitch, setColorSwitch] = useState<string>("default");
  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9">
          <EmployeesProfileEditForm
            setIsPreviewVisible={setIsPreviewVisible}
            isPreviewVisible={isPreviewVisible}
            updateEmployeeProfileEvent={updateEmployeeEvent}
            setProfileUpdateRequestData={setUpdateRequestData}
            employeeId={employeeId}
            updateEmployeeProfile={updateEmployeeProfile}
            setColorSwitch={setColorSwitch}
            colorSwitch={colorSwitch}
          />
        </div>
        <Footer />
      </div>
    </>
  );
});
