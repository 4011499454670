import React, { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";
import Choice from "../../../images/choice.png";
import { EmployeeEditBirthdayForm } from "../../atoms/employeeMaintenance/EmployeeMaintenanceEditBirthdayForm";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { ReturnButton } from "../../atoms/commons/ReturnButton";
import { RegistrationButton } from "../../atoms/employeeMaintenance/RegistrationButton";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { FormLabel } from "../../atoms/commons/FormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";
import {
  EmployeeMaintenanceFormType,
  EmployeeMaintenanceRequestType,
} from "../../../types/api/employeeMaintenance";
import EmployeeManagementPlusBtn from "../../../images/employee-plus-btn.png";
import { OfficeListResponseType } from "../../../types/api/office";
import { DepartmentList } from "../../../types/api/department";

type EditProps = {
  updateEmployeeEvent: () => void;
  setUpdateRequestData: React.Dispatch<
    React.SetStateAction<EmployeeMaintenanceRequestType>
  >;
  employeeId: string | undefined;
  setIsPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviewVisible: boolean;
  fetchDetailEmployee: (employeeId: string | undefined) => Promise<void>;
  detailEmployee: EmployeeMaintenanceFormType;
  officeList: OfficeListResponseType[];
  departmentList: DepartmentList;
  loading: boolean;
};
export const EmployeeMaintenanceEditForm = ({
  updateEmployeeEvent,
  setUpdateRequestData,
  employeeId,
  setIsPreviewVisible,
  isPreviewVisible,
  fetchDetailEmployee,
  detailEmployee,
  loading,
  officeList,
  departmentList,
}: EditProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
  } = useForm<EmployeeMaintenanceFormType>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      employeeNo: "",
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      sex: "",
      year: "",
      month: "",
      day: "",
      emailAddress: "",
      phoneNumber: "",
      postcode: "",
      address: "",
      officeId: "",
      departmentList: [
        {
          value: "",
          responsibilityType: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "departmentList",
    control,
  });

  useEffect(() => {
    fetchDetailEmployee(employeeId);
    if (detailEmployee.firstName !== "") {
      setValue("officeId", detailEmployee.officeId);
      setValue("employeeNo", detailEmployee.employeeNo);
      setValue("firstName", detailEmployee.firstName);
      setValue("firstNameKana", detailEmployee.firstNameKana);
      setValue("lastName", detailEmployee.lastName);
      setValue("lastNameKana", detailEmployee.lastNameKana);
      setValue("businessFirstName", detailEmployee.businessFirstName);
      setValue("businessLastName", detailEmployee.businessLastName);
      setValue("businessFirstNameKana", detailEmployee.businessFirstNameKana);
      setValue("businessLastNameKana", detailEmployee.businessLastNameKana);
      setValue("position", detailEmployee.position);
      setValue("occupation", detailEmployee.occupation);
      setValue("sex", String(detailEmployee.sex));
      setValue("emailAddress", detailEmployee.emailAddress);
      setValue("phoneNumber", detailEmployee.phoneNumber);
      setValue("postcode", detailEmployee.postcode);
      setValue("emailAddress", detailEmployee.emailAddress);
      setValue("address", detailEmployee.address);
      setValue("year", detailEmployee.year);
      setValue("month", detailEmployee.month);
      setValue("day", detailEmployee.day);
      setValue("departmentList", detailEmployee.departmentList);
    }
  }, [loading]);

  const onSubmit: SubmitHandler<EmployeeMaintenanceFormType> = (data) => {
    const departmentListData = data.departmentList.map((department, i) => {
      return {
        id: department.value,
        responsibility_type: department.responsibilityType ? "1" : "0",
        concurrent: i === 0 ? "0" : "1",
      };
    });
    setUpdateRequestData({
      office_id: data.officeId,
      employee_no: data.employeeNo,
      last_name: data.lastName,
      first_name: data.firstName,
      last_name_kana: data.lastNameKana,
      first_name_kana: data.firstNameKana,
      business_first_name: data.businessFirstName,
      business_last_name: data.businessLastName,
      business_first_name_kana: data.businessFirstNameKana,
      business_last_name_kana: data.businessLastNameKana,
      position: data.position,
      occupation: data.occupation,
      sex: data.sex,
      birthday: `${data.year}/${data.month}/${data.day}`,
      phone_number: data.phoneNumber,
      email_address: data.emailAddress,
      postcode: data.postcode,
      address: data.address,
      departments: departmentListData,
    });
    setIsPreviewVisible(true);
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  // 投稿フォームのinputタグのCSS
  const normalFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  // 投稿フォームのselectタグのCSS
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 確認フォームのinputタグのCSS
  const normalSelectFormStyle =
    "border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  // 確認フォームのselectタグのCSS
  const checkSelectFormStyle =
    "rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white";

  // 投稿フォームと確認フォームをinput/selectタグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const formSelectStyle = !isPreviewVisible
    ? normalSelectFormStyle
    : checkSelectFormStyle;

  const formDescription = !isPreviewVisible
    ? "社員情報を編集する場合は、内容を変更後に確認ボタンを押してください"
    : "入力した内容を確認して、編集ボタンを押してください";

  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={EmployeeManagement}>社員詳細</Title>
      </div>
      <div className="flex">
        <span className="my-11 text-sm">{formDescription}</span>
      </div>
      <div className="flex">
        <span className="text-2xl text-lightGreen-200 my-2 text-sm font-bold leading-relaxed">
          基本情報
        </span>
      </div>
      <div className="mt-5 mb-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 border-t items-center flex relative">
            <FormLabel
              title="社員番号"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("employeeNo")}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("employeeNo")}
            />
          </div>

          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="姓"
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("lastName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("lastName")}
                />
              </div>
              {errors.lastName?.types?.required && (
                <span className="text-red-600 absolute bottom-0 left-0">
                  この項目は必須です。
                </span>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="名"
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("firstName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("firstName")}
                />
              </div>
              {errors.firstName?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <RequiredFormLabel
                title="姓 (カナ)"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("lastNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("lastNameKana")}
              />
              {errors.lastNameKana?.types?.required && (
                <span className="text-red-600 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
              {errors.lastNameKana?.types?.pattern && (
                <span className="text-red-600 absolute bottom-0">
                  カタカナで入力してください。
                </span>
              )}
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title="名 (カナ)"
                className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("firstNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("firstNameKana")}
              />
              {errors.firstNameKana?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
              {errors.firstNameKana?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  カタカナで入力してください。
                </span>
              )}
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title={`ビジネスネーム\n姓`}
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
                />
                <input
                  {...register("businessLastName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("businessLastName")}
                />
              </div>
              {errors.businessLastName?.types?.required && (
                <span className="text-red-600 absolute bottom-0 left-0">
                  この項目は必須です
                </span>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title={`ビジネスネーム\n名`}
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
                />
                <input
                  {...register("businessFirstName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("businessFirstName")}
                />
              </div>
              {errors.businessFirstName?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
            </div>
          </div>
          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <RequiredFormLabel
                title={`ビジネスネーム\n姓 (カナ)`}
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 static whitespace-pre-wrap"
              />
              <input
                {...register("businessLastNameKana", {
                  required: true,
                  pattern: /^[ァ-ンヴーｧ-ﾝﾞﾟ\-]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("businessLastNameKana")}
              />
              {errors.businessLastNameKana?.types?.required && (
                <span className="text-red-600 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.businessLastNameKana?.types?.pattern && (
                <span className="text-red-600 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title={`ビジネスネーム\n名 (カナ)`}
                className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200 whitespace-pre-wrap"
              />
              <input
                {...register("businessFirstNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("businessFirstNameKana")}
              />
              {errors.businessFirstNameKana?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です
                </span>
              )}
              {errors.businessFirstNameKana?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  カタカナで入力してください
                </span>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex">
            <RequiredFormLabel
              title="性別"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div className={formSelectStyle}>
              <select
                className="appearance-none rounded-lg w-24 h-12 pl-4 focus:outline-none disabled:opacity-100"
                {...register("sex")}
                name={"sex"}
                disabled={isPreviewVisible}
              >
                <option value="0">男性</option>
                <option value="1">女性</option>
              </select>
              {!isPreviewVisible && (
                <img
                  className="w-3 h-2.5 absolute top-0 right-0 mr-4 mt-5"
                  src={Choice}
                ></img>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex">
            <RequiredFormLabel
              title="生年月日"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <EmployeeEditBirthdayForm
              watch={watch}
              register={register}
              formSelectStyle={formSelectStyle}
              isPreviewVisible={isPreviewVisible}
            />
          </div>

          <div className="py-5 border-y items-center flex relative">
            <RequiredFormLabel
              title="事業場"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div className={formSelectStyle}>
              <select
                className="appearance-none rounded-lg w-80 h-12 pl-4
                focus:outline-none disabled:text-black disabled:opacity-100"
                {...register("officeId", {
                  required: true,
                })}
                disabled={isPreviewVisible}
                value={watch("officeId")}
              >
                <option value="">事業場を選んでください</option>
                {officeList.map((office) => (
                  <option key={office.id} value={office.id}>
                    {office.name}
                  </option>
                ))}
              </select>
              {!isPreviewVisible && (
                <img
                  className="w-3 h-2.5 absolute top-0 right-0 mr-8 mt-5"
                  src={Choice}
                ></img>
              )}
            </div>
            {errors.officeId?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          {fields.map((field, index) => {
            return (
              <div key={field.id} className="py-5 items-center flex relative">
                <RequiredFormLabel
                  title={index === 0 ? "部署(メイン)" : "部署(兼任)"}
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <div className={formSelectStyle}>
                  <select
                    className="appearance-none rounded-lg w-80 h-12 pl-4
                    focus:outline-none disabled:text-black disabled:opacity-100"
                    {...register(`departmentList.${index}.value`, {
                      required: true,
                    })}
                    key={index}
                    name={`departmentList.${index}.value`}
                    defaultValue={field.value}
                    disabled={isPreviewVisible}
                  >
                    <option value="">部署を選んでください</option>
                    {departmentList.map((department) => {
                      return (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      );
                    })}
                  </select>
                  {!isPreviewVisible && (
                    <img
                      className="w-3 h-2.5 absolute top-0 right-0 mr-5 mt-5"
                      src={Choice}
                    ></img>
                  )}
                </div>
                <input
                  type="checkbox"
                  disabled={isPreviewVisible}
                  className="w-6 h-6 ml-10 default:ring-2 accent-white text-lightGreen-200 border rounded-lg"
                  {...register(`departmentList.${index}.responsibilityType`)}
                />
                <span className="w-30 ml-5 min-w-[100px]	text-sm font-semibold text-lightGreen-200">
                  責任者の場合はチェック
                </span>
                {index === 0 && !isPreviewVisible && (
                  <button
                    type="button"
                    onClick={() =>
                      append({
                        value: "",
                      })
                    }
                  >
                    <img
                      src={EmployeeManagementPlusBtn}
                      className="ml-10 h-10"
                    />
                  </button>
                )}
                {index > 0 && !isPreviewVisible && (
                  <button
                    type="button"
                    disabled={isPreviewVisible}
                    className="flex justify-center items-center w-20 ml-7 h-13 border rounded-lg text-sm text-lightGreen-200 hover:bg-lightGreen-200 hover:text-white"
                    onClick={() => remove(index)}
                  >
                    <span>削除</span>
                  </button>
                )}
                {errors.departmentList?.[index]?.value?.types?.required && (
                  <span className="text-red-600 absolute bottom-0 left-0">
                    この項目は必須です
                  </span>
                )}
              </div>
            );
          })}

          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <FormLabel
                  title="役職"
                  className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("position")}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("position")}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center">
                <FormLabel
                  title="職種"
                  className="ml-10 w-40 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("occupation")}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("occupation")}
                />
              </div>
            </div>
          </div>

          <div className="py-5 flex border-t relative">
            <div className="flex items-center">
              <FormLabel
                title="E-mail"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("emailAddress")}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("emailAddress")}
              />
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title="電話番号"
                className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200 ml-10"
              />
              <input
                {...register("phoneNumber", {
                  required: true,
                  pattern: /^0[0-9]{9,10}$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("phoneNumber")}
              />
              {errors.phoneNumber?.types?.required && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
              {errors.phoneNumber?.types?.pattern && (
                <span className="text-red-600 ml-10 absolute bottom-0">
                  ハイフンなしの10桁もしくは11桁の半角数字で入力してください。
                </span>
              )}
            </div>
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="郵便番号"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("postcode", {
                required: true,
                pattern: /^[0-9]{7}$/i,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("postcode")}
            />
            {errors.postcode?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
            {errors.postcode?.types?.pattern && (
              <span className="text-red-600 absolute bottom-0 left-0">
                ハイフンなしの7桁の半角数字で入力してください。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="住所"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("address", {
                required: true,
              })}
              className={formStyle + " min-w-[860px]"}
              disabled={isPreviewVisible}
              value={watch("address")}
            />
            {errors.address?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="pt-10	pb-20 text-center border-t">
            {!isPreviewVisible ? (
              <>
                <ReturnButton />
                <ConfirmationButton>確認</ConfirmationButton>
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={updateEmployeeEvent}>
                  編集
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
