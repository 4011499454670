import React, { FC, memo } from "react";

type Props = {
  stress_check_info: {
    targets: number;
    offices: number;
    examinees: number;
    highstress: number;
    examination_rate: number;
    highstress_rate: number;
  };
};

export const ComprehensiveEvaluationTable: FC<Props> = memo((props) => {
  const { stress_check_info } = props;

  return (
    <table className="text-md container">
      <thead>
        <tr>
          <th className="w-[150px]"></th>
          <th className="text-center w-[150px] font-bold text-lightGreen-200">
            数値
          </th>
          <th className="text-center w-[200px] font-bold text-lightGreen-200">
            割合
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="h-[45px] bg-lightGray-50">
          <td className="min-w-[200px] text-center">
            <span className="font-normal text-lg">受検対象者数</span>
          </td>
          <td className="min-w-[30px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.targets}
            </span>
          </td>
          <td className="min-w-[30px] text-center">
            <span className="font-normal text-lg">---</span>
          </td>
        </tr>
        <tr className="h-[45px]">
          <td className="min-w-[200px] text-center">
            <span className="font-normal text-lg">事業所数</span>
          </td>
          <td className="min-w-[30px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.offices}
            </span>
          </td>
          <td className="min-w-[50px] text-center">
            <span className="font-normal text-lg">---</span>
          </td>
        </tr>
        <tr className="h-[45px] bg-lightGray-50">
          <td className="min-w-[200px] text-center">
            <span className="font-normal text-lg">受検者数</span>
          </td>
          <td className="min-w-[30px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.examinees}
            </span>
          </td>
          <td className="min-w-[50px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.examination_rate}% (*1)
            </span>
          </td>
        </tr>
        <tr className="h-[45px]">
          <td className="min-w-[200px] text-center">
            <span className="font-normal text-lg">高ストレス者数</span>
          </td>
          <td className="min-w-[30px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.highstress}
            </span>
          </td>
          <td className="min-w-[50px] text-center">
            <span className="font-normal text-lg">
              {stress_check_info.highstress_rate}% (*2)
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
});
