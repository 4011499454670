import React, { memo } from "react";

import { AdminReaderListType } from "../../../types/api/settingsRoll";
import SettingsIcon from "../../../images/settings.png";
import { Title } from "../../atoms/commons/Title";
import { EmployeeRollTable } from "./EmployeeRollTable";
import { Paginate } from "../Paginate";
import { LinkButton } from "../../atoms/commons/LinkButton";

export const EmployeeRollList = memo(
  (props: {
    adminReaderList: AdminReaderListType;
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
  }) => {
    const { adminReaderList, paginateObj } = props;

    return (
      <div className="mb-10 min-h-full">
        <div className="flex justify-between">
          <Title src={SettingsIcon}>設定</Title>
          <LinkButton
            path={"/settings/employee-roll/update"}
            placementClassName="absolute top-[65px] right-[35px]"
          >
            更新
          </LinkButton>
        </div>
        <div className="mt-5 justify-center flex flex-col w-full">
          <span className="text-2xl font-bold leading-relaxed text-lightGreen-200 block mt-8">
            管理者
          </span>
          <table className="py-5 text-md justify-center mx-auto w-full mt-2">
            <thead>
              <tr className="border-b leading-none text-lightGreen-200">
                <th className="text-left py-5 w-16">氏名</th>
                <th className="text-left py-5 w-24">E-mail</th>
              </tr>
            </thead>
            {adminReaderList.admins.map((admin) => (
              <EmployeeRollTable
                key={admin.id}
                name={admin.name}
                emailAddress={admin.emailAddress}
              />
            ))}
          </table>
          <Paginate
            handlePageChange={paginateObj.handlePageChange}
            offset={paginateObj.offset}
            perPage={5}
            listLength={adminReaderList.admins.length}
            hight={10}
          />
        </div>
        <div className="mt-5 mb-12 justify-center flex flex-col w-full">
          <span className="text-2xl font-bold leading-relaxed text-lightGreen-200 block mt-8">
            閲覧者
          </span>
          <table className="py-5 text-md justify-center mx-auto w-full mt-2">
            <thead>
              <tr className="border-b leading-none text-lightGreen-200">
                <th className="text-left py-5 w-16">氏名</th>
                <th className="text-left py-5 w-24">E-mail</th>
              </tr>
            </thead>
            {adminReaderList.readers.map((reader) => (
              <EmployeeRollTable
                key={reader.id}
                name={reader.name}
                emailAddress={reader.emailAddress}
              />
            ))}
          </table>
          <Paginate
            handlePageChange={paginateObj.handlePageChange}
            offset={paginateObj.offset}
            perPage={5}
            listLength={adminReaderList.admins.length}
            hight={20}
          />
        </div>
      </div>
    );
  }
);
