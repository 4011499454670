import { useCallback, useEffect, useState } from "react";

import { getPastDepartmentHistoryList } from "../../api/apiHistory";
import {
  PastEmployeeType,
  PatsEmployeeListResponseType,
  PastEmployeeDepartmentType,
} from "../../types/api/organizationHistory";

export const useFetchPastEmployeeList = (
  departmentHistoryId: any,
  departmentId: string
) => {
  const [employeeList, setEmployeeList] = useState<PastEmployeeType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const FetchPastEmployeeList = useCallback(async () => {
    const res = await getPastDepartmentHistoryList(
      departmentHistoryId,
      departmentId
    );

    if (res.status !== 200) {
      setIsLoading(false);
      return alert("過去組織図一覧のデータ取得に失敗しました");
    }

    const employees: PastEmployeeType[] = res.data.data.employee_histories.map(
      (employee: PatsEmployeeListResponseType) => {
        const couplingDepartmentName = employee.departments
          .sort(
            (a: PastEmployeeDepartmentType, b: PastEmployeeDepartmentType) => {
              if (a.concurrent === false && b.concurrent === true) {
                return -1;
              } else {
                return 1;
              }
            }
          )
          .reduce((n: string, m: PastEmployeeDepartmentType, i: number) => {
            if (i === 0) {
              return n + m.name;
            } else {
              return `${n}/${m.name}(兼任)`;
            }
          }, "");
        return {
          id: employee.id,
          businessName: employee.business_name,
          businessNameKana: employee.business_name_kana,
          departmentName: couplingDepartmentName,
          cordColor: employee.cord_color,
          profileImage: employee.profile_image,
        };
      }
    );
    setIsLoading(false);
    setEmployeeList(employees);
  }, []);

  useEffect(() => {
    FetchPastEmployeeList();
  }, []);

  return {
    isLoading,
    employeeList,
  };
};
