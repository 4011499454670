import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getStressCheckResult } from "../../api/apiStressCheck";
import { StressCheckResultResponseType } from "../../types/api/stressCheck";

export const useFetchStressCheckResult = () => {
  const { stressCheckId } = useParams<{ stressCheckId: string }>();

  const [stressCheckResult, setStressCheckResult] =
    useState<StressCheckResultResponseType>();

  const fetchStressCheckResult = useCallback(async (stressCheckId) => {
    const res = await getStressCheckResult(stressCheckId);

    if (res.status !== 200) {
      return alert("結果の取得に失敗しました。");
    }
    setStressCheckResult(res.data.data);
  }, []);

  useEffect(() => {
    fetchStressCheckResult(stressCheckId);
  }, []);

  return {
    stressCheckResult,
  };
};
