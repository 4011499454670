export const cardColorSwitch = (cordColor: string) => {
  switch (cordColor) {
    case "green":
      return "#4FC978";
    case "yellow_green":
      return "#BDE46A";
    case "orange":
      return "#EAB667";
    case "red":
      return "#E46D6D";
    case "pink":
      return "#EA6394";
    case "violet":
      return "#DC69C3";
    case "dark_blue":
      return "#5D71D8";
    case "blue":
      return "#58B0E2";
    default:
      return "bg-cordColor-red";
  }
};

export const profileCardColorSwith = (changeColorBtn: string) => {
  switch (changeColorBtn) {
    case "green":
      return "bg-cordColor-green text-white";
    case "yellow_green":
      return "bg-cordColor-yellow_green text-white";
    case "orange":
      return "bg-cordColor-orange text-white";
    case "red":
      return "bg-cordColor-red text-white";
    case "pink":
      return "bg-cordColor-pink text-white";
    case "violet":
      return "bg-cordColor-violet text-white";
    case "dark_blue":
      return "bg-cordColor-dark_blue text-white";
    case "blue":
      return "bg-cordColor-blue text-white";
    case "light_blue":
      return "bg-cordColor-light_blue text-white";
    case "white":
      return "bg-white text-black";
    default:
      return "bg-white";
  }
};

// White       CardColorString = "white"        // 白 デフォルトの色
// Green       CardColorString = "green"        // 緑
// YellowGreen CardColorString = "yellow_green" // 黄緑
// Orange      CardColorString = "orange"       // オレンジ
// Red         CardColorString = "red"          // 赤
// Pink        CardColorString = "pink"         // ピンク
// Violet      CardColorString = "violet"       // 赤紫
// DarkBlue    CardColorString = "dark_blue"    // 濃い青
// Blue        CardColorString = "blue"         // 青
// LightBlue   CardColorString = "light_blue"
