import Employee from "../../../images/employee.png";
import StressCheck from "../../../images/stress-check.png";

export const GeneralSidebarList = [
  {
    id: 1,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/employees",
    children: "社員紹介",
    disable: false,
  },
  {
    id: 2,
    iconImage: StressCheck,
    iconAlt: "stress-check.png",
    path: "/stress-checks",
    children: "ストレスチェック",
    disable: false,
  },
  {
    id: 3,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/departments",
    children: "組織図",
    disable: false,
  },
];
