import { useState } from "react";

// モーダルの表示非表示
export const useShowModalFlagHandler = () => {
  const [showCreateModalFlag, setShowCreateModalFlag] =
    useState<boolean>(false);

  const onChangeCreateModalFlag = () => {
    setShowCreateModalFlag(!showCreateModalFlag);
  };
  const [showCreateHistoryModalFlag, setShowCreateHistoryModalFlag] =
    useState<boolean>(false);

  const onChangeHistoryModalFlag = () => {
    setShowCreateHistoryModalFlag(!showCreateHistoryModalFlag);
  };

  const [showResumeModalFlag, setShowResumeModalFlag] =
    useState<boolean>(false);
  const onChangeResumeModalFlag = () => {
    setShowResumeModalFlag(!showResumeModalFlag);
  };

  const [showEditModalFlag, setShowEditModalFlag] = useState<boolean>(false);

  const onChangeEditModalFlag = () => {
    setShowEditModalFlag(!showEditModalFlag);
  };

  return {
    showCreateModalFlag,
    onChangeCreateModalFlag,
    showEditModalFlag,
    onChangeEditModalFlag,
    showCreateHistoryModalFlag,
    onChangeHistoryModalFlag,
    showResumeModalFlag,
    onChangeResumeModalFlag,
  };
};
