import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  AnswersRequestType,
  StressCheckDisclosureResponseType,
  StressCheckListResponseType,
  StressCheckQuestionsResponseType,
  StressCheckResultResponseType,
} from "../types/api/stressCheck";

// ストレスチェック一覧データ取得
export const getStressCheckList = async () => {
  return await api
    .get<StressCheckListResponseType>("/merchant/stresschecks")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック質問一覧データ取得
export const getStressCheckQuestions = (stressCheckId: number) => {
  return api
    .get<StressCheckQuestionsResponseType>(
      `/merchant/stresscheck/${stressCheckId}`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック回答データ送信
export const postStressCheckAnswers = (
  stressCheckId: number,
  data: AnswersRequestType
) => {
  return api
    .post(`/merchant/stresscheck/${stressCheckId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック結果データ取得
export const getStressCheckResult = (stressCheckId: number) => {
  return api
    .get<StressCheckResultResponseType>(
      `merchant/stresscheck/${stressCheckId}/result`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック情報開示同意ステータス取得
export const getStressCheckDisclosureResponse = (
  stressCheckFrameId: number
) => {
  return api
    .get<StressCheckDisclosureResponseType>(
      `merchant/stresscheck/disclosure_response/${stressCheckFrameId}`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ストレスチェック情報開示同意ステータス更新
export const putStressCheckDisclosureResponse = (data: {
  stress_check_frame_id: string;
  disclosure_response: string;
}) => {
  return api
    .put("merchant/stresscheck/disclosure_response", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
