import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company.png";
import {
  EditOfficeFormType,
  OfficeRequestType,
} from "../../../types/api/office";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { ReturnButton } from "../../atoms/commons/ReturnButton";
import { RegistrationButton } from "../../atoms/employeeMaintenance/RegistrationButton";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";

type EditProps = {
  createOffice: () => void;
  setCreateRequestData: React.Dispatch<React.SetStateAction<OfficeRequestType>>;
  isPreviewVisible: boolean;
  setIsPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OfficeCreateForm = ({
  createOffice,
  setCreateRequestData,
  isPreviewVisible,
  setIsPreviewVisible,
}: EditProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditOfficeFormType>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      address: "",
      postcode: "",
      phoneNumber: "",
    },
  });

  const onSubmit: SubmitHandler<EditOfficeFormType> = (data) => {
    setCreateRequestData({
      name: data.name,
      address: data.address,
      postcode: data.postcode,
      phone_number: data.phoneNumber,
    });
    setIsPreviewVisible(true);
  };
  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  //  確認フォーム・投稿フォームのinputタグのCSS
  const normalFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 投稿フォームと確認フォームをinput/textareaタグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;

  //投稿フォームと確認フォームの文言を動的に変更させる。
  const formDescription = !isPreviewVisible
    ? "事業場の情報を入力して、確認ボタンを押してください"
    : "入力した内容を確認して、登録ボタンを押してください";

  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>事業場登録</Title>
      </div>
      <div className="flex">
        <span className="my-11 text-sm">{formDescription}</span>
      </div>
      <div className="mt-5 mb-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="事業名"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("name", {
                required: true,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("name")}
            />
            {errors.name?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="電話番号"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("phoneNumber", {
                required: true,
                pattern: /^0[0-9]{9,10}$/i,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("phoneNumber")}
              placeholder="例）09012345678"
            />
            {errors.phoneNumber?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
            {errors.phoneNumber?.types?.pattern && (
              <span className="text-red-600 absolute bottom-0 left-0">
                ハイフンなしの10桁もしくは11桁の半角数字で入力してください。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="郵便番号"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("postcode", {
                required: true,
                pattern: /^[0-9]{7}$/i,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("postcode")}
              placeholder="例）1234567"
            />
            {errors.postcode?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
            {errors.postcode?.types?.pattern && (
              <span className="text-red-600 absolute bottom-0 left-0">
                ハイフンなしの7桁の半角数字で入力してください。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="住所"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("address", {
                required: true,
              })}
              className={formStyle + " min-w-[860px]"}
              disabled={isPreviewVisible}
              value={watch("address")}
            />
            {errors.address?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="mt-10	pb-20 text-center">
            {!isPreviewVisible ? (
              <>
                <ReturnButton />
                <ConfirmationButton>確認</ConfirmationButton>
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={createOffice}>
                  登録
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
