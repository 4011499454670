import React, { memo } from "react";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";
import {
  DepartmentResumeResponseType,
  OrganizationListResponseType,
} from "../../../types/api/department";
import { ModalDisplay } from "../../atoms/commons/ModalDisplay";
import { DepartmentResumeForm } from "../../atoms/departments/ModalResumeForm";
import { OpenDepartmentModalButton } from "../../atoms/departments/OpenModalDepartmentButton";
import { OpenAlertbutton } from "../../atoms/departments/OpenAlertButton";

export const DepartmentList = memo(
  (props: {
    departmentList: OrganizationListResponseType;
    showResumeModalFlag: boolean;
    onChangeResumeModalFlag: () => void;
    fetchOrganizationList: () => Promise<void>;
    saveDepartmentResume: (
      parentId: DepartmentResumeResponseType,
      departmentId: number
    ) => Promise<void>;
    submitCheckAlert: (id: number) => void;
    onClickResumeDepartment: (id: number, name: string) => void;
    departmentId: number;
    departmentName: string;
  }) => {
    const {
      departmentList,
      showResumeModalFlag,
      onChangeResumeModalFlag,
      fetchOrganizationList,
      saveDepartmentResume,
      submitCheckAlert,
      onClickResumeDepartment,
      departmentId,
      departmentName,
    } = props;

    return (
      <>
        <Title src={EmployeeManagement}>部署一覧</Title>
        <div className="py-11">
          <div className="text-md w-full">
            {/* 後々スクロール時に固定させる */}
            <div className="flex border-b leading-none text-lightGreen-200 text-lg font-bold py-5 px-9">
              <span className="w-1/3">部署名</span>
              <span className="w-2/3">ステータス</span>
            </div>
            {departmentList.departments.map((list) => {
              if (list.abolition === false) {
                return [
                  <div
                    key={list.id}
                    className="flex p-9 border-b items-center justify-between"
                  >
                    <div className="w-1/3">
                      <span className="text-lg">{list.name}</span>
                    </div>

                    <button
                      type="button"
                      onClick={() => submitCheckAlert(list.id)}
                      className={`w-28 border-gray-400 border py-2 px-4 rounded-lg hover:bg-gray-400 text-lg hover:text-white`}
                    >
                      <span className="text-lg font-w6 leading-relaxed">
                        廃止
                      </span>
                    </button>
                  </div>,
                ];
              }
            })}
            {departmentList.departments.map((list) => {
              if (list.abolition === true) {
                return [
                  <div key={list.id} className="flex p-9 border-b items-center">
                    <div className="w-1/3">
                      <span className="text-lg">{list.name}</span>
                    </div>
                    <div
                      key={list.id}
                      className="flex justify-between w-2/3 items-center"
                    >
                      <div className="w-full">
                        <span className="py-2 px-6 text-lg bg-[#E9E9E9] rounded-lg">
                          廃止
                        </span>
                      </div>
                      <ModalDisplay showModal={showResumeModalFlag}>
                        <DepartmentResumeForm
                          departmentList={departmentList}
                          onChangeModalFlag={onChangeResumeModalFlag}
                          departmentName={departmentName}
                          departmentId={departmentId}
                          fetchOrganizationList={fetchOrganizationList}
                          saveDepartmentResume={saveDepartmentResume}
                        />
                      </ModalDisplay>

                      <OpenDepartmentModalButton
                        onChangeModalFlag={() =>
                          onClickResumeDepartment(list.id, list.name)
                        }
                        name="再開"
                        modalClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg"
                      />
                    </div>
                  </div>,
                ];
              }
            })}
          </div>
        </div>
      </>
    );
  }
);
