import React, { memo } from "react";

import { SendMailConfirmForm } from "../../templates/stressCheckMaintenance/SendMailConfirmForm";

export const SendMailConfirm = memo(() => {
  return (
    <>
      <SendMailConfirmForm />
    </>
  );
});
