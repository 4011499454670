import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { InterviewSubjectSelectionListTable } from "../../atoms/stressCheckManagements/InterviewSubjectSelectionListTable";
import { NotInterviewTarget } from "../../atoms/stressCheckManagements/NotInterviewSubjectSelectionList";
import {
  useFetchStressCheckCandidateList,
  useSaveStressCheckInterviewTargets,
} from "../../../hooks/stressCheckManagements/useStressCheckInterviewSubject";

export const InterviewSubjectSelectionList = () => {
  const params = useParams<string>();
  const stressCheckId = params.id;

  const { intervieweeCandidateList } =
    useFetchStressCheckCandidateList(stressCheckId);
  const { saveStressCheckInterviewTargets } =
    useSaveStressCheckInterviewTargets();

  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [allCheckBox, setAllCheckBox] = useState<boolean>(false);

  const setCheckedEmployeeList = () => {
    if (!allCheckBox) {
      setCheckedValues([]);
      const allIdArry = intervieweeCandidateList.interviewee_candidates.map(
        (employee) => employee.stress_check_id
      );
      setCheckedValues(allIdArry);
      setAllCheckBox(true);
    } else {
      setCheckedValues([]);
      setAllCheckBox(false);
    }
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(e.target.value, 10);

    if (checkedValues.includes(num)) {
      const setDate = checkedValues.filter(
        (checkedValue: number) => checkedValue !== num
      );
      setCheckedValues(setDate);
    } else {
      setCheckedValues([...checkedValues, num]);
    }
  };

  const onClickRegisterButton = () => {
    if (!window.confirm("本当に登録しても宜しいですか？")) {
      return;
    }

    const data = { interview_targets: checkedValues };

    saveStressCheckInterviewTargets(stressCheckId, data);
  };

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(50);

  const handlePageChange = (data: { selected: number }) => {
    // eslint-disable-next-line
    let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  };

  return (
    <>
      <Title src={StressCheckManagement}>面談対象者選定</Title>
      <ExplanatoryText>
        面談の対象者を選択して、登録してください。
      </ExplanatoryText>
      <div>
        <span className="font-bold text-base text-red-600">
          ※ 面談指導要否文が未設定の場合は、登録できません。
        </span>
      </div>
      <div className="w-full py-[30px] border-y border-lightGray-100">
        <div>
          <span className="font-bold text-lg text-lightGreen-200">
            テスト名
          </span>
          <span className="ml-[110px] font-normal	text-lg">
            {intervieweeCandidateList.title}
          </span>
        </div>
      </div>
      {!intervieweeCandidateList.interviewee_candidates.length ? (
        <NotInterviewTarget onClickRegisterButton={onClickRegisterButton} />
      ) : (
        <>
          {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
          {/* <div className="flex items-center my-[30px]">
          <p className="text-lg leading-relaxed text-gray-700">
            1 - {offset + perPage} 件 / 全 {List.length}件中
          </p>
          <div className="flex items-center ml-auto">
            <div className="flex items-center">
              <span className="font-bold text-lg text-lightGreen-200">
                表示件数
              </span>
              <div className="relative leading-relaxed ml-[15px]">
                <img
                  className="w-2 h-2 absolute top-1 right-0 m-4 pointer-events-none"
                  src={Choice}
                ></img>
                <select className="lg:border border-lightGreen-200 rounded-lg h-[50px] py-2.5 pr-10 pl-7 hover:border-gray-400 focus:outline-none appearance-none">
                  <option value={50}>50件</option>
                  <option value={100}>100件</option>
                  <option value={250}>250件</option>
                  <option value={"ALL"}>ALL</option>
                </select>
              </div>
            </div>
            <div className="flex items-center">
              <span className="ml-[50px] font-bold text-lg text-lightGreen-200">
                検索
              </span>
              <div className="w-80 h-12">
                <input
                  className="ml-[30px] h-[50px] w-[300px] pl-4 border border-lightGreen-200 rounded-lg"
                  placeholder="氏名・社員Noを入力"
                />
              </div>
              <button className="ml-[50px] bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[10px] px-[20px]">
                <span className="font-bold text-lg text-white">検索</span>
              </button>
            </div>
          </div> */}
          {/**********************************************************************************/}
          <div className="w-full flex items-center mb-[65px]">
            <div className="mt-5 justify-center container flex flex-col">
              <div className="flex items-center mb-[30px]">
                <div className="bg-lightGreen-400 border border-lightGreen-400 rounded text-center py-[8px] px-[20px] ml-[10px]">
                  <input
                    type="checkbox"
                    onChange={setCheckedEmployeeList}
                    className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block"
                  />
                  <span className="ml-[10px]">全てを選択</span>
                </div>

                <div className="ml-auto">
                  <span className="font-bold text-base text-red-600">
                    ※ 対象者無しの場合でも、登録ボタンを押してください。
                  </span>
                  <button
                    type="button"
                    onClick={onClickRegisterButton}
                    className="bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[5px] px-[16px]"
                  >
                    <span className="font-bold text-lg text-white">登録</span>
                  </button>
                </div>
              </div>
              <table className="py-5 container ml-auto w-full">
                <thead>
                  <tr className="border-b leading-none">
                    <th className="text-left w-[80px]"></th>
                    <th className="text-left w-[200px]">
                      <span className="font-bold text-lg text-lightGreen-200">
                        氏名
                      </span>
                    </th>
                    <th className="text-left">
                      <span className="font-bold text-lg text-lightGreen-200">
                        受検日
                      </span>
                    </th>
                  </tr>
                </thead>
                {intervieweeCandidateList.interviewee_candidates
                  .slice(offset, offset + perPage)
                  .map((employeeList) => (
                    <InterviewSubjectSelectionListTable
                      key={employeeList.stress_check_id}
                      stressCheckId={employeeList.stress_check_id}
                      name={employeeList.name}
                      examination_date={employeeList.examination_date}
                      handleCheckBoxChange={handleCheckBoxChange}
                      checkedValues={checkedValues}
                    />
                  ))}
              </table>
            </div>
          </div>
          <div className="h-[60px] w-full mt-11 mb-10 flex justify-center">
            <p className="mr-auto text-lg leading-relaxed text-gray-700">
              1 - {offset + perPage} 件 / 全
              {intervieweeCandidateList.interviewee_candidates.length}
              件中
            </p>
            <div className="flex mr-80">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  intervieweeCandidateList.interviewee_candidates.length /
                    perPage
                )} // 全部のページ数。端数の場合も考えて切り上げに。
                marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
                pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
                onPageChange={handlePageChange} // クリック時のfunction
                containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
                activeClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // アクティブなページのliに着くクラス名
                previousClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「<」のliに着けるクラス名
                nextClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「>」のliに着けるクラス名
                disabledClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 使用不可の「<,>」に着くクラス名
                breakClassName={
                  "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                } // 「...」に着くクラス名
                pageClassName={
                  "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
                }
                // aタグのクラス名
                pageLinkClassName={"block w-10 h-full text-center no-underline"}
                previousLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
                nextLinkClassName={"block w-10 h-full text-center no-underline"}
                disabledLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
                breakLinkClassName={
                  "block w-10 h-full text-center no-underline"
                }
              />
            </div>
          </div>
          <div className="w-full text-center inline-block bg-lightGreen-100 py-[40px] mb-[30px]">
            <p className="font-bold text-lg">
              登録ボタンを押して、選択した面談対象者を登録してください。
            </p>
            <p className="font-bold text-base text-red-600">
              ※ 対象者無しの場合でも、登録ボタンを押してください。
            </p>
            <button
              type="button"
              onClick={onClickRegisterButton}
              className="bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[5px] px-[50px] mt-[30px]"
            >
              <span className="font-bold text-lg text-white">登録</span>
            </button>
          </div>
        </>
      )}
    </>
  );
};
