import React, { FC, memo, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import backIcon from "../../../images/back-icon.png";
import { StressCheckDisclosureResponseType } from "../../../types/api/stressCheck";
import { ErrorMassage } from "../commons/ErrorMassage";

type Props = {
  onChangeModalFlag: () => void;
  disclosureResponse: StressCheckDisclosureResponseType;
  stressCheckFrameId: number;
  updateStressCheckDisclosureResponse: (data: {
    stress_check_frame_id: number;
    disclosure_response: string;
  }) => Promise<void>;
};

export const ModalAgreeForm: FC<Props> = memo((props) => {
  const {
    onChangeModalFlag,
    disclosureResponse,
    stressCheckFrameId,
    updateStressCheckDisclosureResponse,
  } = props;
  const [disclosureStatus, setDisclosureStatus] =
    useState<string>(disclosureResponse);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ agree: string }>();

  // 保存処理（未）
  const submit: SubmitHandler<{ agree: string }> = async () => {
    const data = {
      stress_check_frame_id: stressCheckFrameId,
      disclosure_response: disclosureStatus,
    };
    updateStressCheckDisclosureResponse(data);
    reset();
    onChangeModalFlag();
  };

  return (
    <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
      <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
        同意取得
      </p>
      <>
        <p className="text-sm">
          ストレスプロフィールの会社への提供に同意をお願いいたします。
        </p>
        <button onClick={onChangeModalFlag} className="absolute top-5 right-5">
          <img src={backIcon} alt="back-icon" />
        </button>
        <>
          <form className="w-full" onSubmit={handleSubmit(submit)}>
            <div className="w-full mt-5 flex flex-col justify-center items-center border-t border-lightGray-100">
              <div className="w-full mt-10 px-16 flex justify-center text-center text-sm">
                <div className="flex-1 justify-center">
                  <input
                    type="radio"
                    {...register("agree", { required: true })}
                    value="1"
                    checked={disclosureStatus === "1"}
                    onChange={(e) => setDisclosureStatus(e.target.value)}
                    className="appearance-none rounded-full h-5 w-5 border border-lightGreen-200 bg-white checked:border-lightGreen-200 checked:bg-lightGreen-200 mt-[3px] align-top cursor-pointer"
                  />
                  <label className="pl-3">同意する</label>
                </div>
                <div className="flex-1">
                  <input
                    type="radio"
                    {...register("agree", { required: true })}
                    value="0"
                    checked={disclosureStatus === "0"}
                    onChange={(e) => setDisclosureStatus(e.target.value)}
                    className="appearance-none rounded-full h-5 w-5 border border-lightGreen-200 bg-white checked:border-lightGreen-200 checked:bg-lightGreen-200 mt-[3px] align-top cursor-pointer"
                  />
                  <label className="pl-3">同意しない</label>
                </div>
              </div>
              {errors.agree?.type === "required" && (
                <div className="mt-1">
                  <ErrorMassage error="required" style="modal" />
                </div>
              )}
              <button
                type="submit"
                className="mt-10 inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
              >
                登録
              </button>
            </div>
          </form>
          <div className="mt-10 pt-5 w-[600px] flex border-t border-lightGray-100">
            <p>※</p>
            <p className="pl-1">
              ストレスプロフィール（医師面接指導の要否を含む）は、社内規定で規定されている利用目的のため、規定されている共有範囲内で共有します。
              目的以外に利用することは一切ありません。医師面接指導を経ず、個人結果を基に就業上の措置を講じることはございません。
            </p>
          </div>
        </>
      </>
    </div>
  );
});
