import React from "react";

import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company.png";

export const OfficeCreateComplete = () => {
  return (
    <>
      <Title src={Company}>事業場登録</Title>
      <ExplanatoryText>事業場の登録が完了しました。</ExplanatoryText>
      <div className="border-t border-lightGray-100">
        <ReturnHomeButton path="/offices">事業場一覧へ戻る</ReturnHomeButton>
      </div>
    </>
  );
};
