import { useCallback, useState } from "react";

import { getDepartment } from "../../api/apiDepartment";
import { DepartmentResponseType } from "../../types/api/department";

// 部署単体を取得
export const useFetchDepartment = () => {
  const [department, setDepartment] = useState<DepartmentResponseType>({
    id: 0,
    name: "",
    parent_id: "",
  });

  const fetchDepartment = useCallback(
    async (
      id: number,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await getDepartment(id);
      if (res.status !== 200) {
        return alert("部署詳細の取得に失敗しました");
      }
      setDepartment(res.data.data);
      setLoading(false);
    },
    []
  );

  const changeNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDepartment({ ...department, name: e.target.value });
  };

  const changeParentIdInputValue = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();
    setDepartment({ ...department, parent_id: e.target.value });
  };

  return {
    department,
    fetchDepartment,
    changeNameInputValue,
    changeParentIdInputValue,
  };
};
