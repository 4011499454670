import React, { memo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import backIcon from "../../../images/back-icon.png";
import Choice from "../../../images/choice.png";
import { useSaveDepartment } from "../../../hooks/department/useSaveDepartment";
import {
  DepartmentFormType,
  DepartmentList,
} from "../../../types/api/department";
import { FormLabel } from "../commons/FormLabel";
import { ErrorMassage } from "../commons/ErrorMassage";

export const ModalCreateForm = memo(
  (props: {
    fetchDepartmentTree: () => Promise<void>;
    fetchDepartmentList: () => Promise<void>;
    onChangeModalFlag: () => void;
    departmentList: DepartmentList;
  }) => {
    const {
      fetchDepartmentTree,
      fetchDepartmentList,
      onChangeModalFlag,
      departmentList,
    } = props;

    const { saveDepartment } = useSaveDepartment();

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<DepartmentFormType>();

    const submit: SubmitHandler<DepartmentFormType> = async (date) => {
      await saveDepartment(date);
      await fetchDepartmentTree();
      await fetchDepartmentList();
      reset();
      onChangeModalFlag();
    };

    return (
      <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
        <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
          登録フォーム
        </p>
        <button onClick={onChangeModalFlag} className="absolute top-5 right-5">
          <img src={backIcon} alt="back-icon" />
        </button>
        <form onSubmit={handleSubmit(submit)}>
          <div className="w-full flex flex-col justify-center items-center border-t border-lightGray-100">
            <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
              <FormLabel
                title="部署名"
                className="text-lightGreen-200 font-semibold"
              />
              <div className="ml-[75px]">
                <input
                  {...register("name", {
                    required: true,
                    maxLength: 50,
                  })}
                  className="w-[400px] mb-1 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200"
                />
                {errors.name?.type === "required" && (
                  <ErrorMassage error="required" style="modal" />
                )}
                {errors.name?.type === "maxLength" && (
                  <ErrorMassage error="maxLength-50" style="modal" />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
              <FormLabel
                title="上位部署"
                className="text-lightGreen-200 font-semibold"
              />
              <div className="relative">
                <img
                  src={Choice}
                  alt="choice-icon"
                  className="w-3 h-3 absolute top-1 right-0 m-4 pointer-events-none"
                />
                <select
                  className="border border-lightGreen-200 appearance-none rounded-lg w-[400px] h-12 pl-4 ml-[75px]
                focus:outline-none disabled:text-black disabled:opacity-100"
                  {...register("parent_id")}
                >
                  <option value="">上位部署なし</option>
                  {departmentList.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="submit"
              className="mt-10 inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
            >
              保存
            </button>
          </div>
        </form>
      </div>
    );
  }
);
