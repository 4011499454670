import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

export const ReturnStressCheckManagementButton = memo(
  (props: { stressCheckId: string | undefined }) => {
    const navigate = useNavigate();
    const { stressCheckId } = props;

    return (
      <button
        type="button"
        onClick={() => navigate(`/stress-checks-management/${stressCheckId}`)}
        className="inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200"
      >
        戻る
      </button>
    );
  }
);
