import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import { OpenDepartmentModalButton } from "../../atoms/departments/OpenModalDepartmentButton";
import { NavigateButton } from "../../atoms/commons/NavigateButton";
import EmployeeManagement from "../../../images/employee.png";
import EmployeeIcon from "../../../images/figure-icon.png";
import EmployeesIcon from "../../../images/figure-icon.png";
import { TreeObj } from "../../atoms/departments/TreeObj";
import {
  DepartmentList,
  OrganizationTrees,
} from "../../../types/api/department";
import { ModalDisplay } from "../../atoms/commons/ModalDisplay";
import { ModalCreateForm } from "../../atoms/departments/ModalCreateForm";
import { HistoryModalCreateForm } from "../../atoms/departments/ModalHistoryCreateForm";
import { ModalEditForm } from "../../atoms/departments/ModalEditForm";

export const DepartmentTreeFigure = memo(
  (props: {
    departmentObject: {
      userRoll: string | undefined;
      isLoading: boolean;
      departmentTree: OrganizationTrees;
      departmentList: DepartmentList;
      fetchDepartmentTree: () => Promise<void>;
      fetchDepartmentList: () => Promise<void>;
      showCreateModalFlag: boolean;
      onChangeCreateModalFlag: () => void;
      showEditModalFlag: boolean;
      onChangeEditModalFlag: () => void;
      showCreateHistoryModalFlag: boolean;
      onChangeHistoryModalFlag: () => void;
      submitCheckAlert: (id: number) => Promise<void>;
    };
  }) => {
    const { departmentObject } = props;

    const [departmentId, setDepartmentId] = useState<number>(0);

    const onClickEditDepartment = (id: number) => {
      setDepartmentId(id);
      departmentObject.onChangeEditModalFlag();
    };

    return (
      <>
        {/********************************************************
         * TODO: CreateFormとEditFormは同じコンポーネント化にできそう
         *******************************************************/}
        <ModalDisplay showModal={departmentObject.showCreateModalFlag}>
          <ModalCreateForm
            departmentList={departmentObject.departmentList}
            fetchDepartmentTree={departmentObject.fetchDepartmentTree}
            fetchDepartmentList={departmentObject.fetchDepartmentList}
            onChangeModalFlag={departmentObject.onChangeCreateModalFlag}
          />
        </ModalDisplay>
        <ModalDisplay showModal={departmentObject.showCreateHistoryModalFlag}>
          <HistoryModalCreateForm
            onChangeModalFlag={departmentObject.onChangeHistoryModalFlag}
          />
        </ModalDisplay>

        <div className="flex justify-between items-center">
          <Title src={EmployeeManagement}>組織図</Title>
          {departmentObject.userRoll === "01" ? (
            <div className="flex">
              <NavigateButton
                navigatePath="/departments/transition"
                buttonClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg"
              >
                組織図変遷
              </NavigateButton>
              <NavigateButton
                navigatePath="/departments/histories"
                buttonClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg"
              >
                過去組織図
              </NavigateButton>
              <NavigateButton
                navigatePath="/departments/list"
                buttonClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg"
              >
                部署一覧
              </NavigateButton>
              <OpenDepartmentModalButton
                onChangeModalFlag={departmentObject.onChangeCreateModalFlag}
                name="部署登録"
                modalClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14"
              />
              <OpenDepartmentModalButton
                onChangeModalFlag={departmentObject.onChangeHistoryModalFlag}
                name="保存"
                modalClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14"
              />
            </div>
          ) : (
            <NavigateButton
              navigatePath="/departments/histories"
              buttonClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg"
            >
              過去組織図
            </NavigateButton>
          )}
        </div>
        <div className="border-t border-lightGray-100 mt-[35px]"></div>
        {departmentObject.departmentTree.map((parent) => (
          <div key={parent.id}>
            <ModalDisplay showModal={departmentObject.showEditModalFlag}>
              <ModalEditForm
                department_id={departmentId}
                departmentList={departmentObject.departmentList}
                fetchDepartmentTree={departmentObject.fetchDepartmentTree}
                fetchDepartmentList={departmentObject.fetchDepartmentList}
                onChangeModalFlag={departmentObject.onChangeEditModalFlag}
                submitCheckAlert={departmentObject.submitCheckAlert}
                parentId={departmentId}
              />
            </ModalDisplay>
            <div className="py-[20px] h-[800px] sticky overflow-x-scroll border-b border-lightGray-200">
              <div className="absolute">
                <div className="flex relative">
                  <div>
                    <div className="min-w-[300px] max-w-[300px]">
                      <div className="py-[10px] flex justify-center items-center border rounded-lg border-lightGreen-200">
                        <button
                          type="button"
                          onClick={() => onClickEditDepartment(parent.id)}
                          className={
                            departmentObject.userRoll === "01"
                              ? "flex items-center hover:underline"
                              : "flex items-center pointer-events-none"
                          }
                        >
                          <img
                            className="w-10 min-w-10"
                            src={
                              parent.employee_numbers > 1
                                ? EmployeesIcon
                                : EmployeeIcon
                            }
                          ></img>
                          <span className="ml-[5px] text-left min-w-[173px] w-[173px] text-lightGreen-200 text-[16px] font-semibold leading-5">
                            {parent.name}
                          </span>
                        </button>
                        <Link to={`/employees?department_id=` + `${parent.id}`}>
                          <div className="min-w-[60px] py-[5px] px-1 text-center rounded-lg bg-lightGreen-100 hover:opacity-50">
                            <span className="text-lightGreen-200 font-semibold text-[18px] leading-5">
                              {parent.employee_numbers}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {parent.children === null ? (
                    ""
                  ) : (
                    <TreeObj
                      tree={parent.children}
                      onClickEditDepartment={onClickEditDepartment}
                      userRoll={departmentObject.userRoll}
                      submitCheckAlert={departmentObject.submitCheckAlert}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
);
