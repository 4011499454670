import { useCallback, useState, useEffect } from "react";

import { deleteDepartmentAbolition } from "../../api/apiDepartment";

export const useFetchDepartmentAbolition = () => {
  const useDepartmentAbolition = useCallback(async (departmentId) => {
    const res = await deleteDepartmentAbolition(departmentId);
    if (res.status !== 200) {
      return alert("部署の廃止に失敗しました");
    }
    return alert("部署の廃止に成功しました");
  }, []);

  return {
    useDepartmentAbolition,
  };
};
