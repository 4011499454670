// 仮データ（削除予定）
import { GroupAnalyticsReportResponseType } from "../types/api/groupAnalytics";

export const provisionalOfficeList: GroupAnalyticsReportResponseType = {
  start_date: "2022/3/1",
  end_date: "2022/3/31",
  num_of_grades: 5,
  thresholds: [
    [1, 1.9],
    [2, 2.6],
    [2.7, 3.3],
    [3.4, 4],
    [4.1, 5],
  ],
  the_group_list: [
    {
      the_group_name: "東京オフィス",
      the_group_score: 4,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 0,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 5,
            },
            {
              name: "技能の活用度",
              score: 4,
              overall_score: 4,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "名古屋オフィス",
      the_group_score: 4,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 4,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "大阪オフィス",
      the_group_score: 0,
      overall_score: 0,
      explanation: "",
      stress_check_info: {
        examinees: 0,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 0,
        overall_highstress_rate: 0,
        doctor_interview_targets: 0,
        overall_doctor_interview_targets: 0,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 0,
              overall_score: 0,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 0,
              overall_score: 0,
            },
            {
              name: "自覚的な身体的負担度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 0,
              overall_score: 0,
            },
            {
              name: "職場環境によるストレス",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事のコントロール度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "技能の活用度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事の的制度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "働きがい",
              score: 0,
              overall_score: 0,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "活気",
              score: 0,
              overall_score: 0,
            },
            {
              name: "イライラ感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "疲労感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "不安感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "抑うつ感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "身体愁訴",
              score: 0,
              overall_score: 0,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "上司からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "同僚からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "家族・友人からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事や生活の満足度",
              score: 0,
              overall_score: 0,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "大阪オフィス",
      the_group_score: 4,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "大阪オフィス",
      the_group_score: 4,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
  ],
};
export const provisionalDepartmentList: GroupAnalyticsReportResponseType = {
  start_date: "2022/3/1",
  end_date: "2022/3/31",
  num_of_grades: 5,
  thresholds: [
    [1, 1.9],
    [2, 2.6],
    [2.7, 3.3],
    [3.4, 4],
    [4.1, 5],
  ],
  the_group_list: [
    {
      the_group_name: "部署1",
      the_group_score: 2,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 0,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 5,
            },
            {
              name: "技能の活用度",
              score: 4,
              overall_score: 4,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "部署2",
      the_group_score: 2,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 4,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "部署3",
      the_group_score: 0,
      overall_score: 0,
      explanation: "",
      stress_check_info: {
        examinees: 0,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 0,
        overall_highstress_rate: 0,
        doctor_interview_targets: 0,
        overall_doctor_interview_targets: 0,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 0,
              overall_score: 0,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 0,
              overall_score: 0,
            },
            {
              name: "自覚的な身体的負担度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 0,
              overall_score: 0,
            },
            {
              name: "職場環境によるストレス",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事のコントロール度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "技能の活用度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事の的制度",
              score: 0,
              overall_score: 0,
            },
            {
              name: "働きがい",
              score: 0,
              overall_score: 0,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "活気",
              score: 0,
              overall_score: 0,
            },
            {
              name: "イライラ感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "疲労感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "不安感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "抑うつ感",
              score: 0,
              overall_score: 0,
            },
            {
              name: "身体愁訴",
              score: 0,
              overall_score: 0,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 0,
          overall_score: 0,
          comment: "",
          scales: [
            {
              name: "上司からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "同僚からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "家族・友人からのサポート",
              score: 0,
              overall_score: 0,
            },
            {
              name: "仕事や生活の満足度",
              score: 0,
              overall_score: 0,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "部署4",
      the_group_score: 2,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
    {
      the_group_name: "部署5",
      the_group_score: 2,
      overall_score: 4.8,
      explanation:
        "全体のストレス度は「やや良好」です。さらに良好な状態を目指しましょう。\nすべての受検対象者が受検しています。今後もこの状態を継続していきましょう。\n高ストレス者の割合は高い水準でした。",
      stress_check_info: {
        examinees: 1,
        overall_examinees: 3,
        highstress: 0,
        overall_highstress: 3,
        highstress_rate: 100,
        overall_highstress_rate: 100,
        doctor_interview_targets: 1,
        overall_doctor_interview_targets: 1,
      },
      sections: [
        {
          name: "ストレスの原因と考えられる因子",
          score: 2,
          overall_score: 3.8,
          comment:
            "ストレスの原因と考えられる因子は「やや注意」です。改善の取り組みをお勧めします。",
          scales: [
            {
              name: "心理的な仕事の負担（量）",
              score: 1,
              overall_score: 3.2,
            },
            {
              name: "心理的な仕事の負担（質）",
              score: 1,
              overall_score: 4.1,
            },
            {
              name: "自覚的な身体的負担度",
              score: 1,
              overall_score: 2.1,
            },
            {
              name: "職場の対人関係でのストレス",
              score: 2,
              overall_score: 1.9,
            },
            {
              name: "職場環境によるストレス",
              score: 1,
              overall_score: 2.5,
            },
            {
              name: "仕事のコントロール度",
              score: 5,
              overall_score: 4.1,
            },
            {
              name: "技能の活用度",
              score: 1,
              overall_score: 3.9,
            },
            {
              name: "仕事の的制度",
              score: 5,
              overall_score: 4.2,
            },
            {
              name: "働きがい",
              score: 5,
              overall_score: 3.7,
            },
          ],
        },
        {
          name: "ストレスによって起こる心身の反応",
          score: 5,
          overall_score: 4.5,
          comment:
            "ストレスによって起こる心身の反応は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "活気",
              score: 1,
              overall_score: 3,
            },
            {
              name: "イライラ感",
              score: 5,
              overall_score: 2.1,
            },
            {
              name: "疲労感",
              score: 3,
              overall_score: 5,
            },
            {
              name: "不安感",
              score: 4,
              overall_score: 1.1,
            },
            {
              name: "抑うつ感",
              score: 2,
              overall_score: 4,
            },
            {
              name: "身体愁訴",
              score: 5,
              overall_score: 5,
            },
          ],
        },
        {
          name: "ストレス反応に影響を与える他の因子",
          score: 5,
          overall_score: 2.6,
          comment:
            "ストレス反応に影響を与える他の因子は「良好」です。今後もこの状態が維持できるようにしていきましょう。",
          scales: [
            {
              name: "上司からのサポート",
              score: 4,
              overall_score: 4.9,
            },
            {
              name: "同僚からのサポート",
              score: 5,
              overall_score: 3.6,
            },
            {
              name: "家族・友人からのサポート",
              score: 3,
              overall_score: 4.2,
            },
            {
              name: "仕事や生活の満足度",
              score: 2,
              overall_score: 4.7,
            },
          ],
        },
      ],
    },
  ],
};
