import React, { memo, FC, useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";

import Logo from "../../../images/logo.png";
import { useAuth } from "../../../hooks/useAuth";
import { LoginButton } from "../../atoms/login/LoginButton";

export const Login: FC = memo(() => {
  const { login } = useAuth();

  const [companyId, setCompanyId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const onChangeCompanyId = (e: ChangeEvent<HTMLInputElement>) =>
    setCompanyId(e.target.value);
  const onChangeUserEmail = (e: ChangeEvent<HTMLInputElement>) =>
    setUserEmail(e.target.value);
  const onChangeUserPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setUserPassword(e.target.value);

  const onClickLogin = () => login(companyId, userEmail, userPassword);

  return (
    <div className="bg-lightGreen-100 flex flex-col justify-center items-center sm:py-12">
      <div className="flex flex-col space-y-16 items-center  px-[150px] pt-20 pb-28 bg-white shadow rounded-lg">
        <div className="flex justify-center items-center">
          <img src={Logo} alt="logo.png" width={300} height={200} />
        </div>
        <div className="flex flex-col space-y-5 items-center justify-end w-[400px]">
          <label className="font-bold text-lg font-w6 leading-none text-gray-700 w-full">
            会社ID
          </label>
          <input
            className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
            value={companyId}
            onChange={onChangeCompanyId}
          />
          <label className="font-bold text-lg font-w6 leading-none text-gray-700 w-full">
            メールアドレス
          </label>
          <input
            className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
            value={userEmail}
            onChange={onChangeUserEmail}
          />
          <label className="font-bold text-lg font-w6 leading-none text-gray-700 w-full">
            パスワード
          </label>
          <input
            className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full border-gray-700"
            value={userPassword}
            type="password"
            onChange={onChangeUserPassword}
          />
        </div>
        <LoginButton onClick={onClickLogin}>ログイン</LoginButton>
        <Link className="mb-10" to={`/password/reset`}>
          <span className="text-lg underline text-gray-700">
            パスワードを忘れた方はこちら
          </span>
        </Link>
      </div>
      <span className="text-xs leading-3 text-center text-gray-600 mt-6">
        © 2022 CAPS株式会社
      </span>
    </div>
  );
});
