import React, { FC, memo } from "react";

type Props = {
  children: string;
};

export const ExplanatoryText: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <div className="flex">
      <span className="my-10 text-sm">{children}</span>
    </div>
  );
});
