import { useCallback, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { postTask } from "../../api/apiEmployeeTask";
import { TaskListRequestType } from "../../types/api/EmployeeTask";

export const postTaskList = () => {
  const [showCreateModalFlag, setShowCreateModalFlag] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TaskListRequestType>({
    defaultValues: { name: "", status: 0 },
  });

  const onChangeCreateModalFlag = () => {
    setShowCreateModalFlag(!showCreateModalFlag);
  };

  const saveTask = useCallback(async (data: TaskListRequestType) => {
    const res = await postTask(data);
    if (res.status !== 201) {
      return alert("登録に失敗しました");
    }
  }, []);

  return {
    onChangeCreateModalFlag,
    saveTask,
    showCreateModalFlag,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  };
};
