import React, { FC } from "react";

type Props = {
  firstButtonOnClickIvent: () => void;
  seccondButtonOnClickIvent: (e: React.MouseEvent) => void;
  frameClassName: string;
  firstButtonClassName: string;
  seccondButtonClassName: string;
  firstButtonChildren: string;
  seccondButtonChildren: string;
};

export const FormBottons: FC<Props> = (props) => {
  const {
    firstButtonOnClickIvent,
    seccondButtonOnClickIvent,
    frameClassName,
    firstButtonClassName,
    seccondButtonClassName,
    firstButtonChildren,
    seccondButtonChildren,
  } = props;

  return (
    <>
      <div className={frameClassName}>
        <button
          type="button"
          onClick={firstButtonOnClickIvent}
          className={firstButtonClassName}
        >
          {firstButtonChildren}
        </button>
        <button
          type="button"
          onClick={seccondButtonOnClickIvent}
          className={seccondButtonClassName}
        >
          {seccondButtonChildren}
        </button>
      </div>
    </>
  );
};
