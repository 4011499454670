import Employee from "../../../images/employee.png";
import EmployeeManagement from "../../../images/employee-management.png";
import StressCheck from "../../../images/stress-check.png";
import StressCheckManagement from "../../../images/stress-check-management.png";
import Company from "../../../images/company.png";
import Mail from "../../../images/mail.png";
import Doctor from "../../../images/doctor.png";
import Settings from "../../../images/settings.png";
// import EmploymentRetirement from "../../../images/employment-retiremen_sidebar_icon.png";

export const ManagerSidebarList = [
  {
    id: 1,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/employees",
    children: "社員紹介",
    disable: false,
  },
  {
    id: 2,
    iconImage: EmployeeManagement,
    iconAlt: "employee-management.png",
    path: "/employees-management",
    children: "社員管理",
    disable: false,
  },
  {
    id: 3,
    iconImage: StressCheck,
    iconAlt: "stress-check.png",
    path: "/stress-checks",
    children: "ストレスチェック",
    disable: false,
  },
  {
    id: 4,
    iconImage: StressCheckManagement,
    iconAlt: "stress-check-management.png",
    path: "/stress-checks-management",
    children: "ストレスチェック管理",
    disable: false,
  },
  {
    id: 5,
    iconImage: Company,
    iconAlt: "company.png",
    path: "/offices",
    children: "事業場一覧",
    disable: false,
  },
  {
    id: 7,
    iconImage: Mail,
    iconAlt: "mail.png",
    path: "/contacts",
    children: "お問い合わせ先設定",
    disable: false,
  },
  // {
  //   id: 8,
  //   iconImage: EmploymentRetirement,
  //   iconAlt: "employment-retirement.png",
  //   path: "/employment-retirement-tasks",
  //   children: "入退職処理",
  //   disable: false,
  // },
  {
    id: 9,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/departments",
    children: "組織図",
    disable: false,
  },
  {
    id: 10,
    iconImage: Doctor,
    iconAlt: "doctor.png",
    path: "/doctor-collaboration",
    children: "産業医連携",
    disable: true,
  },
  {
    id: 11,
    iconImage: Settings,
    iconAlt: "settings.png",
    path: "/settings",
    children: "設定",
    disable: false,
  },
];
