import { AxiosResponse } from "axios";
import { api } from "./axiosConfig";
import {
  EmployeeMaintenanceListResponseType,
  EmployeeMaintenanceRequestType,
} from "../types/api/employeeMaintenance";

// 社員登録リスト取得
export const getEmployeeMaintenanceList = () => {
  return api
    .get<EmployeeMaintenanceListResponseType>("/merchant/maintenance/employees")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 社員登録データ送信
export const postMaintenanceEmployee = (
  data: EmployeeMaintenanceRequestType
) => {
  return api
    .post("/merchant/maintenance/employee", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 社員アカウント発行データ送信
export const postMaintenanceMerchantUser = (employeeId: string) => {
  return api
    .post("/merchant/maintenance/merchant_user", {
      employee_id: employeeId,
    })
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 社員詳細データ取得
export const getMaintenanceDetailEmployee = (
  employeeId: string | undefined
) => {
  return api
    .get(`/merchant/maintenance/employee/${employeeId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 社員情報編集
export const putMaintenanceEmployee = (
  data: EmployeeMaintenanceRequestType,
  employeeId: string | undefined
) => {
  return api
    .put(`/merchant/maintenance/employee/${employeeId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
