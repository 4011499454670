import React, { FC } from "react";

type Props = {
  cx: number;
  cy: number;
  stroke: string;
};

export const CustomizedMostPastDotOverall: FC<Props> = (props) => {
  const { cx, cy, stroke } = props;

  return (
    <svg x={cx - 17} y={cy - 17} width={34} height={34} fill="green">
      <circle
        stroke={stroke}
        strokeWidth="3"
        cx="17"
        cy="17"
        r="16"
        fill="#FFFFFF"
      />
    </svg>
  );
};
