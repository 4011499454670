import React, { memo } from "react";

import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";

export const EmployeeMaintenanceEditFormComplete = memo(() => {
  return (
    <>
      <Title src={EmployeeManagement}>社員詳細</Title>
      <ExplanatoryText>社員編集が完了しました</ExplanatoryText>
      <div className="border-t border-lightGray-100">
        <ReturnHomeButton path="/employees-management">
          社員管理へ戻る
        </ReturnHomeButton>
      </div>
    </>
  );
});
