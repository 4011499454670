import React from "react";
import { NavLink } from "react-router-dom";

import { useLoginUser } from "../../../hooks/useLoginUser";
import Logo from "../../../images/logo.png";
import { ManagerSidebarList } from "./ManagerSidebarList";
import { GeneralSidebarList } from "./GeneralSidebarList";
import { ReaderSidebarList } from "./ReaderSidebarList";

export const Sidebar = () => {
  const { loginUser } = useLoginUser();
  const userRoll = loginUser?.data.user_roll;

  const disableHander = (disable: boolean) => {
    switch (disable) {
      case true:
        return "flex items-center cursor-pointer pointer-events-none opacity-25";
      case false:
        return "flex items-center cursor-pointer";
    }
  };

  // ログインユーザーのroll別にサイドバーの配列をswitch
  const sidebarList = ((userRoll) => {
    switch (userRoll) {
      case "01":
        return ManagerSidebarList;
      case "02":
        return ReaderSidebarList;
      default:
        return GeneralSidebarList;
    }
  })(userRoll);

  return (
    <div className="sidebar box-border fixed justify-center overflow-auto shadow h-full min-w-[255px] p-5">
      <div className="sidebar-logo flex justify-center mb-5">
        <NavLink to="/">
          <img src={Logo} alt="logo.png" width={200} height={100} />
        </NavLink>
      </div>
      <ul className="sidebar-items">
        {sidebarList.map((nav, i) => (
          <li
            key={nav.id}
            className={
              ManagerSidebarList.length - 1 === i
                ? "sidebar-item flex py-5 border-y border-ligthGray:100 border-solid"
                : "sidebar-item flex py-5 border-t border-ligthGray:100 border-solid"
            }
          >
            <NavLink className={() => disableHander(nav.disable)} to={nav.path}>
              <img
                src={nav.iconImage}
                alt={nav.iconAlt}
                width={50}
                height={50}
              />
              <span className="pl-3 text-base font-bold leading-5">
                {nav.children}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
