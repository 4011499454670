import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import { AdminReaderUpdateRequestType } from "../types/api/settingsRoll";

// 管理者・閲覧者一覧取得
export const getAdminReaderList = () => {
  return api
    .get(`/merchant/maintenance/settings/employee_rolls`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 管理者・閲覧者への更新
export const putAdminReader = (data: AdminReaderUpdateRequestType) => {
  return api
    .put(`/merchant/maintenance/settings/employee_roll`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
