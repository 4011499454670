import React, { FC } from "react";

type Props = {
  x: number;
  y: number;
  stroke: string;
  payload: { value: number | string };
};

export const ScoreAxisTick: FC<Props> = (props) => {
  const { x, y, stroke, payload } = props;

  if (payload.value === 1) {
    return (
      <g transform={`translate(${x},${y})`}>
        <circle
          stroke={stroke}
          strokeWidth="3"
          cx="0"
          cy="1"
          r="13"
          fill="#C73333"
        />
        <text
          x={-1}
          y={0}
          dy={7}
          dx={4}
          textAnchor="end"
          className="text-md"
          fill="white"
        >
          {payload.value}
        </text>
        <text
          x={18}
          y={30}
          dy={7}
          dx={4}
          textAnchor="end"
          className="text-md"
          fill="#C73333"
        >
          要注意
        </text>
      </g>
    );
  } else if (payload.value === 5) {
    return (
      <g transform={`translate(${x},${y})`}>
        <circle
          stroke={stroke}
          strokeWidth="3"
          cx="0"
          cy="1"
          r="13"
          fill="#6CA893"
        />
        <text
          x={1}
          y={0}
          dy={7}
          dx={4}
          textAnchor="end"
          className="text-md"
          fill="white"
        >
          {payload.value}
        </text>
        <text
          x={12}
          y={30}
          dy={7}
          dx={4}
          textAnchor="end"
          className="text-md"
          fill="#6CA893"
        >
          良好
        </text>
      </g>
    );
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={1}
        y={0}
        dy={7}
        dx={4}
        textAnchor="end"
        className="text-md"
        fill="#3A3F3D"
      >
        {payload.value}
      </text>
    </g>
  );
};
