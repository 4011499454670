import React, { memo } from "react";
import {
  UseFormRegister,
  UseFormWatch,
  UseFormHandleSubmit,
} from "react-hook-form";

import { StressCheckSubjectEmployeeResponseType } from "../../../types/api/stressCheckManagement";

export const StressCheckSelectionListTable = memo(
  (props: {
    employeeList: {
      employee_id: number;
      name: string;
      office_id: number;
      office_name: string;
      selected: boolean;
      not_in_organization: boolean;
    };
    employeeId: number;
    name: string;
    officeId: number;
    officeName: string;
    handleCheckBoxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checkedValues: number[];
    departmentlists: [
      {
        department_id: number;
        name: string;
      }
    ];
    oficelists: [
      {
        office_id: number;
        name: string;
      }
    ];
    selected: boolean;
    register: UseFormRegister<StressCheckSubjectEmployeeResponseType>;
    watch: UseFormWatch<StressCheckSubjectEmployeeResponseType>;
    handleSubmit: UseFormHandleSubmit<StressCheckSubjectEmployeeResponseType>;
  }) => {
    const {
      employeeId,
      name,
      officeName,
      handleCheckBoxChange,
      checkedValues,
      departmentlists,
      oficelists,
      employeeList,
      register,
    } = props;

    return employeeList.not_in_organization === false ? (
      <div className="w-full border-b hover:bg-lightGreen-100 flex items-center ">
        <div className="flex items-center pl-[30px] w-1/3 py-9">
          <input
            type="checkbox"
            value={employeeId}
            onChange={handleCheckBoxChange}
            checked={checkedValues.includes(employeeId)}
            className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block"
          />
          <div className="pl-3">
            <span>{name}</span>
          </div>
        </div>
        <div>
          <span>{officeName}</span>
        </div>
      </div>
    ) : (
      <div className="w-full border-b hover:bg-lightGreen-100 text-lg py-9">
        <div className="flex items-center pl-[30px] pb-5">
          <input
            type="checkbox"
            value={employeeId}
            {...register("department_id")}
            onChange={handleCheckBoxChange}
            checked={checkedValues.includes(employeeId)}
            className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block"
          />
          <div className="pl-3">
            <span>{name}</span>
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex justify-between items-center pl-[30px]">
            <span className="w-[101px]">
              事業場<span className="text-red-500">*</span>
            </span>
            <select
              {...register("office_id")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            >
              <option value="">事業場を選んでください</option>
              {oficelists.map((office) => (
                <option key={office.office_id} value={office.office_id}>
                  {office.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between items-center pl-3">
            <span>
              部署<span className="text-red-500">*</span>
            </span>
            <select
              {...register("department_id")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            >
              <option value="">部署を選んでください</option>
              {departmentlists.map((department) => (
                <option
                  key={department.department_id}
                  value={department.department_id}
                >
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-between items-center pl-3">
            <span>
              性別<span className="text-red-500">*</span>
            </span>
            <select
              {...register("sex")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            >
              <option value="">性別を選んでください</option>
              <option value="0">男性</option>
              <option value="1">女性</option>
            </select>
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex justify-between items-center pl-[30px]">
            <span className="w-[101px]">役職</span>
            <input
              {...register("position")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            />
          </div>
          <div className="flex justify-between items-center pl-3">
            <span>職種</span>
            <input
              {...register("occupation")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            />
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex justify-between items-center pl-[30px]">
            <span className="w-[101px]">オプション1</span>
            <input
              {...register("option1")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            />
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex justify-between items-center pl-[30px]">
            <span className="w-[101px]">オプション2</span>
            <input
              {...register("option2")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            />
          </div>
        </div>
        <div className="flex py-5">
          <div className="flex justify-between items-center pl-[30px]">
            <span className="w-[101px]">オプション3</span>
            <input
              {...register("option3")}
              className="ml-4 border border-lightGreen-200 rounded-lg w-56 h-12 px-3 text-base"
            />
          </div>
        </div>
        <span className="pl-[30px] text-red-400 text-lg">
          ※
          受検開始日時点で入社されていなかったため、必要な情報を入力してください
        </span>
      </div>
    );
  }
);
