import React, { FC } from "react";
import { Link } from "react-router-dom";

import defaultProfileImage from "../../../images/default-profile.png";

type Props = {
  id: number;
  businessName: string;
  position: string;
  occupation: string;
  departmentName: string;
  profileImage: string;
};

export const EmployeeTable: FC<Props> = (props) => {
  const {
    id,
    businessName,
    position,
    occupation,
    departmentName,
    profileImage,
  } = props;

  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td>
          {profileImage ? (
            <img
              src={profileImage}
              alt="picture"
              className="max-w-16 max-h-16 rounded-full"
            />
          ) : (
            <img
              src={defaultProfileImage}
              alt="picture"
              className="max-w-16 max-h-16 rounded-full"
            />
          )}
        </td>
        <td className="text-lightGreen-200 truncate max-w-0">
          <span title={businessName} className="truncate">
            {businessName}
          </span>
        </td>

        <td className="truncate max-w-0">
          <span title={departmentName}>{departmentName}</span>
        </td>
        <td className="truncate max-w-0">
          <span title={position} className="truncate">
            {position === "" ? "未登録" : position}
          </span>
        </td>
        <td className="truncate max-w-0">
          <span title={occupation} className="truncate">
            {occupation === "" ? "未登録" : occupation}
          </span>
        </td>
        <td className="flex items-center justify-end mt-6">
          <Link
            target="_blank"
            to={`../detail/` + `${id}`}
            className="py-1 px-5 text-white rounded-lg focus:outline-none border-lightGreen-200 border"
          >
            <span className="text-lg font-w6 leading-relaxed text-lightGreen-200">
              詳細
            </span>
          </Link>
        </td>
      </tr>
    </tbody>
  );
};
