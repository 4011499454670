import React, { FC, memo } from "react";

import ExcellentIcon from "../../../../images/excellent-2.png";
import GoodIcon from "../../../../images/good-2.png";
import UsuallyIcon from "../../../../images/usually-2.png";
import BadIcon from "../../../../images/bad-2.png";
import WorstIcon from "../../../../images/worst-2.png";
import { CompanyReportSections } from "../../../../types/api/stressCheckManagement";

type Props = {
  num_of_grades: number;
  thresholds: number[][];
  sections: {
    name: string;
    score: number;
    comment: string;
    scales: {
      name: string;
      score: number;
    }[];
  }[];
};

export const CategoryEcaluation: FC<Props> = memo((props) => {
  const { num_of_grades, thresholds, sections } = props;

  const faceIconHandleChange = (evaluate: number, thresholds: number[][]) => {
    if (thresholds[0][0] <= evaluate && evaluate <= thresholds[0][1]) {
      return WorstIcon;
    } else if (thresholds[1][0] <= evaluate && evaluate <= thresholds[1][1]) {
      return BadIcon;
    } else if (thresholds[2][0] <= evaluate && evaluate <= thresholds[2][1]) {
      return UsuallyIcon;
    } else if (thresholds[3][0] <= evaluate && evaluate <= thresholds[3][1]) {
      return GoodIcon;
    } else if (thresholds[4][0] <= evaluate && evaluate <= thresholds[4][1]) {
      return ExcellentIcon;
    } else {
      return ExcellentIcon;
    }
  };

  const stressRatingColorHandler = (evaluate: number) => {
    if (evaluate <= thresholds[0][1]) {
      return "text-lightRed-100";
    } else if (evaluate <= thresholds[1][1]) {
      return "text-lightViolet-100";
    } else {
      return "";
    }
  };

  const seciontComments = (sections: CompanyReportSections[]) => {
    let appendComments = "";
    for (let index = 0; index < sections.length; index++) {
      const comment = sections[index].comment;
      appendComments = appendComments + comment;
    }

    return appendComments;
  };

  return (
    <>
      <div className="mt-[30px] pb-[40px] pt-[30px] border-t border-lightGray-100">
        <div>
          <span className="font-bold text-[24px] text-lightGreen-200">
            各カテゴリの評価
          </span>
        </div>
        <div className="pt-[20px] pb-[40px]">
          <div className="flex justify-between">
            {sections.map((section, index) => (
              <div key={index}>
                <div className="flex flex-col justify-center items-center self-start min-w-[300px]">
                  <div className="max-w-[170px]">
                    <span className="font-bold text-lg text-lightGreen-200">
                      {section.name}
                    </span>
                  </div>
                  <div className="mt-[5px] mb-[10px] flex items-center">
                    <img
                      src={faceIconHandleChange(section.score, thresholds)}
                      alt="face-icon.png"
                      width={60}
                      height={60}
                    />
                    <div className="ml-[20px]">
                      <span className="text-[50px] font-bold">
                        {section.score}
                      </span>
                      <span className="pl-[10px] text-[30px] font-bold">/</span>
                      <span className="pl-[5px] text-[30px] font-bold">
                        {num_of_grades}
                      </span>
                    </div>
                  </div>
                  {section.scales.map((scale, index) => (
                    <div
                      key={index}
                      className={
                        (index + 1) % 2 === 0
                          ? "w-full min-h-[45px] px-[10px] flex items-center justify-between"
                          : "w-full min-h-[45px] px-[10px] bg-lightGray-50 flex items-center justify-between"
                      }
                    >
                      <div className="w-full break-all">
                        <span className="text-lg">{scale.name} </span>
                      </div>
                      <div>
                        <span
                          className={
                            "text-lg " + stressRatingColorHandler(scale.score)
                          }
                        >
                          {scale.score}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                {sections.length - 1 === index ? null : (
                  <div className="self-stretch border-r border-lightGray-100 mx-[10px]"></div>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-end mt-[20px]">
            <div className="flex items-center justify-center">
              <div className="bg-lightRed-100 min-w-[72px] min-h-[26px] text-center">
                <span className="font-bold text-[14px] text-white">注意</span>
              </div>
              <span className="pl-[10px] text-lightRed-100 font-bold text-[14px]">
                ~ {thresholds[0][1]}
              </span>
            </div>
            <div className="flex items-center justify-center ml-[30px]">
              <div className="bg-lightViolet-100 min-w-[72px] min-h-[26px] text-center">
                <span className="font-bold text-[14px] text-white">
                  やや注意
                </span>
              </div>
              <span className="pl-[10px] text-lightViolet-100 font-bold text-[14px]">
                ~ {thresholds[1][1]}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center rounded-lg bg-lightGreen-100 px-[65px] py-[30px]">
          <span className="font-bold text-lg text-lightGreen-200 min-w-[30px]">
            解説
          </span>
          <div className="max-w-[850px] min-h-[100px] pl-[65px] flex justify-center items-center">
            <span>{seciontComments(sections)}</span>
          </div>
        </div>
      </div>
    </>
  );
});
