import React, { memo } from "react";
import { useFetchStressCheckManagement } from "../../../hooks/stressCheckManagements/useFetchStressCheckManagement";

import { StressCheckToEmployeesManagementList } from "../../templates/stressCheckMaintenance/StressCheckToEmployeesMaintenanceList";

export const StressCheckToEmployeesManagement = memo(() => {
  const { stressCheckManagement } = useFetchStressCheckManagement();

  return (
    <>
      <StressCheckToEmployeesManagementList
        stressCheckManagement={stressCheckManagement}
      />
    </>
  );
});
