import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  PasswordChangeRequestType,
  PasswordResetRequestType,
  UserLoginResponseType,
  UserRequestType,
} from "../types/api/user";

// ログイン情報の有効の有無を確認する関数
export const postAuthCheckApi = () => {
  return api
    .post<UserLoginResponseType>("merchant/auth/check")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ログインするための関数
export const postAuthLoginApi = (data: UserRequestType) => {
  return api
    .post<UserLoginResponseType>("/merchant/auth/login", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ログアウトするための関数
export const postAuthLogoutApi = () => {
  return api
    .post("merchant/auth/logout")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// パスワードを変更するための関数
export const postPasswordChangeApi = (data: PasswordChangeRequestType) => {
  return api
    .post("/merchant/auth", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// パスワードをリセットするための関数
export const postPasswordResetApi = (data: PasswordResetRequestType) => {
  return api
    .post("/merchant/auth/reset", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
