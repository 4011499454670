import { useCallback, useEffect, useState } from "react";

import { getStressCheckList } from "../../api/apiStressCheck";
import { StressCheckListResponseType } from "../../types/api/stressCheck";

export const useFetchStressCheckList = () => {
  const [stressCheckList, setStressCheckList] = useState<
    StressCheckListResponseType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchStressCheckList = useCallback(async () => {
    const res = await getStressCheckList();

    if (res.status !== 200) {
      setIsLoading(false);
      return alert("ストレスチェックの一覧データ取得に失敗しました");
    }

    setStressCheckList(res.data.data.stress_check_frames);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchStressCheckList();
  }, []);

  return {
    isLoading,
    stressCheckList,
  };
};
