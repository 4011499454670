import React, { memo } from "react";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import { ComprehensiveEvaluationForm } from "../../atoms/stressCheckManagements/analytics/ComprehensiveEvaluationForm";
import { AnalyticsExplanation } from "../../atoms/stressCheckManagements/analytics/AnalyticsExplanation";
import { CategoryEcaluation } from "../../atoms/stressCheckManagements/analytics/CategoryEcaluation";
import { useFetchStressCheckReport } from "../../../hooks/stressCheckManagements/useFetchStressCheckReport";
import { ImprovementAdvice } from "../../atoms/stressCheckManagements/analytics/ImprovementAdvice";

export const CompanyAnalyticsForm = memo(() => {
  const { companyReport } = useFetchStressCheckReport();

  return (
    <>
      {companyReport && (
        <>
          <div className="flex items-center justify-between">
            <Title src={StressCheckManagement}>全社分析レポート</Title>
            <div className="mt-[60px] pr-[40px]">
              <div className="flex items-center">
                <span className="font-bold text-lg text-lightGreen-200">
                  実施期間
                </span>
                <span className="pl-[16px]">
                  {companyReport.start_date} ~ {companyReport.end_date}
                </span>
              </div>
            </div>
          </div>
          <ComprehensiveEvaluationForm
            num_of_grades={companyReport.num_of_grades}
            thresholds={companyReport.thresholds}
            overall_score={companyReport.overall_score}
            overall_comment={companyReport.overall_comment}
            stress_check_info={companyReport.stress_check_info}
          />
          <ImprovementAdvice advice={companyReport.advice} />
          <CategoryEcaluation
            num_of_grades={companyReport.num_of_grades}
            thresholds={companyReport.thresholds}
            sections={companyReport.sections}
          />
          <AnalyticsExplanation />
        </>
      )}
    </>
  );
});
