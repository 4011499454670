import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import combinationEmployeeDepartmentList from "../../utils/department";

import {
  getEmployeeProfileApi,
  putEmployeeProfileEdit,
} from "../../api/apiEmployee";
import {
  EmployeeProfileDetailType,
  EmployeeProfileEditFromType,
} from "../../types/api/employee";

export const useEmployee = () => {
  const [isListTable, setIsListTable] = useState(true);
  const screenSwitchHandle = () => {
    isListTable ? setIsListTable(false) : setIsListTable(true);
  };
  const navigate = useNavigate();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [detailEmployee, setDetailEmployee] =
    useState<EmployeeProfileDetailType>({
      employee_id: 0,
      employee_no: "",
      office_name: "",
      position: "",
      last_name: "",
      first_name: "",
      business_last_name: "",
      business_first_name_kana: "",
      business_last_name_kana: "",
      business_first_name: "",
      email_address: "",
      phone_number: "",
      profile_image: "",
      word: "",
      self_introduction: "",
      interest_content_1: "",
      interest_image_1: "",
      interest_content_2: "",
      interest_image_2: "",
      card_color: "",
      private_email_address: "",
      private_phone_number: "",
      extention_number: "",
      occupation: "",
      department_name: "",
      departments: [
        {
          department_id: 0,
          name: "",
          responsibility_type: 0,
          concurrent: false,
        },
      ],
      department_histories: [
        {
          department_id: 0,
          name: "",
          assign_start_date: "",
          assign_end_date: "",
        },
      ],
    });

  // 社員自己紹介ページ値の取得
  const fetchDetailEmployeeProfile = useCallback(
    async (employeeId: string | undefined) => {
      const resEmployee = await getEmployeeProfileApi(employeeId);
      const employee = resEmployee.data.data;
      const employeeDepartmentList = combinationEmployeeDepartmentList(
        employee.departments
      );
      setDetailEmployee(employee);
      setDetailEmployee((state) => {
        return {
          ...state,
          department_name: employeeDepartmentList,
        };
      });
    },
    []
  );

  // 社員自己紹介編集ページの値を取得
  const fetchDetailEmployeeProfileEdit = useCallback(
    async (
      employeeId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const resEmployee = await getEmployeeProfileApi(employeeId);
      const employee = resEmployee.data.data;
      const employeeDepartmentList = combinationEmployeeDepartmentList(
        employee.departments
      );
      setDetailEmployee(employee);
      setDetailEmployee((state) => {
        return {
          ...state,
          department_name: employeeDepartmentList,
        };
      });
      setLoading(false);
    },
    []
  );

  // 社員自己紹介データを更新
  const updateEmployeeProfile = useCallback(
    async (
      data: EmployeeProfileEditFromType,
      employeeId: string | undefined
    ) => {
      const res = await putEmployeeProfileEdit(data, employeeId);
      if (res.status !== 201) {
        return alert("社員編集に失敗しました");
      }
      setIsComplete(true);
      navigate(`/employees/detail/${employeeId}`);
    },
    []
  );

  return {
    isListTable,
    screenSwitchHandle,
    detailEmployee,
    isComplete,
    updateEmployeeProfile,
    fetchDetailEmployeeProfile,
    fetchDetailEmployeeProfileEdit,
  };
};
