import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getInterviewGuidance,
  postInterviewGuidance,
} from "../../api/apiStressCheckManagement";
import { InterviewGuidanceFormType } from "../../types/api/stressCheckManagement";

export const useInterviewGuidance = () => {
  const navigate = useNavigate();

  const [interviewGuidance, setInterviewGuidance] =
    useState<InterviewGuidanceFormType>({
      sentence_for_target: "",
      sentence_for_not_target: "",
    });

  const fetchInterviewGuidance = useCallback(
    async (
      stressCheckId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await getInterviewGuidance(stressCheckId);

      if (res.status !== 200) {
        alert("ストレスチェック対象者の一覧データ取得に失敗しました");
        return navigate(`/stress-checks-management/${stressCheckId}`);
      }

      setInterviewGuidance(res.data.data);
      setLoading(false);
    },
    []
  );

  const saveInterviewGuidance = useCallback(
    async (
      stressCheckId: string | undefined,
      data: InterviewGuidanceFormType
    ) => {
      const res = await postInterviewGuidance(data, stressCheckId);

      if (res.status !== 200) {
        return alert("面談指導要否文の更新に失敗しました");
      }

      alert("面談指導要否文を更新しました");
      navigate(`/stress-checks-management/${stressCheckId}`);
    },
    []
  );

  return {
    fetchInterviewGuidance,
    saveInterviewGuidance,
    interviewGuidance,
    setInterviewGuidance,
  };
};
