import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getStressCheckManagementList } from "../../api/apiStressCheckManagement";
import { LoginUserContext } from "../../components/providers/LoginUserProvider";
import { StressCheckManagementListResponseType } from "../../types/api/stressCheckManagement";

export const useFetchStressCheckManagementList = () => {
  const [stressCheckManagementList, setStressCheckManagementList] = useState<
    StressCheckManagementListResponseType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const user = useContext(LoginUserContext);

  const onClickHandler = (stressCheckId: number) => {
    // 管理者権限者と閲覧権限者でURLを分ける
    navigate(`/stress-checks-management/${stressCheckId}`);
  };

  const fetchStressCheckManagementList = useCallback(async () => {
    const res = await getStressCheckManagementList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("ストレスチェック管理一覧のデータ取得に失敗しました");
    }
    setIsLoading(false);
    setStressCheckManagementList(res.data.data.stress_check_frames);
  }, []);

  useEffect(() => {
    fetchStressCheckManagementList();
  }, []);

  return {
    isLoading,
    onClickHandler,
    stressCheckManagementList,
  };
};
