import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DepartmentPastTree } from "../../templates/histories/DepartmentPastTree";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { useFetchHistoryDepartmentTree } from "../../../hooks/histories/useFetchHistoryDepartmentTree";
import { useFetchOrganizationHistoryList } from "../../../hooks/histories/useFetchOrganizationHistoryList";

export const DepartmentPastTrees = () => {
  const { isLoading } = useFetchOrganizationHistoryList();
  const params = useParams();
  const pastHistoryId = params.id;
  const [loading, setLoading] = useState(true);
  const { pastHistoryList, fetchPastOrganizationHistoryList } =
    useFetchHistoryDepartmentTree();

  const DepartmentPastObject = {
    pastHistoryId,
    loading,
    setLoading,
    pastHistoryList,
    fetchPastOrganizationHistoryList,
  };

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <DepartmentPastTree DepartmentPastObject={DepartmentPastObject} />
      )}
    </>
  );
};
