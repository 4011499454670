import React, { memo } from "react";

import { Title } from "../../atoms/commons/Title";
import EmploymentRetirement from "../../../images/employment-retirement.png";
import { NavigateButton } from "../../atoms/commons/NavigateButton";
import {
  EmployeeTaskResponseType,
  updateEmployeeTaskResponseType,
} from "../../../types/api/EmployeeTask";

type Props = {
  employeeTaskObj: {
    isListTable: boolean;
    screenSwitchHandle: () => void;
    isLoading: boolean;
    employeeTaskList: EmployeeTaskResponseType;
    checkTaskBoolean: boolean;
    checkTask: boolean;
    setcheckTaskBoolean: React.Dispatch<React.SetStateAction<boolean>>;
    updateEmployeeTaskList: (
      data: updateEmployeeTaskResponseType
    ) => Promise<void>;
    updateEmployeeTask: (data: updateEmployeeTaskResponseType) => Promise<void>;
  };
};

export const EmployeeEmploymentTask = memo(({ employeeTaskObj }: Props) => {
  return (
    <div>
      <div className="flex content-center justify-between">
        <div className="flex">
          <img className="h-16 mt-14" src={EmploymentRetirement} />
          <span className="text-3xl font-bold leading-relaxed text-lightGreen-200 block mt-16 ml-4">
            入退職処理
          </span>
        </div>
        <div className="flex">
          <NavigateButton
            navigatePath="/employment-retirement-tasks/tasklist"
            buttonClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg font-bold"
          >
            タスク一覧
          </NavigateButton>
        </div>
      </div>
      <span className="w-full py-11 text-sm inline-block border-b">
        入退職のタスクを左右のスクロールでご確認ください。
      </span>
      <div className="w-full py-9 text-xl border-b pl-5">
        <div className="flex font-bold text-lightGreen-200">
          <div className="mr-6 px-8 py-3 bg-lightGreen-100 rounded-lg">
            入社処理
          </div>
          <span className="border-r-2"></span>
          <button
            onClick={() => employeeTaskObj.screenSwitchHandle()}
            className="ml-6 px-8 py-3"
          >
            退職処理
          </button>
        </div>
      </div>
      <div className="py-13 overflow-x-scroll w-full">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className="w-80 pb-10 px-5 text-lightGreen-200">名前</th>
              {employeeTaskObj.employeeTaskList.employment.tasks.map((task) => {
                return (
                  <th
                    key={task.id}
                    className="w-80 pb-10 px-5 text-lightGreen-200"
                  >
                    {task.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {employeeTaskObj.employeeTaskList.employment.employees.map(
              (task) => {
                return (
                  <tr key={task.id} className="odd:bg-lightGray-50">
                    <td className="w-80 text-center py-3 px-5 text-lg">
                      {task.last_name}
                      {task.first_name}
                    </td>
                    {task.task_status.map((check) => {
                      return check.completed == true ? (
                        <td
                          key={check.task_id}
                          className="w-80 text-center py-3 px-5"
                        >
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            onClick={() => {
                              const setEmployeeTask = {
                                employee_id: task.id,
                                task_id: check.task_id,
                                completed: employeeTaskObj.checkTaskBoolean,
                              };
                              employeeTaskObj.updateEmployeeTaskList(
                                setEmployeeTask
                              );
                            }}
                            className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block border-lightGreen-200"
                          />
                        </td>
                      ) : (
                        <td
                          key={check.task_id}
                          className="w-80 text-center py-3 px-5"
                        >
                          <input
                            type="checkbox"
                            onClick={() => {
                              const setEmployeeTask = {
                                employee_id: task.id,
                                task_id: check.task_id,
                                completed: employeeTaskObj.checkTask,
                              };
                              employeeTaskObj.updateEmployeeTask(
                                setEmployeeTask
                              );
                            }}
                            className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block border-lightGreen-200"
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});
