import React, { memo } from "react";

import { useStressCheckSelection } from "../../../hooks/stressCheckManagements/useStressCheckSubjectEmployees";
import { StressCheckSelectionList } from "../../templates/stressCheckMaintenance/StressCheckSubjectSelectionList";

export const StressCheckSelection = memo(() => {
  const stressCheckSelectionObj = useStressCheckSelection();
  return (
    <>
      <StressCheckSelectionList
        stressCheckSelectionObj={stressCheckSelectionObj}
      />
    </>
  );
});
