import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import MailIcon from "../../../images/mail.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { SendMailButton } from "../../atoms/stressCheckManagements/SendMailButton";
import { useFetchSendMailFlagStatus } from "../../../hooks/stressCheckManagements/useFetchSendMailFlagStatus";

export const SendMailList = () => {
  const navigate = useNavigate();
  const { stressCheckId } = useParams();

  const { sendMailStatus } = useFetchSendMailFlagStatus();

  const buttonList = [
    {
      children: "ガイダンスメール",
      buttonFlag: sendMailStatus?.sendable_guidance_and_remind_mail,
    },
    {
      children: "リマインドメール",
      buttonFlag: sendMailStatus?.sendable_guidance_and_remind_mail,
    },
    {
      children: "面談対象者メール",
      buttonFlag: sendMailStatus?.sendable_interview_notify_mail,
    },
  ];

  const onClickNavButton = (mailType: string) => {
    switch (mailType) {
      case "ガイダンスメール":
        navigate(
          `/stress-checks-management/${stressCheckId}/sendmail/${"guidance"}`
        );
        break;
      case "リマインドメール":
        navigate(
          `/stress-checks-management/${stressCheckId}/sendmail/${"reminde"}`
        );
        break;
      case "面談対象者メール":
        navigate(
          `/stress-checks-management/${stressCheckId}/sendmail/${"interview-notify"}`
        );
        break;
    }
  };

  return (
    <>
      <Title src={MailIcon}>メール送信</Title>
      <ExplanatoryText>送信するメールを選択してください。</ExplanatoryText>
      <div className="pb-2">
        <span className="font-bold text-sm text-red-600">
          ※メールは再送信されません。
        </span>
      </div>
      <div className="flex flex-col gap-[40px] justify-center items-center border-t border-lightGray-100 py-[80px]">
        {buttonList.map((button) => (
          <div key={button.children}>
            <SendMailButton
              onClickNavButton={onClickNavButton}
              buttonFlag={button.buttonFlag}
            >
              {button.children}
            </SendMailButton>
          </div>
        ))}
      </div>
    </>
  );
};
