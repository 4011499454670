import React, { FC, memo } from "react";

type Props = {
  stressCheckId: number;
  name: string;
  examination_date: string;
  handleCheckBoxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkedValues: number[];
};

export const InterviewSubjectSelectionListTable: FC<Props> = memo((props) => {
  const {
    stressCheckId,
    name,
    examination_date,
    handleCheckBoxChange,
    checkedValues,
  } = props;
  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="pl-[30px] overflow-ellipsis truncate max-w-0">
          <input
            type="checkbox"
            value={stressCheckId}
            onChange={handleCheckBoxChange}
            checked={checkedValues.includes(stressCheckId)}
            className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block"
          />
        </td>
        <td className="overflow-ellipsis truncate max-w-0">
          <span>{name}</span>
        </td>
        <td className="overflow-ellipsis truncate max-w-0">
          <span>{examination_date}</span>
        </td>
        {/********************************************************
         * TODO: 4月以降に詳細ボタンを追加する場合は下記のコメントアウトを
         * 外して使用すればOK
         *******************************************************/}
        {/* <td className="mt-6 flex justify-end">
            <button className="h-10 w-20 text-sm text-white rounded focus:outline-none border-lightGreen-200 border">
              <p className="text-lg font-w6 leading-relaxed text-lightGreen-200">
                詳細
              </p>
            </button>
          </td> */}
      </tr>
    </tbody>
  );
});
