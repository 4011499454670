import React, { memo } from "react";

import { EmployeeEmploymentTask } from "../../templates/employmentRetirementTasks/EmployeeEmploymentTask";
import { EmployeeRetirementTask } from "../../templates/employmentRetirementTasks/EmployeeRetirementTask";
import { useEmployeeTaskList } from "../../../hooks/employmentRetirementEmployeeTask/useFetchEmployeeTaskList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

export const EmployeeTasks = memo(() => {
  const employeeTaskObj = useEmployeeTaskList();

  return (
    <>
      {employeeTaskObj.isLoading ? (
        <LoadingTemplate />
      ) : employeeTaskObj.isListTable ? (
        <EmployeeEmploymentTask employeeTaskObj={employeeTaskObj} />
      ) : (
        <EmployeeRetirementTask employeeTaskObj={employeeTaskObj} />
      )}
    </>
  );
});
