import React, { memo } from "react";

export const EmployeeRollTable = memo(
  (props: { name: string; emailAddress: string }) => {
    const { name, emailAddress } = props;

    return (
      <tbody>
        <tr className="border-b hover:bg-lightGreen-100 h-24">
          <td className="text-lightGreen-200 truncate max-w-0">
            <span title={name} className="truncate">
              {name}
            </span>
          </td>

          <td className="truncate max-w-0">
            <span title={emailAddress}>{emailAddress}</span>
          </td>
        </tr>
      </tbody>
    );
  }
);
