import React, { memo, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import MailIcon from "../../../images/mail.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { FormBottons } from "../../atoms/commons/FormBottons";
import { useSendMail } from "../../../hooks/stressCheckManagements/useSendMail";

export const SendMailConfirmForm = memo(() => {
  const getParams = useParams();
  const mailType = getParams.mailType;

  const {
    fetchGuidanceMail,
    fetchRemindMail,
    fetchInterviewNotifyMail,
    sendMail,
    sendGuidanceMail,
    sendRemindMail,
    sendInterviewNotifyMail,
    backButtonHandler,
  } = useSendMail();

  switch (mailType) {
    case "guidance":
      useEffect(() => {
        const fetch = async () => {
          fetchGuidanceMail();
        };
        fetch();
      }, []);
      break;
    case "reminde":
      useEffect(() => {
        const fetch = async () => {
          fetchRemindMail();
        };
        fetch();
      }, []);
      break;
    case "interview-notify":
      useEffect(() => {
        const fetch = async () => {
          fetchInterviewNotifyMail();
        };
        fetch();
      }, []);
      break;
  }

  const titleHandler = () => {
    switch (mailType) {
      case "guidance":
        return "ガイダンスメール";
      case "reminde":
        return "リマインドメール";
        break;
      case "interview-notify":
        return "面談対象者メール";
        break;
    }
  };

  const onClickHandler = () => {
    if (!window.confirm("本当に送信しても宜しいですか？")) {
      return;
    }
    switch (mailType) {
      case "guidance":
        sendGuidanceMail();
        break;
      case "reminde":
        sendRemindMail();
        break;
      case "interview-notify":
        sendInterviewNotifyMail();
        break;
    }
  };

  return (
    <>
      <Title src={MailIcon}>{titleHandler()}送信内容確認</Title>
      <ExplanatoryText>
        メールの入力内容を確認し、送信ボタンを押してください。
      </ExplanatoryText>
      <div className="flex flex-col mt-[10px] py-[10px]">
        <span className="font-bold text-lg">メールの送信対象者について</span>
        <span>
          ストレスチェック対象者のうち、メールアドレスが登録されている方全員に送信されます。
        </span>
      </div>
      <div className="flex flex-col py-[30px]">
        <span className="font-bold text-lg">本文内の問い合わせ先について</span>
        <span>
          <Link
            to={"/contacts"}
            className="text-lightGreen-200 hover:text-lightGreen-300"
          >
            こちら
          </Link>
          から編集可能です。
        </span>
      </div>
      <div className="flex flex-col">
        <div className="flex py-[30px] border-y border-lightGray-100">
          <p className="font-bold text-lg text-lightGreen-200">件名</p>
          <p className="ml-[160px] w-[510px]">{sendMail.subject}</p>
        </div>
        <div className="flex py-[30px]">
          <p className="font-bold text-lg text-lightGreen-200">本文</p>
          <textarea
            name="content"
            value={sendMail.content}
            disabled={true}
            className="ml-[160px] w-9/12 min-h-[600px] bg-white resize-none"
          />
        </div>
        <div className="py-5 border-t flex items-center"></div>
        <FormBottons
          firstButtonOnClickIvent={() => backButtonHandler()}
          seccondButtonOnClickIvent={() => onClickHandler()}
          frameClassName="mt-10	pb-20 text-center"
          firstButtonClassName="inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200"
          seccondButtonClassName="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
          firstButtonChildren="戻る"
          seccondButtonChildren="送信"
        />
      </div>
    </>
  );
});
