import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import backIcon from "../../../images/back-icon.png";
import { useSaveDepartment } from "../../../hooks/department/useSaveDepartment";
import { OrganizationTrees } from "../../../types/api/department";
import { FormLabel } from "../commons/FormLabel";

export const HistoryModalCreateForm = memo(
  (props: { onChangeModalFlag: () => void }) => {
    const [isChecked, setIsChecked] = useState(false);
    const { onChangeModalFlag } = props;
    const { saveDepartmentHistory } = useSaveDepartment();
    const {
      formState: { errors },
    } = useForm<OrganizationTrees>();
    const submit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      saveDepartmentHistory();
      onChangeModalFlag();
    };
    // 日付を取得
    const getTodayYear = new Date().getFullYear();
    const getTodayMonth = new Date().getMonth() + 1;
    // チェックされるとbuttonが押下できる様にする
    const [CheckBoolean, setCheckBoolean] = useState<boolean>(false);
    const ClickCheck = () => {
      setCheckBoolean(!CheckBoolean);
    };
    useEffect(() => {
      if (CheckBoolean === true) {
        setIsChecked(false);
      } else {
        setIsChecked(true);
      }
    }, [ClickCheck]);

    return (
      <>
        <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
          <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
            組織図保存
          </p>
          <button
            onClick={onChangeModalFlag}
            className="absolute top-5 right-5"
          >
            <img src={backIcon} alt="back-icon" />
          </button>
          <form onSubmit={submit}>
            <div className="w-full flex flex-col justify-center items-center border-t border-lightGray-100">
              <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
                <FormLabel
                  title="名称"
                  className="text-lightGreen-200 font-semibold mr-10"
                />
                {":"}
                <span className="ml-10 w-[400px] pl-5 text-sm">
                  {getTodayYear}年{getTodayMonth}月
                </span>
              </div>
            </div>
            <p className="mt-5">
              {getTodayYear}年{getTodayMonth}月時点の組織図が保存されます。
            </p>
            <p className="text-red-600">
              ※既に保存されている場合は上書きとなります。
            </p>
            <div className="flex items-center mt-5">
              <input
                type="checkbox"
                onChange={ClickCheck}
                className="w-6 h-6 default:ring-2 accent-white text-lightGreen-200 border rounded-lg"
              />
              <label className="pl-3 font-bold">
                1度保存すると、削除できませんのでご注意ください。
              </label>
            </div>
            <div className="text-center">
              <button
                type="submit"
                disabled={isChecked}
                className="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mt-7 hover:bg-lightGreen-200 hover:text-white text-lightGreen-200 text-lg disabled:text-white disabled:bg-slate-300 disabled:border-slate-300"
              >
                保存
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }
);
