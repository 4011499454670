import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { putAdminReader } from "../../api/apiSettings";
import {
  AdminReaderUpdateFormType,
  AdminReaderUpdateRequestType,
} from "../../types/api/settingsRoll";

export const useUpdateAdminReader = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AdminReaderUpdateFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      emailAddress: "",
      roll: "",
    },
  });

  const updateAdminReader = useCallback(
    async (data: AdminReaderUpdateRequestType) => {
      const res = await putAdminReader(data);
      if (res.status !== 200) {
        switch (res.data.code) {
          case "TARGET_NOT_FOUND":
            alert("対象従業員が存在しませんでした");
            break;
          default:
            alert("対象従業員の権限更新に失敗しました");
            break;
        }
        return;
      }
      alert("対象従業員の権限更新に成功しました");
      navigate(`/settings`);
    },
    []
  );

  const onSubmit: SubmitHandler<AdminReaderUpdateFormType> = (data) => {
    const requestData: AdminReaderUpdateRequestType = {
      email_address: data.emailAddress,
      roll: data.roll,
    };
    updateAdminReader(requestData);
  };

  return {
    register,
    handleSubmit,
    watch,
    onSubmit,
    errors,
  };
};
