import React, { FC, memo } from "react";
import { Link } from "react-router-dom";

type Props = {
  name: string;
  officeId: string;
};

export const OfficeListTable: FC<Props> = memo((props) => {
  const { name, officeId } = props;
  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="text-lightGreen-200 overflow-ellipsis truncate max-w-0">
          {name}
        </td>
        <td className="mt-6 flex justify-end">
          <Link to={`/offices/detail/${officeId}`}>
            <button className="py-1 px-5 text-sm text-white rounded-lg focus:outline-none border-lightGreen-200 border">
              <p className="text-lg font-w6 leading-relaxed text-lightGreen-200">
                詳細
              </p>
            </button>
          </Link>
        </td>
      </tr>
    </tbody>
  );
});
