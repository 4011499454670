import { Dispatch, SetStateAction, useCallback, useState } from "react";

import {
  getMaintenanceDetailEmployee,
  postMaintenanceEmployee,
  postMaintenanceMerchantUser,
  putMaintenanceEmployee,
} from "../../api/apiEmployeeMaintenance";
import {
  DepartmentFormType,
  DepartmentType,
  DetailEmployeeMaintenanceType,
  EmployeeMaintenanceFormType,
  EmployeeMaintenanceRequestType,
} from "../../types/api/employeeMaintenance";

export const useEmployeeMaintenance = () => {
  const [loading, setLoading] = useState(true);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [detailEmployee, setDetailEmployee] =
    useState<EmployeeMaintenanceFormType>({
      employeeNo: "",
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      businessFirstName: "",
      businessLastName: "",
      businessFirstNameKana: "",
      businessLastNameKana: "",
      position: "",
      occupation: "",
      sex: "",
      year: "",
      month: "",
      day: "",
      emailAddress: "",
      phoneNumber: "",
      postcode: "",
      address: "",
      officeId: "",
      departmentList: [],
    });

  // 社員データを登録
  const saveEmployee = useCallback(
    async (data: EmployeeMaintenanceRequestType) => {
      const res = await postMaintenanceEmployee(data);

      if (res.status !== 201) {
        return alert("登録に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  // 社員データのアカウント発行
  const AccountIssuance = useCallback(
    async (
      employeeId: string,
      setAccountStatus: Dispatch<SetStateAction<string>>
    ) => {
      const res = await postMaintenanceMerchantUser(employeeId);

      if (res.status !== 201) {
        return alert("アカウント発行に失敗しました。");
      }
      alert("アカウント発行が完了しました。");
      setAccountStatus("1");
    },
    []
  );

  // 社員詳細データを取得
  const fetchDetailEmployee = useCallback(
    async (employeeId: string | undefined) => {
      const res = await getMaintenanceDetailEmployee(employeeId);
      if (res.status !== 200) {
        return alert("利用会社の詳細データ取得に失敗しました。");
      }

      const resEmployee: DetailEmployeeMaintenanceType = res.data;
      // detailEmployee.birthday（例: "1991/1/1"）に格納されている/の記号をなくして、それぞれの年月日を配列(例: ["1991", "1", "1"])に格納する
      const resBirthday = resEmployee.data.birthday.split("/");
      const resDepartments: DepartmentFormType = resEmployee.data.departments
        .sort((a, b) => {
          if (a.concurrent === true && b.concurrent === false) {
            return 1;
          } else {
            return -1;
          }
        })
        .map((resD) => {
          return {
            value: String(resD.department_id),
            responsibilityType: !!Number(resD.responsibility_type),
          };
        });

      setDetailEmployee({
        employeeNo: resEmployee.data.employee_no,
        firstName: resEmployee.data.first_name,
        lastName: resEmployee.data.last_name,
        firstNameKana: resEmployee.data.first_name_kana,
        lastNameKana: resEmployee.data.last_name_kana,
        businessFirstName: resEmployee.data.business_first_name,
        businessLastName: resEmployee.data.business_last_name,
        businessFirstNameKana: resEmployee.data.business_first_name_kana,
        businessLastNameKana: resEmployee.data.business_last_name_kana,
        position: resEmployee.data.position,
        occupation: resEmployee.data.occupation,
        sex: resEmployee.data.sex,
        year: resBirthday[0],
        month: resBirthday[1],
        day: resBirthday[2],
        emailAddress: resEmployee.data.email_address,
        phoneNumber: resEmployee.data.phone_number,
        postcode: resEmployee.data.postcode,
        address: resEmployee.data.address,
        officeId: String(resEmployee.data.office_id),
        departmentList: resDepartments,
      });
      setLoading(false);
    },
    []
  );

  // 社員詳細データを更新
  const updateEmployee = useCallback(
    async (
      data: EmployeeMaintenanceRequestType,
      employeeId: string | undefined
    ) => {
      const res = await putMaintenanceEmployee(data, employeeId);
      if (res.status !== 200) {
        return alert("社員編集に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  return {
    saveEmployee,
    isComplete,
    AccountIssuance,
    detailEmployee,
    fetchDetailEmployee,
    updateEmployee,
    loading,
  };
};
