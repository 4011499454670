import React from "react";

import { StressCheckResult } from "../../templates/stressChecks/StressCheckResult";

export const StressChecksResult = () => {
  return (
    <>
      <StressCheckResult />
    </>
  );
};
