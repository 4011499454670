import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useFieldArray } from "react-hook-form";

import { useEmployeeMaintenance } from "../../../hooks/employeeMaintenance/useEmployeeMaintenance";
import {
  EmployeeMaintenanceFormType,
  EmployeeMaintenanceRequestType,
} from "../../../types/api/employeeMaintenance";
import { EmployeeMaintenanceCreateComplete } from "../../templates/employeeMaintenance/EmployeeMaintenanceCreateComplete";
import { EmployeeMaintenanceCreateForm } from "../../templates/employeeMaintenance/EmployeeMaintenanceCreateForm";
import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { Sidebar } from "../../templates/sidebar/Sidebar";

export const EmployeeMaintenanceCreate = memo(() => {
  const { saveEmployee, isComplete } = useEmployeeMaintenance();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useForm<EmployeeMaintenanceFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      employeeNo: "",
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      businessLastName: "",
      businessFirstNameKana: "",
      businessLastNameKana: "",
      businessFirstName: "",
      position: "",
      occupation: "",
      sex: "0",
      year: "2022",
      month: "1",
      day: "1",
      officeId: "",
      emailAddress: "",
      phoneNumber: "",
      postcode: "",
      address: "",
      departmentList: [
        {
          value: "",
          responsibilityType: false,
        },
      ],
    },
  });

  const [requestData, setRequestData] =
    useState<EmployeeMaintenanceRequestType>({
      office_id: "",
      employee_no: "",
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      business_first_name: "",
      business_last_name: "",
      business_first_name_kana: "",
      business_last_name_kana: "",
      position: "",
      occupation: "",
      email_address: "",
      sex: "",
      birthday: "",
      postcode: "",
      address: "",
      phone_number: "",
      departments: [
        {
          id: "0",
          responsibility_type: "0",
          concurrent: "0",
        },
      ],
    });
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onSubmit: SubmitHandler<EmployeeMaintenanceFormType> = (data) => {
    const departmentListData = data.departmentList.map((department, i) => {
      return {
        id: department.value,
        responsibility_type: department.responsibilityType ? "1" : "0",
        concurrent: i === 0 ? "0" : "1",
      };
    });

    setRequestData({
      office_id: data.officeId,
      employee_no: data.employeeNo,
      last_name: data.lastName,
      first_name: data.firstName,
      last_name_kana: data.lastNameKana,
      first_name_kana: data.firstNameKana,
      business_first_name: data.businessFirstName,
      business_last_name: data.businessLastName,
      business_first_name_kana: data.businessFirstNameKana,
      business_last_name_kana: data.businessLastNameKana,
      position: data.position,
      occupation: data.occupation,
      sex: data.sex,
      birthday: `${data.year}/${data.month}/${data.day}`,
      phone_number: data.phoneNumber,
      email_address: data.emailAddress,
      postcode: data.postcode,
      address: data.address,
      departments: departmentListData,
    });
    setIsPreviewVisible(true);
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };
  const createEmployee = () => {
    saveEmployee(requestData);
  };

  // スクロール時にscrollIntoViewを使用するためにて定義してる
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isPreviewVisible, isComplete]);

  const { fields, append, remove } = useFieldArray({
    name: "departmentList",
    control,
  });

  return (
    <>
      {/********************************************************
       * TODO: スクロール対策で一時的にcommonの内容を移行させてる
       *******************************************************/}
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div
          className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9"
          ref={scrollBottomRef}
        >
          {!isComplete ? (
            <EmployeeMaintenanceCreateForm
              register={register}
              onSubmit={onSubmit}
              watch={watch}
              handleSubmit={handleSubmit}
              errors={errors}
              isPreviewVisible={isPreviewVisible}
              hidePreview={hidePreview}
              createEmployee={createEmployee}
              getValues={getValues}
              reset={reset}
              required
              append={append}
              remove={remove}
              fields={fields}
            />
          ) : (
            <EmployeeMaintenanceCreateComplete />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
});
