import React, { memo, FC, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import Logo from "../../../images/logo.png";
import { useAuth } from "../../../hooks/useAuth";
import {
  PasswordResetRequestType,
  ResetPasswordFormType,
} from "../../../types/api/user";

export const PasswordReset: FC = memo(() => {
  const { postPasswordReset } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      companyId: "",
      emailAddress: "",
    },
  });

  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollBottomRef?.current?.scrollIntoView();
  }, []);

  const onSubmit: SubmitHandler<ResetPasswordFormType> = (data) => {
    const requestData: PasswordResetRequestType = {
      company_id: data.companyId,
      email_address: data.emailAddress,
    };
    postPasswordReset(requestData);
  };

  return (
    <div
      className="bg-lightGreen-100 flex flex-col justify-center items-center sm:py-12 min-h-screen w-full"
      ref={scrollBottomRef}
    >
      <div className="flex flex-col space-y-12 items-center px-[150px] pt-20 pb-28 bg-white shadow rounded-lg">
        <div className="inline-flex space-x-3.5 items-center">
          <img src={Logo} alt="logo.png" width={300} height={200} />
        </div>
        <span>項目を入力して、リセットボタンを押してください。</span>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-5 items-center justify-end w-full">
            <label className="font-bold text-lg font-w6 leading-none text-gray-700 w-full">
              会社ID
            </label>
            <input
              className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
              value={watch("companyId")}
              {...register("companyId", {
                required: "この項目は必須です",
              })}
            />
            {errors.companyId?.message && (
              <p className="text-red-600 ml-0 w-full">
                {errors.companyId.message}
              </p>
            )}
            <label className="font-bold text-lg font-w6 leading-none text-gray-700 w-full">
              メールアドレス
            </label>
            <input
              className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
              value={watch("emailAddress")}
              {...register("emailAddress", {
                required: "この項目は必須です",
                pattern: {
                  value:
                    /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i,
                  message: "不正な形式のメールアドレスです。",
                },
              })}
            />
            {errors.emailAddress?.message && (
              <span className="text-red-600 ml-0 w-full">
                {errors.emailAddress.message}
              </span>
            )}
          </div>
          <div className="mt-16	pb-20 text-center">
            <button
              className="inline-block w-36 h-13 border rounded-lg text-sm border-gray-700 text-gray-700"
              onClick={() => {
                navigate("/");
              }}
            >
              戻る
            </button>
            <button
              type="submit"
              className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
              value="リセット"
            >
              リセット
            </button>
          </div>
        </form>
      </div>
      <span className="text-xs leading-3 text-center text-gray-600 mt-6">
        © 2022 CAPS株式会社
      </span>
    </div>
  );
});
