import React, { FC, useContext } from "react";

import { Title } from "../../atoms/commons/Title";
import StressCheck from "../../../images/stress-check.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { StressCheckToAnswerHeading } from "../../atoms/stressChecks/StressCheckToAnswerHeading";
import { AnswersContext } from "../../pages/stressChecks/StressCheckToAnswerSheet";
import { StressCheckQuestion } from "../../../types/api/stressCheckQuestion";

type Props = {
  onLangEnFlag: () => void;
  onClickAnswerHandleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    secId: number,
    queId: number
  ) => void;
  submitStressCheckToAnswer: () => void;
  stressCheckQuestions: StressCheckQuestion[];
};

const answerTypeHandler = (type: string) => {
  switch (type) {
    case "yesno":
      return ["そうだ", "まあそうだ", "やや違う", "違う"];
    case "frequency":
      return [
        "ほとんどなかった",
        "ややなかった",
        "少しあった",
        "ほとんどいつもあった",
      ];
    case "degree":
      return ["非常に", "ややある", "あまりない", "全くない"];
    case "satisfaction":
      return ["満足", "やや満足", "やや不満足", "不満足"];
    default:
      return ["そうだ", "まあそうだ", "やや違う", "違う"];
  }
};

export const LangJpAnswerSheet: FC<Props> = ({
  onLangEnFlag,
  onClickAnswerHandleChange,
  submitStressCheckToAnswer,
  stressCheckQuestions,
}) => {
  const onClickChangeLangEn = () => {
    onLangEnFlag();
  };

  const answers = useContext(AnswersContext);

  return (
    <>
      <Title src={StressCheck}>ストレスチェックテスト</Title>
      <div className="absolute top-[80px] right-[80px]">
        <button disabled={true}>
          <span>日本語</span>
        </button>
        ｜
        <button
          disabled={false}
          onClick={onClickChangeLangEn}
          className="text-lightGreen-200 underline decoration-lightGreen-200"
        >
          <span>English</span>
        </button>
      </div>
      <ExplanatoryText>
        質問についてあてはまるものを選択してください。
      </ExplanatoryText>
      <div className="mt-5 justify-center container flex flex-col">
        <form>
          <div>
            {stressCheckQuestions.map((section) => (
              <div key={section.section_id}>
                <StressCheckToAnswerHeading>
                  {section.jp.title}
                </StressCheckToAnswerHeading>
                <div className="py-[30px] mb-[100px]">
                  {Object.keys(section.jp.questions).map((key, index) => (
                    <div
                      key={key}
                      className={
                        section.question_length === index + 1
                          ? "border-y py-[35px] border-lightGray-300"
                          : "border-t py-[35px] border-lightGray-100"
                      }
                    >
                      <div className="flex items-center">
                        <div className="flex justify-center items-center h-10 w-10 mr-4 boder rounded-lg bg-lightGreen-200">
                          <span className="text-white text-[26px]">
                            {index + 1}
                          </span>
                        </div>
                        <span className="text-lg font-bold">
                          {section.jp.questions[key]}
                        </span>
                      </div>
                      <div className="flex justify-center text-center pt-[30px]">
                        {answerTypeHandler(section.question_type).map(
                          (value, index) => (
                            <div key={value} className="flex-1">
                              <input
                                type="radio"
                                value={index + 1}
                                onChange={(e) =>
                                  onClickAnswerHandleChange(
                                    e,
                                    section.section_id,
                                    parseInt(key)
                                  )
                                }
                                checked={answers.some((ans) => {
                                  if (ans.sectionId === section.section_id) {
                                    const a = ans.questions.find(
                                      (que) =>
                                        que.questionNumber === parseInt(key)
                                    );
                                    return a?.answer == index + 1;
                                  }
                                  return false;
                                })}
                                className="appearance-none rounded-full h-5 w-5 border border-lightGreen-200 bg-white checked:border-lightGreen-200 checked:bg-lightGreen-200 mt-[2px] align-top cursor-pointer"
                              />
                              <label className="pl-3">{value}</label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center mt-[70px]">
            <p>
              全て回答してから送信ボタンを押して、ストレスチェックを完了してください。
            </p>
          </div>
          <div className="flex justify-center items-center mt-[30px] mb-[100px]">
            <button
              type="button"
              onClick={submitStressCheckToAnswer}
              className="w-[150px] h-[50px] border rounded-lg bg-lightGreen-200 text-white"
            >
              回答を送信
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
