import React, { FC, memo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  FieldError,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import BackIcon from "../../../images/back-icon.png";
import Choice from "../../../images/choice.png";
import { TaskListRequestType } from "../../../types/api/EmployeeTask";
import { ErrorMassage } from "../commons/ErrorMassage";
import { FormLabel } from "../commons/FormLabel";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";

// タスク登録API繋ぎ込みMR時に使用
// import {
//     TaskListRequestType,
// } from "../../../types/api/EmployeeTask";
// import { FormLabel } from "../commons/FormLabel";
// import { ErrorMassage } from "../commons/ErrorMassage";

type Props = {
  onChangeModalFlag: () => void;
  saveTask: (data: TaskListRequestType) => Promise<void>;
  register: UseFormRegister<TaskListRequestType>;
  handleSubmit: UseFormHandleSubmit<TaskListRequestType>;
  setValue: UseFormSetValue<TaskListRequestType>;
  formState: {
    errors: {
      status?: FieldError | undefined;
      name?: FieldError | undefined;
    };
  };
};

export const ModalTaskCreateForm: FC<Props> = memo((props) => {
  const {
    onChangeModalFlag,
    saveTask,
    register,
    handleSubmit,
    setValue,
    formState,
  } = props;

  return (
    <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
      <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
        タスク登録
      </p>
      <button onClick={onChangeModalFlag} className="absolute top-5 right-5">
        <img src={BackIcon} alt="Back-icon" />
      </button>
      <form onSubmit={handleSubmit(saveTask)}>
        <div className="w-full flex flex-col justify-center items-center border-t border-lightGray-100">
          <div className="flex items-center py-5 border-b border-lightGray-100 w-full">
            <RequiredFormLabel
              title="タスク区分"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div className="relative">
              <img
                src={Choice}
                alt="choice-icon"
                className="w-3 h-3 absolute top-1 right-0 m-4 pointer-events-none"
              />
              <select
                className="border border-lightGreen-200 appearance-none rounded-lg w-40 h-12 pl-4
                focus:outline-none disabled:text-black disabled:opacity-100"
                {...register("status")}
              >
                <option value="0">ーーーーー</option>
                <option value="1">入社タスク</option>
                <option value="2">退社タスク</option>
              </select>
            </div>
          </div>
          <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
            <RequiredFormLabel
              title="タスク名"
              className="w-40 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <div>
              <input
                {...register("name", {
                  required: true,
                  maxLength: 50,
                })}
                className="w-[400px] mb-1 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200"
              />
              {formState.errors.name?.type === "required" && (
                <ErrorMassage error="required" style="modal" />
              )}
              {formState.errors.name?.type === "maxLength" && (
                <ErrorMassage error="maxLength-50" style="modal" />
              )}
            </div>
          </div>
          <div className="flex mt-10">
            <button
              type="submit"
              className="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg hover:text-white hover:bg-lightGreen-200 text-lightGreen-200 text-lg"
            >
              <span className="text-lg font-w6 leading-relaxed">登録</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});
