import React, { FC, memo } from "react";

type Props = {
  issuableEvent: () => void;
  accountStatus: string;
};

export const AccountIssuingButton: FC<Props> = memo(
  ({ issuableEvent, accountStatus }) => {
    const latestAccountIssuingStatus = () => {
      switch (accountStatus) {
        case "0":
          return (
            <button
              className="py-1 px-5 text-sm text-lightGreen-200  rounded-lg focus:outline-none border-lightGreen-200 border"
              type="button"
              onClick={issuableEvent}
            >
              発行
            </button>
          );
        case "1":
          return <p>発行済</p>;
        case "2":
          return <p>発行不可</p>;
      }
    };

    return (
      <td className="overflow-ellipsis truncate max-w-0">
        {latestAccountIssuingStatus()}
      </td>
    );
  }
);
