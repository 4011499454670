import React, { FC, memo } from "react";

type Props = {
  employmentStatus: string;
};

export const EmployeeStatus: FC<Props> = memo(({ employmentStatus }) => {
  const latestEmploymentStatus = () => {
    switch (employmentStatus) {
      case "0":
        return <p>在籍中</p>;
      case "1":
        return <p>休職中</p>;
      case "2":
        return <p>退職済み</p>;
    }
  };

  return (
    <td className="overflow-ellipsis truncate max-w-0">
      {latestEmploymentStatus()}
    </td>
  );
});
