import React, { useContext, useEffect } from "react";

import { DepartmentTreeFigure } from "../../templates/departments/DepartmentTreeFigure";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { useFetchDepartmentTree } from "../../../hooks/department/useFetchDepartmentTree";
import { useShowModalFlagHandler } from "../../../hooks/department/useShowModalFlagHandler";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { useFetchDepartmentAbolition } from "../../../hooks/department/useFetchDepartmentAbolition";

export const DepartmentTree = () => {
  const {
    fetchDepartmentTree,
    departmentTree,
    fetchDepartmentList,
    departmentList,
    isLoading,
  } = useFetchDepartmentTree();

  useEffect(() => {
    fetchDepartmentTree();
    fetchDepartmentList();
  }, []);

  const user = useContext(LoginUserContext);
  const userRoll = user.loginUser?.data.user_roll;
  const { useDepartmentAbolition } = useFetchDepartmentAbolition();
  const {
    showCreateModalFlag,
    onChangeCreateModalFlag,
    showEditModalFlag,
    onChangeEditModalFlag,
    showCreateHistoryModalFlag,
    onChangeHistoryModalFlag,
  } = useShowModalFlagHandler();
  const submitCheckAlert = async (id: number) => {
    if (
      !window.confirm(
        `部署を廃止しますが、よろしいでしょうか？\n※ 配下の部署も同様に廃止されます`
      )
    ) {
      return;
    }
    await useDepartmentAbolition(id);
    fetchDepartmentTree();
    fetchDepartmentList();
    onChangeEditModalFlag();
  };
  const departmentObject = {
    userRoll,
    isLoading,
    departmentTree,
    departmentList,
    fetchDepartmentTree,
    fetchDepartmentList,
    showCreateModalFlag,
    onChangeCreateModalFlag,
    showEditModalFlag,
    onChangeEditModalFlag,
    showCreateHistoryModalFlag,
    onChangeHistoryModalFlag,
    submitCheckAlert,
  };

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <DepartmentTreeFigure departmentObject={departmentObject} />
      )}
    </>
  );
};
