import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getStressCheckManagement } from "../../api/apiStressCheckManagement";
import { StressCheckManagementResponseType } from "../../types/api/stressCheckManagement";

export const useFetchStressCheckManagement = () => {
  const params = useParams();
  const stressCheckId = params.id;
  const navigate = useNavigate();

  const [stressCheckManagement, setStressCheckManagement] =
    useState<StressCheckManagementResponseType>({
      stress_check_frame: {
        stress_check_frame_id: "",
        name: "",
        available_api: {
          set_sc_targets: false,
          set_interview_targets: false,
          get_company_report: false,
          send_mail: false,
        },
      },
      office_summaries: [
        {
          office_name: "",
          employee_count: 0,
          stress_checked_count: 0,
          interviewee_count: 0,
        },
      ],
    });

  const fetchStressCheckManagement = useCallback(async () => {
    const res = await getStressCheckManagement(stressCheckId);

    if (res.status !== 200) {
      alert("ストレスチェック管理のデータ取得に失敗しました");
      return navigate("/stress-checks-management");
    }

    setStressCheckManagement(res.data.data);
  }, []);

  useEffect(() => {
    fetchStressCheckManagement();
  }, []);

  return {
    stressCheckManagement,
  };
};
