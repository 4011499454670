import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useEmployeeMaintenance } from "../../../hooks/employeeMaintenance/useEmployeeMaintenance";
import { AccountIssuingButton } from "./AccountIssuingButton";
import { EmployeeStatus } from "./EmployeeMaintenanceStatus";

type Props = {
  id: number;
  employeeNo: string;
  lastName: string;
  firstName: string;
  employmentStatus: string;
  officeName: string;
  accountStatus: string;
};

export const EmployeeMaintenanceTable: FC<Props> = (props) => {
  const {
    id,
    lastName,
    firstName,
    employmentStatus,
    officeName,
    accountStatus,
    employeeNo,
  } = props;

  // リロードしなくても発行ボタンをクリックしたら動的に発行済みに変更させるために初期値にaccountStatusにしてる
  const [latestAccountStatus, setLatestAccountStatus] = useState(accountStatus);

  const { AccountIssuance } = useEmployeeMaintenance();
  const navigate = useNavigate();

  const issuableEvent = () => {
    AccountIssuance(String(id), setLatestAccountStatus);
  };

  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="text-lightGreen-200 overflow-ellipsis max-w-0">
          <p>{employeeNo == "" ? "未設定" : employeeNo}</p>
        </td>
        <td className="text-lightGreen-200 overflow-ellipsis max-w-0">
          <p>
            {lastName} {firstName}
          </p>
        </td>

        <EmployeeStatus employmentStatus={employmentStatus} />

        <td className="overflow-ellipsis truncate max-w-0">
          <p>{officeName}</p>
        </td>
        <AccountIssuingButton
          issuableEvent={issuableEvent}
          accountStatus={latestAccountStatus}
        />
        <td className="flex items-center justify-end mt-6">
          <button
            className="py-1 px-5 text-white rounded-lg focus:outline-none border-lightGreen-200 border"
            onClick={() => {
              navigate(`../detail/${id}`, {
                state: { id: id },
              });
            }}
          >
            <span className="text-lg font-w6 leading-relaxed text-lightGreen-200">
              詳細
            </span>
          </button>
        </td>
      </tr>
    </tbody>
  );
};
