import React, { memo } from "react";
import { Link } from "react-router-dom";

export const StressCheckResultBackButton = memo(() => {
  return (
    <>
      <div className="flex justify-center items-center mt-[20px] pb-[30px]">
        <Link
          to={"/stress-checks"}
          className="border border-lightGreen-200 rounded-lg py-[16px] px-[50px]"
        >
          <span className="font-bold text-lg text-lightGreen-200">戻る</span>
        </Link>
      </div>
    </>
  );
});
