import React, { memo } from "react";

import StressCheckManagementIcon from "../../../images/stress-check-management.png";
import { Title } from "../../atoms/commons/Title";
import { scManagementEmployeeListResponseType } from "../../../types/api/stressCheckManagementEmployees";
import { Paginate } from "../Paginate";
import { AdminStressCheckTargetEmployeesTable } from "./AdminStressCheckTargetEmployeesTable";

export const AdminStressCheckTargetEmployeesList = memo(
  (props: {
    stressCheckManagementEmployeeList: scManagementEmployeeListResponseType;
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
    titleObj: {
      InterviewTitle: string;
      HighStressTitle: string;
      StressCheckStatus: string;
    };
    onClickHandler: (stressCheckId: number) => void;
  }) => {
    const {
      titleObj,
      paginateObj,
      stressCheckManagementEmployeeList,
      onClickHandler,
    } = props;

    return (
      <div className="mb-10 min-h-full">
        <Title src={StressCheckManagementIcon}>
          ストレスチェック対象者一覧
        </Title>
        <div className="mt-5 justify-center flex flex-col w-full"></div>
        <table className="py-5 text-md justify-center mx-auto w-full mt-2">
          <thead>
            <tr className="border-b leading-none text-lightGreen-200">
              <th className="text-left py-5 w-10">社員番号</th>
              <th className="text-left py-5 w-16">名前</th>
              <th className="text-center py-5 w-8">
                {titleObj.InterviewTitle}
              </th>
              <th className="text-center py-5 w-6">
                {titleObj.HighStressTitle}
              </th>
              <th className="text-right py-5 w-6">
                {titleObj.StressCheckStatus}
              </th>
            </tr>
          </thead>
          {stressCheckManagementEmployeeList.sc_target_employees.map(
            (employee) => (
              <AdminStressCheckTargetEmployeesTable
                key={employee.id}
                employeeNo={employee.employee_no}
                stressCheckID={employee.stress_check_id}
                lastName={employee.last_name}
                firstName={employee.first_name}
                interviewTarget={employee.interview_target}
                hightStress={employee.hight_stress}
                answered={employee.answered}
                onClickHandler={onClickHandler}
              />
            )
          )}
        </table>
        <Paginate
          handlePageChange={paginateObj.handlePageChange}
          offset={paginateObj.offset}
          perPage={50}
          listLength={
            stressCheckManagementEmployeeList.sc_target_employees.length
          }
          hight={10}
        />
      </div>
    );
  }
);
