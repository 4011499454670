import React, { memo, ReactNode } from "react";

export const ModalDisplay = memo(
  (props: { showModal: boolean; children: ReactNode }) => {
    const { showModal, children } = props;

    return (
      <>
        {showModal ? (
          <div className="z-20 fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
            {children}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
);
