import React, { FC, memo } from "react";

import { GroupAnalyticsListTableHeader } from "./../../../atoms/stressCheckManagements/groupAnalytics/GroupAnalyticsListTableHeader";
import { GroupAnalyticsListTableValue } from "../../../atoms/stressCheckManagements/groupAnalytics/GroupAnalyticsListTableValue";
import { useGroupAnalytics } from "../../../../hooks/stressCheckManagements/useGroupAnalytics";

type Props = {
  comprehensiveEvaluationList: string[];
  stressFactorList: string[];
  mentalAndPhysicalReactionList: string[];
  supportStatus: string[];
  num_of_grades: number;
  thresholds: number[][];
  tableList: {
    the_group_name: string;
    the_group_score: number;
    overall_score: number;
    explanation: string;
    stress_check_info: {
      examinees: number;
      overall_examinees: number;
      highstress: number;
      overall_highstress: number;
      highstress_rate: number;
      overall_highstress_rate: number;
      doctor_interview_targets: number;
      overall_doctor_interview_targets: number;
    };
    sections: {
      name: string;
      score: number;
      comment: string;
      scales: {
        name: string;
        score: number;
      }[];
    }[];
  }[];
};

export const GroupAnalyticsOverall: FC<Props> = memo((props) => {
  const {
    comprehensiveEvaluationList,
    stressFactorList,
    mentalAndPhysicalReactionList,
    supportStatus,
    num_of_grades,
    thresholds,
    tableList,
  } = props;

  const { faceIconHandleChange } = useGroupAnalytics();

  return (
    <>
      <div className="mt-10">
        <div className="mt-6 flex">
          <div className="w-[320px]  whitespace-nowrap">
            <div className="block text-left">
              <p className="block text-2xl text-lightGreen-200 pb-10 font-bold">
                全体
              </p>
              <GroupAnalyticsListTableHeader
                list={comprehensiveEvaluationList}
              />
              <GroupAnalyticsListTableHeader
                list={stressFactorList}
                marginTop
              />
              <GroupAnalyticsListTableHeader
                list={mentalAndPhysicalReactionList}
                marginTop
              />
              <GroupAnalyticsListTableHeader list={supportStatus} marginTop />
            </div>
          </div>
          <div className="mx-[15px] border-r border-lightGray-100"></div>
          <div className="flex overflow-x-scroll whitespace-nowrap">
            {tableList.map((table, index) => (
              <div
                key={index}
                className={
                  index !== 0
                    ? "px-[15px] border-l border-lightGray-100"
                    : "pr-[15px]"
                }
              >
                <div className="block text-center">
                  <p className="h-[43px] w-[148px] text-lg block text-lightGreen-200 font-bold">
                    {table.the_group_name}
                  </p>
                  <div className="mt-[30px] w-[148px] text-lg block">
                    <div
                      className={
                        table.the_group_score !== 0
                          ? "flex items-center h-[45px] pl-[2px]"
                          : "flex items-center h-[45px] pl-[30px]"
                      }
                    >
                      <img
                        className={table.the_group_score !== 0 ? "" : "hidden"}
                        src={faceIconHandleChange(
                          table.the_group_score,
                          thresholds
                        )}
                        alt="face-icon.png"
                        width={40}
                        height={40}
                      />
                      <div className="ml-5 flex items-end">
                        <span className="text-3xl font-bold">
                          {table.the_group_score !== 0
                            ? table.the_group_score
                            : "-"}
                        </span>
                        <span className="pl-[7px] text-lg font-bold">/</span>
                        <span className="pl-[5px] text-lg font-bold">
                          {num_of_grades}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="leading-[45px] w-[148px] mt-2 text-md block font-normal bg-lightGray-50 text-center">
                    {table.stress_check_info.examinees !== 0
                      ? table.stress_check_info.examinees
                      : "-"}
                  </p>
                  <p className="leading-[45px] w-[148px] mt-2 text-md block font-normal text-center">
                    {table.stress_check_info.examinees !== 0 ||
                    table.stress_check_info.highstress !== 0
                      ? `${table.stress_check_info.highstress}（
                    ${table.stress_check_info.highstress_rate}％）`
                      : "-"}
                  </p>
                  <p className="leading-[45px] w-[148px] mt-2 text-md block font-normal bg-lightGray-50 text-center">
                    {table.stress_check_info.doctor_interview_targets !== 0
                      ? table.stress_check_info.doctor_interview_targets
                      : "-"}
                  </p>
                  {table.sections.map((section, index) => (
                    <GroupAnalyticsListTableValue
                      key={index}
                      num_of_grades={num_of_grades}
                      thresholds={thresholds}
                      sections={section}
                      marginTop
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
