import React, { memo } from "react";
import {
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import StressCheckManagement from "../../../images/stress-check-management.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { StressCheckSelectionListTable } from "../../atoms/stressCheckManagements/StressCheckSubjectSelectionListTable";
import { Paginate } from "../Paginate";
import {
  StressCheckSubjectEmployeeListResponseType,
  StressCheckSubjectEmployeeResponseType,
} from "../../../types/api/stressCheckManagement";

type InputProps = {
  stressCheckSelectionObj: {
    isLoading: boolean;
    stressCheckSubjectEmployeeList: StressCheckSubjectEmployeeListResponseType;
    fetchStressCheckSubjectEmployeeList: () => Promise<void>;
    checkedValues: number[];
    setCheckedValues: React.Dispatch<React.SetStateAction<number[]>>;
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
    register: UseFormRegister<StressCheckSubjectEmployeeResponseType>;
    watch: UseFormWatch<StressCheckSubjectEmployeeResponseType>;
    handleSubmit: UseFormHandleSubmit<StressCheckSubjectEmployeeResponseType>;
    setValue: UseFormSetValue<StressCheckSubjectEmployeeResponseType>;
    departmentlists: [
      {
        department_id: number;
        name: string;
      }
    ];
    oficelists: [
      {
        office_id: number;
        name: string;
      }
    ];
    setCheckedEmployeeList: () => void;
    handleCheckBoxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: SubmitHandler<StressCheckSubjectEmployeeResponseType>;
  };
};

export const StressCheckSelectionList = memo(
  ({ stressCheckSelectionObj }: InputProps) => {
    return (
      <>
        <Title src={StressCheckManagement}>ストレスチェック対象者選定</Title>
        <ExplanatoryText>
          ストレスチェックの対象者を登録してください。
        </ExplanatoryText>
        <div className="pb-2">
          <span className="font-bold text-sm text-red-600">
            ※ 事業場が未設定な社員は登録できません。
          </span>
        </div>
        <div className="w-full py-[30px] border-y border-lightGray-100">
          <div>
            <span className="font-bold text-lg text-lightGreen-200">
              テスト名
            </span>
            <span className="ml-[110px] font-normal	text-lg">
              {stressCheckSelectionObj.stressCheckSubjectEmployeeList.title}
            </span>
          </div>
        </div>

        {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
        {/* <div className="flex items-center my-[30px]">
            <p className="text-lg leading-relaxed text-gray-700">
              1 - {offset + perPage} 件 / 全 {List.length}件中
            </p>
            <div className="flex items-center ml-auto">
              <div className="flex items-center">
                <span className="font-bold text-lg text-lightGreen-200">
                  表示件数
                </span>
                <div className="relative leading-relaxed ml-[15px]">
                  <img
                    className="w-2 h-2 absolute top-1 right-0 m-4 pointer-events-none"
                    src={Choice}
                  ></img>
                  <select className="lg:border border-lightGreen-200 rounded-lg h-[50px] py-2.5 pr-10 pl-7 hover:border-gray-400 focus:outline-none appearance-none">
                    <option value={50}>50件</option>
                    <option value={100}>100件</option>
                    <option value={250}>250件</option>
                    <option value={"ALL"}>ALL</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center">
                <span className="ml-[50px] font-bold text-lg text-lightGreen-200">
                  検索
                </span>
                <div className="w-80 h-12">
                  <input
                    className="ml-[30px] h-[50px] w-[300px] pl-4 border border-lightGreen-200 rounded-lg"
                    placeholder="氏名・社員Noを入力"
                  />
                </div>
                <button className="ml-[50px] bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[10px] px-[20px]">
                  <span className="font-bold text-lg text-white">検索</span>
                </button>
              </div>
            </div> */}
        <form
          onSubmit={stressCheckSelectionObj.handleSubmit(
            stressCheckSelectionObj.onSubmit
          )}
        >
          <div className="w-full flex items-center mb-[65px]">
            <div className="mt-5 justify-center container flex flex-col">
              <div className="flex items-center mb-[30px]">
                <div className="bg-lightGreen-400 border border-lightGreen-400 rounded text-center py-[8px] px-[20px] ml-[10px]">
                  <input
                    type="checkbox"
                    onChange={stressCheckSelectionObj.setCheckedEmployeeList}
                    className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block"
                  />
                  <span className="ml-[10px]">全てを選択</span>
                </div>
                <div className="ml-auto">
                  <button
                    type="submit"
                    className="bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[5px] px-[13px]"
                  >
                    <span className="font-bold text-lg text-white">登録</span>
                  </button>
                </div>
              </div>
              <div className="py-5 w-full">
                <div className="flex border-b leading-none pb-5">
                  <div className="w-1/3 pl-16">
                    <span className="font-bold text-lg text-lightGreen-200">
                      氏名
                    </span>
                  </div>
                  <div>
                    <span className="font-bold text-lg text-lightGreen-200">
                      事業場
                    </span>
                  </div>
                </div>
                {stressCheckSelectionObj.stressCheckSubjectEmployeeList.candidates.map(
                  (employeeList) => (
                    <StressCheckSelectionListTable
                      key={employeeList.employee_id}
                      employeeList={employeeList}
                      employeeId={employeeList.employee_id}
                      name={employeeList.name}
                      officeId={employeeList.office_id}
                      officeName={employeeList.office_name}
                      handleCheckBoxChange={
                        stressCheckSelectionObj.handleCheckBoxChange
                      }
                      checkedValues={stressCheckSelectionObj.checkedValues}
                      departmentlists={stressCheckSelectionObj.departmentlists}
                      oficelists={stressCheckSelectionObj.oficelists}
                      selected={employeeList.selected}
                      register={stressCheckSelectionObj.register}
                      watch={stressCheckSelectionObj.watch}
                      handleSubmit={stressCheckSelectionObj.handleSubmit}
                    />
                  )
                )}
              </div>
            </div>
          </div>

          <Paginate
            handlePageChange={
              stressCheckSelectionObj.paginateObj.handlePageChange
            }
            offset={stressCheckSelectionObj.paginateObj.offset}
            perPage={50}
            listLength={
              stressCheckSelectionObj.stressCheckSubjectEmployeeList.candidates
                .length
            }
            hight={60}
          />
          <div className="w-full text-center inline-block bg-lightGreen-100 py-[40px] mb-[30px]">
            <p className="font-bold text-lg">
              登録ボタンを押して、選択したストレスチェック対象者を登録してください。
            </p>
            <button
              type="submit"
              className="bg-lightGreen-200 border border-lightGreen-200 rounded-lg text-center py-[5px] px-[50px] mt-[30px]"
            >
              <span className="font-bold text-lg text-white">登録</span>
            </button>
          </div>
        </form>
      </>
    );
  }
);
