import { useCallback, useEffect, useState } from "react";

import { getAdminReaderList } from "../../api/apiSettings";
import {
  AdminReaderListType,
  AdminsReadersResponseType,
  AdminsReadersType,
} from "../../types/api/settingsRoll";

export const useFetchAdminReaderList = () => {
  const [adminReaderList, setReaderAdminList] = useState<AdminReaderListType>({
    admins: [],
    readers: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchAdminReaderList = useCallback(async () => {
    const res = await getAdminReaderList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("管理者・閲覧者一覧取得に失敗しました");
    }

    const adminReaderList = () => {
      const admins: AdminsReadersType = res.data.data.admins.map(
        (admin: AdminsReadersResponseType) => {
          return {
            id: admin.id,
            name: admin.last_name + " " + admin.first_name,
            emailAddress: admin.email_address,
          };
        }
      );
      const readers: AdminsReadersType = res.data.data.readers.map(
        (reader: AdminsReadersResponseType) => {
          return {
            id: reader.id,
            name: reader.last_name + " " + reader.first_name,
            emailAddress: reader.email_address,
          };
        }
      );
      return {
        admins: admins,
        readers: readers,
      };
    };

    setIsLoading(false);
    setReaderAdminList(adminReaderList());
  }, []);

  useEffect(() => {
    fetchAdminReaderList();
  }, []);

  return {
    adminReaderList,
    isLoading,
  };
};
