import React from "react";

import { useFetchOrganizationHistoryList } from "../../../hooks/histories/useFetchOrganizationHistoryList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { TreeHistoryList } from "../../templates/histories/TreeHistoryList";

export const DepartmentTreeHistories = () => {
  const { isLoading, historyList } = useFetchOrganizationHistoryList();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <TreeHistoryList historyList={historyList} />
      )}
    </>
  );
};
