import React, { memo } from "react";

import { EmploymentTaskList } from "../../templates/employmentRetirementTasks/EmploymentTaskList";
import { RetirementTaskList } from "../../templates/employmentRetirementTasks/RetirementTaskList";
import { useTaskList } from "../../../hooks/employmentRetirementEmployeeTask/useFetchTaskList";
import { postTaskList } from "../../../hooks/employmentRetirementEmployeeTask/useTaskList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

export const TaskLists = memo(() => {
  const taskListObj = useTaskList();
  const postTaskListObj = postTaskList();

  if (taskListObj.isLoading) {
    return <LoadingTemplate />;
  }

  return (
    <>
      {taskListObj.isLoading ? (
        <LoadingTemplate />
      ) : taskListObj.isListTable ? (
        <EmploymentTaskList
          taskListObj={taskListObj}
          postTaskListObj={postTaskListObj}
        />
      ) : (
        <RetirementTaskList
          taskListObj={taskListObj}
          postTaskListObj={postTaskListObj}
        />
      )}
    </>
  );
});
