import React, { memo } from "react";

import { InterviewGuidanceEditForm } from "../../templates/stressCheckMaintenance/InterviewGuidanceEditForm";

export const InterviewGuidanceEdit = memo(() => {
  return (
    <>
      <InterviewGuidanceEditForm />
    </>
  );
});
