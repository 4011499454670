// プロフィール画面で設定したカード色と文字色を切り替える関数
export const cardThemeSwitch = (cordColor: string) => {
  switch (cordColor) {
    case "green":
      return "bg-cordColor-green text-white";
    case "yellow_green":
      return "bg-cordColor-yellowGreen text-white";
    case "orange":
      return "bg-cordColor-orange text-white";
    case "red":
      return "bg-cordColor-red text-white";
    case "pink":
      return "bg-cordColor-pink text-white";
    case "violet":
      return "bg-cordColor-violet text-white";
    case "dark_blue":
      return "bg-cordColor-darkBlue text-white";
    case "blue":
      return "bg-cordColor-blue text-white";
    default:
      return "bg-cordColor-white text-blocks";
  }
};
