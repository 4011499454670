import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getStressCheckQuestions } from "../../api/apiStressCheck";
import { StressCheckQuestionsResponseType } from "../../types/api/stressCheck";

// ストレスチェック回答ページのデータ取得
export const useFetchStressCheckQuestions = (stressCheckId: number) => {
  const navigate = useNavigate();

  const [stressCheckQuestions, setStressCheckQuestions] = useState<
    StressCheckQuestionsResponseType[]
  >([]);

  const fetchStressCheckQuestions = useCallback(async () => {
    const res = await getStressCheckQuestions(stressCheckId);
    if (res.status !== 200) {
      alert("ストレスチェックの一覧データ取得に失敗しました");
      return navigate("/stresscheck");
    }

    setStressCheckQuestions(res.data.data.sections);
  }, []);

  useEffect(() => {
    fetchStressCheckQuestions();
  }, []);

  return {
    stressCheckQuestions,
  };
};
