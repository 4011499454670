import React from "react";

import { useFetchOfficeList } from "../../../hooks/offices/useFetchOfficeList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { OfficeList } from "../../templates/offices/OfficeList";

export const Offices = () => {
  const { isLoading, officeList } = useFetchOfficeList();

  return (
    <>
      {isLoading ? <LoadingTemplate /> : <OfficeList officeList={officeList} />}
    </>
  );
};
