import React, { memo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import backIcon from "../../../images/back-icon.png";
import Choice from "../../../images/choice.png";
import { OrganizationListResponseType } from "../../../types/api/department";
import { DepartmentResumeResponseType } from "../../../types/api/department";
import { FormLabel } from "../commons/FormLabel";

export const DepartmentResumeForm = memo(
  (props: {
    departmentList: OrganizationListResponseType;
    onChangeModalFlag: () => void;
    departmentName: string;
    departmentId: number;
    fetchOrganizationList: () => Promise<void>;
    saveDepartmentResume: (
      parentId: DepartmentResumeResponseType,
      departmentId: number
    ) => Promise<void>;
  }) => {
    const {
      departmentList,
      onChangeModalFlag,
      departmentName,
      departmentId,
      fetchOrganizationList,
      saveDepartmentResume,
    } = props;

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<DepartmentResumeResponseType>();

    // Todo: 後日hook-fromなしでリファクタリング
    const submit: SubmitHandler<DepartmentResumeResponseType> = async (
      data
    ) => {
      await saveDepartmentResume(data, departmentId);
      fetchOrganizationList();
      reset();
      onChangeModalFlag();
    };

    return (
      <>
        <div className="relative py-[60px] px-[60px] bg-white rounded-lg flex flex-col justify-center items-center">
          <p className="font-bold leading-7 text-[32px] text-lightGreen-200 mb-[50px]">
            再開フォーム
          </p>
          <button
            onClick={onChangeModalFlag}
            className="absolute top-5 right-5"
          >
            <img src={backIcon} alt="back-icon" />
          </button>
          <div className="w-full flex flex-col justify-center items-center border-t border-lightGray-100">
            <div className="flex items-center py-5 border-b border-lightGray-100 w-full">
              <span className="w-16 text-lightGreen-200 font-semibold">
                部署名
              </span>
              <div className="ml-[75px]">
                <span className="text-lg">{departmentName}</span>
              </div>
            </div>
            <form onSubmit={handleSubmit(submit)} className="text-center">
              <div className="flex justify-between items-center py-5 border-b border-lightGray-100 w-full">
                <FormLabel
                  title="上位部署"
                  className="text-lightGreen-200 font-semibold"
                />
                <div className="relative">
                  <img
                    src={Choice}
                    alt="choice-icon"
                    className="w-3 h-3 absolute top-1 right-0 m-4 pointer-events-none"
                  />
                  <select
                    className="border border-lightGreen-200 appearance-none rounded-lg w-[400px] h-12 pl-4 ml-[75px]
                focus:outline-none disabled:text-black disabled:opacity-100"
                    {...register("parent_id")}
                  >
                    <option value="">上位部署なし</option>
                    {departmentList.departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="mt-10 inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
              >
                保存
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
);
