import React, { memo } from "react";

export const StressCheckSummaryDate = memo(() => {
  return (
    <div className="pb-[35px]">
      <div className="mb-[30px]">
        <span className="font-bold text-[24px] text-lightGreen-200">
          サマリデータ
        </span>
      </div>
      <span>
        労基署報告書類に記載する事業の種類は、
        <a
          href="https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000044.html"
          className="text-lightGreen-200 underline decoration-lightGreen-200 hover:opacity-[50%]"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        を参照してください。
      </span>
    </div>
  );
});
