import React, { FC, memo } from "react";

import { NavigateButton } from "../../atoms/commons/NavigateButton";

type Props = {
  id: number;
  saveDate: string;
};

export const ListTable: FC<Props> = memo((props) => {
  const { id, saveDate } = props;
  return (
    <tbody>
      <tr className="border-b hover:bg-lightGreen-100 h-24">
        <td className="text-lightGreen-200 overflow-ellipsis truncate max-w-0 pl-2">
          {saveDate}
        </td>
        <td className="mt-6 flex justify-end pr-2">
          <NavigateButton
            navigatePath={`/departments/histories/${id}`}
            buttonClassName="py-1 px-5 text-sm rounded-lg border-lightGreen-200 border"
          >
            詳細
          </NavigateButton>
        </td>
      </tr>
    </tbody>
  );
});
