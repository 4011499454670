import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";

// 過去組織図一覧取得
export const getOrganizationHistoryList = () => {
  return api
    .get("/merchant/history/organizations")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 組織図ヒストリー保存
export const postOrganizationHistoryList = () => {
  return api
    .put("/merchant/maintenance/history/organization")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 過去組織図詳細取得
export const getPastOrganizationHistoryList = (
  organizationFrameId: string | undefined
) => {
  return api
    .get(`/merchant/history/organizations/${organizationFrameId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 過去部署従業員
export const getPastDepartmentHistoryList = (
  departmentHistoryId: string | null,
  departmentId: string | null
) => {
  return api
    .get(
      `/merchant/maintenance/history/organizations/${departmentId}/departments/${departmentHistoryId}/employees`
    )
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getDepartmentTransition = () => {
  return api
    .get("/merchant/maintenance/history/departments")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
