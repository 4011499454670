import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { profileCardColorSwith } from "../../../utils/cardColorSwitch";
import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";
import EmployeeDetailAvatar from "../../../images/employee-detail-avatar.png";
import EmployeeProfileNoImage from "../../../images/ProfileNoImage.png";
import { useEmployee } from "../../../hooks/employees/useEmployee";
import { ReturnButton } from "../../atoms/commons/ReturnButton";
import {
  EmployeeProfileEditFromType,
  EmployeeProfileEditType,
} from "../../../types/api/employee";

type EditProps = {
  updateEmployeeProfileEvent: () => void;
  setProfileUpdateRequestData: React.Dispatch<
    React.SetStateAction<EmployeeProfileEditFromType>
  >;
  employeeId: string | undefined;
  setIsPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isPreviewVisible: boolean;
  updateEmployeeProfile: (
    data: EmployeeProfileEditFromType,
    employeeId: string | undefined
  ) => Promise<void>;
  setColorSwitch: React.Dispatch<React.SetStateAction<string>>;
  colorSwitch: string;
};

export const EmployeesProfileEditForm = ({
  employeeId,
  setIsPreviewVisible,
  isPreviewVisible,
  updateEmployeeProfile,
  setColorSwitch,
  colorSwitch,
}: EditProps) => {
  const { detailEmployee, fetchDetailEmployeeProfileEdit } = useEmployee();
  const [loading, setLoading] = useState(true);
  const [imageSet1, setImgge1] = useState<string | ArrayBuffer | null>("");
  const [imageSet2, setImgge2] = useState<string | ArrayBuffer | null>("");
  const [imageSet3, setImgge3] = useState<string | ArrayBuffer | null>("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EmployeeProfileEditType>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      businessLastName: "",
      businessFirstNameKana: "",
      businessLastNameKana: "",
      businessFirstName: "",
      profileImage: "",
      word: "",
      selfIntroduction: "",
      interestContent1: "",
      interestImage1: "",
      interestContent2: "",
      interestImage2: "",
      cardColor: "white",
      privateEmailAddress: "",
      privatePhoneNumber: "",
      extentionNumber: "",
    },
  });
  useEffect(() => {
    fetchDetailEmployeeProfileEdit(employeeId, setLoading);
    if (detailEmployee.employee_id !== 0) {
      setValue("businessFirstName", detailEmployee.business_first_name);
      setValue("businessLastName", detailEmployee.business_last_name);
      setValue(
        "businessFirstNameKana",
        detailEmployee.business_first_name_kana
      );
      setValue("businessLastNameKana", detailEmployee.business_last_name_kana);
      setValue("privateEmailAddress", detailEmployee.private_email_address);
      setValue("privatePhoneNumber", detailEmployee.private_phone_number);
      setValue("extentionNumber", detailEmployee.extention_number);
      setValue("interestContent1", detailEmployee.interest_content_1);
      setValue("interestContent2", detailEmployee.interest_content_2);
      setValue("word", detailEmployee.word);
      setValue("selfIntroduction", detailEmployee.self_introduction);
      setValue("cardColor", detailEmployee.card_color);
      const colortype = profileCardColorSwith(detailEmployee.card_color);
      setColorSwitch(colortype);
    }
  }, [loading]);
  const convertToBase64From1 = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files![0];
    const reader = new FileReader(); // ファイルリーダーのインスタンス生成
    reader.onload = await function (e) {
      if (reader.result) {
        setImgge1(e.target!.result);
      } else {
        alert("画像投稿に失敗しました");
        return "";
      }
    };
    reader.readAsDataURL(file);
  };
  const convertToBase64From2 = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files![0];
    const reader = new FileReader(); // ファイルリーダーのインスタンス生成
    reader.onload = await function (e) {
      if (reader.result) {
        setImgge2(e.target!.result);
      } else {
        alert("画像投稿に失敗しました");
        return "";
      }
    };
    reader.readAsDataURL(file);
  };
  const convertToBase64From3 = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files![0];
    const reader = new FileReader(); // ファイルリーダーのインスタンス生成
    reader.onload = await function (e) {
      if (reader.result) {
        setImgge3(e.target!.result);
      } else {
        alert("画像投稿に失敗しました");
        return "";
      }
    };
    reader.readAsDataURL(file);
  };

  const onSubmit: SubmitHandler<EmployeeProfileEditType> = async (data) => {
    const ProfileUpdateData: EmployeeProfileEditFromType = {
      business_last_name: data.businessFirstName,
      business_first_name_kana: data.businessFirstNameKana,
      business_last_name_kana: data.businessLastNameKana,
      business_first_name: data.businessFirstName,
      profile_image: imageSet1,
      word: data.word,
      self_introduction: data.selfIntroduction,
      interest_content_1: data.interestContent1,
      interest_image_1: imageSet2,
      interest_content_2: data.interestContent2,
      interest_image_2: imageSet3,
      card_color: data.cardColor,
      private_email_address: data.privateEmailAddress,
      private_phone_number: data.privatePhoneNumber,
      extention_number: data.extentionNumber,
    };
    setIsPreviewVisible(true);
    updateEmployeeProfile(ProfileUpdateData, employeeId);
  };

  const handleClick = (colorType: string) => {
    const colortype = profileCardColorSwith(colorType);
    setColorSwitch(colortype);
    setValue("cardColor", colorType);
  };

  const colorList = [
    { name: "white" },
    { name: "green" },
    { name: "yellow_green" },
    { name: "orange" },
    { name: "red" },
    { name: "pink" },
    { name: "violet" },
    { name: "dark_blue" },
    { name: "blue" },
    { name: "light_blue" },
  ];

  return (
    <>
      <main>
        <div>
          <Title src={EmployeeManagement}>プロフィール編集</Title>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex py-16">
            <div className="text-left w-3/12 2xl:w-2/12">
              <div className="text-center">
                {detailEmployee.profile_image ? (
                  <img
                    src={detailEmployee.profile_image}
                    alt="picture"
                    className="pb-3 h-52 w-52 rounded-full"
                  />
                ) : (
                  <img
                    src={EmployeeDetailAvatar}
                    alt="picture"
                    className="pb-3 h-52 w-52 rounded-full"
                  />
                )}
                <input
                  {...register("profileImage")}
                  className="inline w-40 mb-6 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-emerald-200 file:text-lightGreen-200 hover:file:bg-violet-100"
                  type="file"
                  onChange={convertToBase64From1}
                  accept="image/*"
                />
              </div>
              <div className="justify-center text-xl">
                <div>
                  <span className="text-lightGreen-200">
                    ビジネスネーム(姓)
                  </span>
                  <input
                    {...register("businessFirstName", { required: true })}
                    className="w-full h-13 pl-3 border rounded-lg text-sm text border-lightGreen-200"
                    disabled={isPreviewVisible}
                    value={watch("businessFirstName")}
                  />
                  {errors.businessLastName?.types?.required && (
                    <p className="text-red-600 text-xs">この項目は必須です</p>
                  )}
                  <p className="mt-3 text-lightGreen-200">ビジネスネーム(名)</p>
                  <input
                    {...register("businessLastName", { required: true })}
                    className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                    disabled={isPreviewVisible}
                    value={watch("businessLastName")}
                  />
                  {errors.businessLastName?.types?.required && (
                    <span className="text-red-600 text-xs">
                      この項目は必須です
                    </span>
                  )}
                </div>
              </div>
              <div className="justify-center text-xl mt-5">
                <div>
                  <span className="text-lightGreen-200">
                    ビジネスネーム(セイ)
                  </span>
                  <input
                    {...register("businessFirstNameKana", {
                      required: true,
                      pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                    })}
                    disabled={isPreviewVisible}
                    value={watch("businessFirstNameKana")}
                    className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                  />
                  {errors.businessFirstNameKana?.types?.required && (
                    <span className="text-red-600 text-xs">
                      この項目は必須です。
                    </span>
                  )}
                  {errors.businessFirstNameKana?.types?.pattern && (
                    <span className="text-red-600 text-xs">
                      カタカナで入力してください
                    </span>
                  )}
                  {""}
                  <p className="mt-3 text-lightGreen-200">
                    ビジネスネーム(メイ)
                  </p>
                  <input
                    {...register("businessLastNameKana", {
                      required: true,
                      pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                    })}
                    disabled={isPreviewVisible}
                    value={watch("businessLastNameKana")}
                    className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                  />
                  {errors.businessLastNameKana?.types?.required && (
                    <span className="text-red-600 text-xs">
                      この項目は必須です。
                    </span>
                  )}
                  {errors.businessLastNameKana?.types?.pattern && (
                    <span className="text-red-600 text-xs">
                      カタカナで入力してください
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full pl-10 text-lg">
              <div className="border"></div>
              <div className="py-12 m-[0 auto]">
                <div className="flex justify-center">
                  <div className="flex flex-wrap w-80 items-center">
                    {colorList.map((color) => {
                      return (
                        <button
                          {...register("cardColor")}
                          key={color.name}
                          onClick={() => handleClick(color.name)}
                          className={
                            "h-10 mr-5 w-10 border rounded-full" +
                            " " +
                            `bg-cordColor-${color.name}`
                          }
                          type="button"
                        ></button>
                      );
                    })}
                  </div>
                  <div
                    className={
                      "w-96 h-36 border leading-relaxed ml-6 rounded-lg truncate" +
                      " " +
                      `${colorSwitch}`
                    }
                  >
                    <div className="p-5 flex">
                      <img
                        src={EmployeeDetailAvatar}
                        className="max-w-24 max-h-24 rounded-full"
                      />
                      <div className="pl-5 space-y-2">
                        <div className="truncate w-40">
                          <p className="inline text-xl font-bold">
                            プレビューネーム
                          </p>
                        </div>
                        <div className="truncate w-40">
                          <p className="inline text-xs">プレビューネーム</p>
                        </div>
                        <div className="truncate w-40">
                          <p className="inline text-xs">〇〇〇〇〇〇〇〇部</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border"></div>
              <div className="py-12">
                <div className="flex mb-3 items-center bg-[#F0F6F4] py-6 px-10 rounded-lg">
                  <b className="text-lightGreen-200 w-24">ひとこと</b>
                  <input
                    {...register("word")}
                    className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                    disabled={isPreviewVisible}
                  />
                </div>
                <b className="text-lightGreen-200">自己紹介本文</b>
                <textarea
                  {...register("selfIntroduction")}
                  className="w-full h-40 pl-3 pt-3 border rounded-lg text-sm border-lightGreen-200"
                  disabled={isPreviewVisible}
                />
              </div>
              <div className="border"></div>
              <div className="py-12">
                <b className="text-lightGreen-200">趣味・特技</b>
                <div className="flex justify-between pt-9">
                  <div className="md:w-[356px] 2xl:w-[34rem]">
                    {detailEmployee.interest_image_1 ? (
                      <img
                        src={detailEmployee.interest_image_1}
                        alt="個人プロフィール 趣味"
                        width={356}
                        height={200}
                        className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                      />
                    ) : (
                      <img
                        src={EmployeeProfileNoImage}
                        alt="個人プロフィール 趣味"
                        width={356}
                        height={200}
                        className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                      />
                    )}
                    <input
                      {...register("interestImage1")}
                      type="file"
                      className="block mb-6 text-sm text-slate-600 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-emerald-200 file:text-lightGreen-200 hover:file:bg-violet-100"
                      onChange={convertToBase64From2}
                      accept="image/*"
                    />
                    <textarea
                      {...register("interestContent1")}
                      className="w-full h-24 pl-3 border rounded-lg text-sm border-lightGreen-200"
                      disabled={isPreviewVisible}
                      value={watch("interestContent1")}
                    />
                  </div>
                  <div className="md:w-[356px] 2xl:w-[34rem]">
                    {detailEmployee.interest_image_2 ? (
                      <img
                        src={detailEmployee.interest_image_2}
                        alt="個人プロフィール 趣味"
                        width={356}
                        height={200}
                        className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                      />
                    ) : (
                      <img
                        src={EmployeeProfileNoImage}
                        alt="個人プロフィール 趣味"
                        width={356}
                        height={200}
                        className="object-cover md:h-[200px] 2xl:h-[300px] 2xl:w-[34rem] rounded-lg mb-6"
                      />
                    )}
                    <input
                      {...register("interestImage2")}
                      type="file"
                      className="block mb-6 text-sm text-slate-600 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-emerald-200 file:text-lightGreen-200 hover:file:bg-violet-100"
                      onChange={convertToBase64From3}
                      accept="image/*"
                    />
                    <textarea
                      {...register("interestContent2")}
                      className="w-full h-24 pl-3 border rounded-lg text-sm border-lightGreen-200"
                      disabled={isPreviewVisible}
                      value={watch("interestContent2")}
                    />
                  </div>
                </div>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">E-mail(会社)</b>
                <span>{detailEmployee.email_address}</span>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">E-mail(個人)</b>
                <input
                  {...register("privateEmailAddress")}
                  className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                  disabled={isPreviewVisible}
                />
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">電話番号</b>
                <input
                  {...register("privatePhoneNumber", {
                    pattern: /^0[0-9]{9,10}$/i,
                  })}
                  className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                  disabled={isPreviewVisible}
                />
              </div>
              {errors.privatePhoneNumber?.types?.pattern && (
                <div className="text-red-600 text-xs">
                  ハイフンなしの10桁もしくは11桁の半角数字で入力してください。
                </div>
              )}
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">内線番号</b>
                <input
                  {...register("extentionNumber")}
                  className="w-full h-13 pl-3 border rounded-lg text-sm border-lightGreen-200"
                  disabled={isPreviewVisible}
                />
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">事業場</b>
                <span>{detailEmployee.office_name}</span>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">役職</b>
                <span>{detailEmployee.position}</span>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">部署</b>
                <p>{detailEmployee.department_name}</p>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">職種</b>
                <span>{detailEmployee.occupation}</span>
              </div>
              <div className="border"></div>
              <div className="py-9 flex items-center">
                <b className="w-36 text-lightGreen-200">社員番号</b>
                <span>{detailEmployee.employee_no}</span>
              </div>
              <div className="border"></div>
              <div className="pt-10 pb-20 text-center border-t">
                <ReturnButton />
                <button
                  className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
                  type="submit"
                >
                  編集
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};
