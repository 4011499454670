import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  navigatePath: string;
  children: ReactNode;
  buttonClassName: string;
};

export const NavigateButton: FC<Props> = (props) => {
  const { navigatePath, children, buttonClassName } = props;
  const navigate = useNavigate();

  return (
    <button
      type="submit"
      onClick={() => navigate(navigatePath)}
      className={`${buttonClassName} hover:text-white hover:bg-lightGreen-200 text-lightGreen-200`}
    >
      <span className="text-lg font-w6 leading-relaxed text-sm">
        {children}
      </span>
    </button>
  );
};
