import React from "react";

import { useFetchStressCheckList } from "../../../hooks/stressChecks/useFetchStressCheckList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { StressCheckList } from "../../templates/stressChecks/StressCheckList";

export const StressChecks = () => {
  const { isLoading, stressCheckList } = useFetchStressCheckList();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <StressCheckList stressCheckList={stressCheckList} />
      )}
    </>
  );
};
