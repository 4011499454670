import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getStressCheckReport } from "../../api/apiStressCheckManagement";
import { CompanyReportResponseType } from "../../types/api/stressCheckManagement";

export const useFetchStressCheckReport = () => {
  const params = useParams();
  const stressCheckId = params.id;
  const navigate = useNavigate();

  const [companyReport, setCompanyReport] =
    useState<CompanyReportResponseType>();

  const fetchStressCheckReport = useCallback(async () => {
    const res = await getStressCheckReport(stressCheckId);

    if (res.status !== 200) {
      alert("全社分析レポートのデータ取得に失敗しました");
      return navigate(`/stress-checks-management/${stressCheckId}`);
    }

    setCompanyReport(res.data.data);
  }, []);

  useEffect(() => {
    fetchStressCheckReport();
  }, []);

  return {
    companyReport,
  };
};
