import React, { FC, memo } from "react";

export const ReaderStressCheckTargetEmployeesTable = memo(
  (props: {
    employeeNo: string;
    lastName: string;
    firstName: string;
    answered: boolean;
  }) => {
    const { employeeNo, lastName, firstName, answered } = props;

    return (
      <tbody>
        <tr className="text-lightGreen-200 border-b hover:bg-lightGreen-100 h-24">
          <td className="truncate max-w-0">
            <span title={employeeNo}>{employeeNo}</span>
          </td>
          <td className="text-lightGreen-200 truncate max-w-0">
            {lastName} {firstName}
          </td>
          <td className="truncate max-w-0 text-center">
            <p className="text-lg font-w6 leading-relaxed">
              {answered ? "回答済み" : "未回答"}
            </p>
          </td>
        </tr>
      </tbody>
    );
  }
);
