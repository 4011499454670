import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useOffice } from "../../../hooks/offices/useOffice";
import { OfficeRequestType } from "../../../types/api/office";
import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { OfficeEditComplete } from "../../templates/offices/OfficeEditComplete";
import { OfficeEditForm } from "../../templates/offices/OfficeEditForm";
import { Sidebar } from "../../templates/sidebar/Sidebar";

export const OfficeEdit = memo(() => {
  const { isComplete, updateDetailOffice } = useOffice();
  const [updateRequestData, setUpdateRequestData] = useState<OfficeRequestType>(
    {
      name: "",
      address: "",
      postcode: "",
      phone_number: "",
    }
  );
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const params = useParams();
  const officeId = params.id;

  const updateOffice = () => {
    updateDetailOffice(officeId, updateRequestData);
  };

  // スクロール時にscrollIntoViewを使用するためにて定義してる
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isPreviewVisible, isComplete]);

  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div
          className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9"
          ref={scrollBottomRef}
        >
          {!isComplete ? (
            <OfficeEditForm
              updateOffice={updateOffice}
              setUpdateRequestData={setUpdateRequestData}
              officeId={officeId}
              isPreviewVisible={isPreviewVisible}
              setIsPreviewVisible={setIsPreviewVisible}
            />
          ) : (
            <OfficeEditComplete />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
});
