import React, { memo } from "react";

import EmployeeIcon from "../../../images/employee.png";
import ListIcon from "../../../images/list-icon.png";
import GridIcon from "../../../images/grid-icon.png";
import { Title } from "../../atoms/commons/Title";
import { EmployeeCard } from "../../atoms/employees/EmployeeCard";
import { cardThemeSwitch } from "../../../utils/cardThemeSwitch";
import { PastEmployeeType } from "../../../types/api/organizationHistory";
import { Paginate } from "../Paginate";

export const PastEmployeeGrid = memo(
  (props: {
    employeeList: PastEmployeeType[];
    screenSwitchHandle: () => void;
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
  }) => {
    const { employeeList, screenSwitchHandle, paginateObj } = props;

    return (
      <>
        {employeeList.length !== 0 ? (
          <>
            <div className="flex justify-between min-h-147">
              <Title src={EmployeeIcon}>社員紹介</Title>
              <div className="flex mt-14">
                <button
                  onClick={() => screenSwitchHandle()}
                  className="flex w-32 h-10 py-2.5 pl-5 pr-4 rounded-lg relative"
                >
                  <img className="w-[21px] h-[20px]" src={ListIcon} />
                  <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                    リスト
                  </span>
                </button>
                <div className="flex w-36 h-10 py-2.5 pl-5 pr-4 rounded-lg relative bg-lightGreen-200 bg-opacity-10">
                  <img className="w-[21px] h-[20px]" src={GridIcon} />
                  <span className="block text-center pl-2 font-bold text-lightGreen-200 text-lg absolute top-1.5 right-3.5">
                    グリッド
                  </span>
                </div>
              </div>
            </div>
            {/****************** 4月リリース時は非表示のためコメントアウト ****************************/}
            {/* <div className="h-12 mt-20 md:flex md:justify-between">
        <p className="text-md leading-relaxed ml-14 md:text-center py-2">
          1 - {offset + perPage} 人 / 全 {employees.length}人中
        </p>
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          表示人数
        </p>
        <CountSelectForm />
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          検索
        </p>
        <EmployeeSearchForm />
        <SearchButton />
      </div> */}
            {/*********************************************************************************/}

            <div className="mt-5 pt-10 w-full grid md:grid-cols-1 mg:grid-cols-2 lg:grid-cols-3 gap-7 border-t">
              {employeeList.map((employee) => (
                <EmployeeCard
                  key={employee.id}
                  id={employee.id}
                  businessName={employee.businessName}
                  businessNameKana={employee.businessNameKana}
                  departmentName={employee.departmentName}
                  cardTheme={cardThemeSwitch(employee.cordColor)}
                  profileImage={employee.profileImage}
                />
              ))}
            </div>
            <Paginate
              handlePageChange={paginateObj.handlePageChange}
              offset={paginateObj.offset}
              perPage={50}
              listLength={employeeList.length}
              hight={40}
            />
          </>
        ) : (
          <>
            <div className="border-b pb-10">
              <Title src={EmployeeIcon}>社員紹介</Title>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-screen static">
              <span className="font-bold text-3xl text-lightGreen-200 absolute top-1/2 right-1/5">
                対象のユーザーは存在しません。
              </span>
            </div>
          </>
        )}
      </>
    );
  }
);
