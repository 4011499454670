import { useCallback, useState, useEffect } from "react";

import { getDepartmentList } from "../../api/apiDepartment";
import { OrganizationListResponseType } from "../../types/api/department";

export const useFetchDepartmentList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [departmentList, setDepartmentList] =
    useState<OrganizationListResponseType>({
      departments: [
        {
          id: 0,
          parent_id: 0,
          abolition: false,
          name: "",
        },
      ],
    });

  const fetchOrganizationList = useCallback(async () => {
    const res = await getDepartmentList();
    if (res.status !== 201) {
      setIsLoading(false);
      return alert("部署一覧のデータ取得に失敗しました");
    }
    setIsLoading(false);
    setDepartmentList(res.data.data);
  }, []);

  useEffect(() => {
    fetchOrganizationList();
  }, []);

  return {
    isLoading,
    departmentList,
    fetchOrganizationList,
  };
};
