import React from "react";
import {
  Routes,
  Route,
  RouteProps,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";

import { Login } from "../components/pages/auth/Login";
import { Common } from "../components/templates/Common";
import { ErrorPage } from "../components/pages/ErrorPage";
import { AxiosInterceptors } from "../api/axiosInterceptors";
import { useLoginUser } from "../hooks/useLoginUser";
import { StressChecks } from "../components/pages/stressChecks/StressChecks";
import { EmployeeTasks } from "../components/pages/employmentRetirementTasks/EmployeeTasks";
import { TaskLists } from "../components/pages/employmentRetirementTasks/TaskLists";
import { StressCheckToAnswerSheet } from "../components/pages/stressChecks/StressCheckToAnswerSheet";
import { StressCheckSelection } from "../components/pages/stressCheckMaintenance/StressCheckSubjectSelection";
import { StressCheckMaintenance } from "../components/pages/stressCheckMaintenance/StressCheckMaintenance";
import { StressCheckToEmployeesManagement } from "../components/pages/stressCheckMaintenance/StressCheckToEmployeesManagement";
import { Offices } from "../components/pages/offices/Offices";
import { OfficeCreate } from "../components/pages/offices/OfficeCreate";
import { Contacts } from "../components/pages/contacts/Contacts";
import { StressChecksResult } from "../components/pages/stressChecks/StressChecksResult";
import { SendMailSelection } from "../components/pages/stressCheckMaintenance/SendMailSelection";
import { SendMailConfirm } from "../components/pages/stressCheckMaintenance/SendMailConfirm";
import { InterviewGuidanceEdit } from "../components/pages/stressCheckMaintenance/InterviewGuidanceEdit";
import { OfficeEdit } from "../components/pages/offices/OfficeEdit";
import { InterviewSubjectSelection } from "../components/pages/stressCheckMaintenance/InterviewSubjectSelection";
import { PasswordChange } from "../components/pages/auth/PasswordChange";
import { PasswordReset } from "../components/pages/auth/PasswordReset";
import { LoadingPage } from "../components/pages/LoadingPage";
import { useLoadingCheck } from "../hooks/useLoadingUser";
import { EmployeeMaintenance } from "../components/pages/employeeMaintenance/EmployeeMaintenance";
import { EmployeeMaintenanceCreate } from "../components/pages/employeeMaintenance/EmployeeMaintenanceCreate";
import { EmployeeMaintenanceEdit } from "../components/pages/employeeMaintenance/EmployeeMaintenanceEdit";
import { Employees } from "../components/pages/employees/Employees";
import { EmployeesProfile } from "../components/pages/employees/EmployeeProfile";
import { EmployeesProfileEdit } from "../components/pages/employees/EmployeeProfileEdit";
import { DepartmentTree } from "../components/pages/departments/DepartmentTree";
import { DepartmentLists } from "../components/pages/departments/DepartmentLists";
import { DepartmentTreeHistories } from "../components/pages/histories/DepartmentTreeHistories";
import { EmployeeRolls } from "../components/pages/settings/EmployeeRolls";
import { EmployeeRollUpdate } from "../components/pages/settings/EmployeeRollUpdate";
import { DepartmentPastTrees } from "../components/pages/histories/DepartmentPastTrees";
import { DepartmentTransition } from "../components/pages/histories/DepartmentTransition";
import { StressCheckTargetEmployees } from "../components/pages/stressCheckMaintenance/StressCheckTargetEmployees";
import { PastEmployees } from "../components/pages/histories/DepartmentPastEmployees";
import { CompanyAnalytics } from "../components/pages/stressCheckMaintenance/CompanyAnalytics";
import { GroupAnalytics } from "../components/pages/stressCheckMaintenance/GroupAnalytics";

// ログイン情報が必須の場合
const PrivateRoute: React.FC<RouteProps> = () => {
  const { loginUser } = useLoginUser();
  const location = useLocation();
  return loginUser ? <Outlet /> : <Navigate to="/" state={location.pathname} />;
};

// ログイン情報が不要の場合
const PublicRoute: React.FC<RouteProps> = () => {
  const { loginUser } = useLoginUser();
  const location = useLocation();
  let pathName = location.state;
  // ログインページからhomeページに遷移する瞬間は、pathNameがnullなのでhomeページ(merchants)に遷移させる
  if (pathName == null) {
    pathName = "employees";
  }
  return loginUser ? <Navigate to={`${pathName}`} /> : <Login />;
};

export const Router = () => {
  const { loading } = useLoadingCheck();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <AxiosInterceptors />
      <Routes>
        <Route path="/" element={<PublicRoute />} />
        <Route path="/password/reset" element={<PasswordReset />} />
        <Route path="/employees" element={<PrivateRoute />}>
          <Route path="/employees" element={<Common>{<Employees />}</Common>} />
          <Route
            path="/employees?department_id=:department_id"
            element={<Common>{<Employees />}</Common>}
          />
          <Route
            path="/employees/detail/:employee_id"
            element={<EmployeesProfile />}
          />
          <Route
            path="/employees/detail/:employee_id/edit"
            element={<EmployeesProfileEdit />}
          />
        </Route>
        <Route path="/employees-management" element={<PrivateRoute />}>
          <Route
            path="/employees-management"
            element={<Common>{<EmployeeMaintenance />}</Common>}
          />
          <Route
            path="/employees-management/create"
            element={<EmployeeMaintenanceCreate />}
          />
          <Route
            path="/employees-management/detail/:id"
            element={<EmployeeMaintenanceEdit />}
          />
        </Route>
        <Route path="/stress-checks" element={<PrivateRoute />}>
          <Route
            path="/stress-checks"
            element={<Common>{<StressChecks />}</Common>}
          />
          <Route
            path="/stress-checks/:stressCheckId/answer"
            element={<Common>{<StressCheckToAnswerSheet />}</Common>}
          />
          <Route
            path="/stress-checks/:stressCheckId/result"
            element={<Common>{<StressChecksResult />}</Common>}
          />
        </Route>
        <Route path="/stress-checks-management" element={<PrivateRoute />}>
          <Route
            path="/stress-checks-management"
            element={<Common>{<StressCheckMaintenance />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id"
            element={<Common>{<StressCheckToEmployeesManagement />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id/subjects"
            element={<Common>{<StressCheckSelection />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id/interviews"
            element={<Common>{<InterviewSubjectSelection />}</Common>}
          />
          <Route
            path="/stress-checks-management/:stressCheckId/sendmail"
            element={<Common>{<SendMailSelection />}</Common>}
          />
          <Route
            path="/stress-checks-management/:stressCheckId/sendmail/:mailType"
            element={<Common>{<SendMailConfirm />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id/employees"
            element={<Common>{<StressCheckTargetEmployees />}</Common>}
          />
          <Route
            path="/stress-checks-management/:stressCheckId/interview-guidance/edit"
            element={<Common>{<InterviewGuidanceEdit />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id/company-analytics"
            element={<Common>{<CompanyAnalytics />}</Common>}
          />
          <Route
            path="/stress-checks-management/:id/group-analytics"
            element={<Common>{<GroupAnalytics />}</Common>}
          />
        </Route>
        <Route path="/offices" element={<PrivateRoute />}>
          <Route path="/offices" element={<Common>{<Offices />}</Common>} />
          <Route path="/offices/create" element={<OfficeCreate />} />
          <Route path="/offices/detail/:id" element={<OfficeEdit />} />
        </Route>
        <Route path="/doctor-collaboration" element={<PrivateRoute />}>
          <Route
            path="/doctor-collaboration"
            element={<Common>{<StressChecks />}</Common>}
          />
        </Route>
        <Route path="/contacts" element={<PrivateRoute />}>
          <Route path="/contacts" element={<Common>{<Contacts />}</Common>} />
        </Route>
        <Route path="/departments" element={<PrivateRoute />}>
          <Route
            path="/departments"
            element={<Common>{<DepartmentTree />}</Common>}
          />
          <Route
            path="/departments/histories"
            element={<Common>{<DepartmentTreeHistories />}</Common>}
          />
          <Route
            path="/departments/histories/:id"
            element={<Common>{<DepartmentPastTrees />}</Common>}
          />
          <Route
            path="/departments/histories/:history_frame_id/:id/pastemployees"
            element={<Common>{<PastEmployees />}</Common>}
          />
          <Route
            path="/departments/transition"
            element={<Common>{<DepartmentTransition />}</Common>}
          />
          <Route
            path="/departments/list"
            element={<Common>{<DepartmentLists />}</Common>}
          />
        </Route>
        <Route path="/employment-retirement-tasks" element={<PrivateRoute />}>
          <Route
            path="/employment-retirement-tasks"
            element={<Common>{<EmployeeTasks />}</Common>}
          />
          <Route
            path="/employment-retirement-tasks/tasklist"
            element={<Common>{<TaskLists />}</Common>}
          />
        </Route>
        <Route path="/settings" element={<PrivateRoute />}>
          <Route
            path="/settings"
            element={<Common>{<EmployeeRolls />}</Common>}
          />
          <Route
            path="/settings/employee-roll/update"
            element={<Common>{<EmployeeRollUpdate />}</Common>}
          />
        </Route>
        <Route path="/password/change" element={<PrivateRoute />}>
          <Route path="/password/change" element={<PasswordChange />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};
