import React, { FC, memo } from "react";
import { Link } from "react-router-dom";

import EmployeeIcon from "../../../images/figure-icon.png";
import EmployeesIcon from "../../../images/employee.png";

type Props = {
  // TODO：ここのanyはちゃんと型定義してあげたい。
  tree: Array<any>;
  onClickEditDepartment: (id: number) => void;
  userRoll: string | undefined;
  submitCheckAlert: (id: number) => void;
};

export const TreeObj: FC<Props> = memo((props) => {
  const { submitCheckAlert, tree, onClickEditDepartment, userRoll } = props;

  return (
    <>
      <div className="ml-20">
        {tree.map((child, key) => (
          <div key={child.id}>
            <div className="flex relative">
              <div
                className={
                  key === 0
                    ? "before:content-[''] before:inline-block before:h-[85px] before:w-[80px] before:absolute before:top-[28px] before:left-[-80px] before:border-lightGreen-200 before:border-t"
                    : "before:content-[''] before:inline-block before:h-[85px] before:w-[40px] before:absolute before:top-[28px] before:left-[-40px] before:border-lightGreen-200 before:border-t"
                }
              ></div>
              <div
                className={
                  tree.length === key + 1
                    ? ""
                    : "before:content-[''] before:inline-block before:h-full before:w-[80px] before:absolute before:top-[28px] before:left-[-40px] before:border-lightGreen-200 before:border-l"
                }
              >
                <div className="min-w-[300px] max-w-[300px]">
                  <div className="py-[10px] flex justify-center items-center border rounded-lg border-lightGreen-200">
                    <button
                      type="button"
                      onClick={() => onClickEditDepartment(child.id)}
                      className={
                        userRoll === "01"
                          ? "flex items-center hover:underline"
                          : "flex items-center pointer-events-none"
                      }
                    >
                      <img
                        className="w-10 min-w-10"
                        src={
                          child.employee_numbers > 1
                            ? EmployeesIcon
                            : EmployeeIcon
                        }
                      ></img>
                      <span className="ml-[5px] text-left min-w-[173px] w-[173px] text-lightGreen-200 text-[16px] font-semibold leading-5">
                        {child.name}
                      </span>
                    </button>
                    <Link to={`/employees?department_id=` + `${child.id}`}>
                      <div className="ml-[5px] min-w-[60px] py-[5px] text-center rounded-lg bg-lightGreen-100 hover:opacity-50">
                        <span className="text-lightGreen-200 font-semibold text-[18px] leading-5">
                          {child.employee_numbers}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="pb-10"></div>
              </div>
              <div>
                {child.children === null ? (
                  ""
                ) : (
                  <TreeObj
                    tree={child.children}
                    onClickEditDepartment={onClickEditDepartment}
                    userRoll={userRoll}
                    submitCheckAlert={submitCheckAlert}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});
