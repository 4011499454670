import { useCallback, useState } from "react";

import { postDepartment, putDepartmentResume } from "../../api/apiDepartment";
import { postOrganizationHistoryList } from "../../api/apiHistory";
import { DepartmentResumeResponseType } from "../../types/api/department";

export const useSaveDepartment = () => {
  // 部署を登録
  const saveDepartment = useCallback(async (department) => {
    const res = await postDepartment(department);
    if (res.status !== 201) {
      return alert("部署の登録に失敗しました");
    }
    return alert("部署の登録に成功しました");
  }, []);

  //  部署再開
  const saveDepartmentResume = useCallback(
    async (parentId: DepartmentResumeResponseType, departmentId: number) => {
      const res = await putDepartmentResume(parentId, departmentId);
      if (res.status !== 200) {
        return alert("部署の再開に失敗しました");
      }
      return alert("部署の再開に成功しました");
    },
    []
  );

  {
    /********************************************************
     * TODO: これは別の処理なので、hooksファイルとして切り出したい。
     *******************************************************/
  }
  const saveDepartmentHistory = useCallback(async () => {
    const res = await postOrganizationHistoryList();
    if (res.data.code === "EMPLOYEE_OFFICE_UNDEFINED_ERROR") {
      return alert("事業場未設定の従業員が存在します");
    } else if (res.status !== 201) {
      return alert("組織図保存に失敗しました");
    }

    return alert("組織図保存に成功しました");
  }, []);

  return {
    saveDepartment,
    saveDepartmentHistory,
    saveDepartmentResume,
  };
};
