import React, { FC, memo } from "react";
import {
  FieldError,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import {
  TaskListRequestType,
  TaskListResponseType,
} from "../../../types/api/EmployeeTask";
import EmploymentRetirement from "../../../images/employment-retirement.png";
import { ModalDisplay } from "../../atoms/commons/ModalDisplay";
import { ModalTaskCreateForm } from "../../atoms/employmentRetirement/ModalTaskCreateForm";
import { OpenDepartmentModalButton } from "../../atoms/departments/OpenModalDepartmentButton";

type Props = {
  taskListObj: {
    isListTable: boolean;
    screenSwitchHandle: () => void;
    isLoading: boolean;
    taskList: TaskListResponseType;
  };
  postTaskListObj: {
    onChangeCreateModalFlag: () => void;
    saveTask: (data: TaskListRequestType) => Promise<void>;
    showCreateModalFlag: boolean;
    register: UseFormRegister<TaskListRequestType>;
    handleSubmit: UseFormHandleSubmit<TaskListRequestType>;
    setValue: UseFormSetValue<TaskListRequestType>;
    formState: {
      errors: {
        status?: FieldError | undefined;
        name?: FieldError | undefined;
      };
    };
  };
};

export const EmploymentTaskList: FC<Props> = memo((props) => {
  const { taskListObj, postTaskListObj } = props;
  return (
    <div>
      <ModalDisplay showModal={postTaskListObj.showCreateModalFlag}>
        <ModalTaskCreateForm
          onChangeModalFlag={postTaskListObj.onChangeCreateModalFlag}
          saveTask={postTaskListObj.saveTask}
          register={postTaskListObj.register}
          handleSubmit={postTaskListObj.handleSubmit}
          setValue={postTaskListObj.setValue}
          formState={postTaskListObj.formState}
        />
      </ModalDisplay>
      <div className="flex content-center justify-between">
        <div className="flex">
          <img className="h-16 mt-14" src={EmploymentRetirement} />
          <span className="text-3xl font-bold leading-relaxed text-lightGreen-200 block mt-16 ml-4">
            入退職 タスク一覧
          </span>
        </div>
        <div className="flex">
          <OpenDepartmentModalButton
            onChangeModalFlag={postTaskListObj.onChangeCreateModalFlag}
            name="タスク登録"
            modalClassName="w-32 border-lightGreen-200 border py-2 px-4 rounded-lg mr-5 mt-14 text-lg font-bold"
          />
        </div>
      </div>
      <span className="w-full py-11 text-sm inline-block border-b">
        項目として表示させる場合は、チェックを入れてください。
      </span>
      <div className="w-full py-9 text-xl border-b pl-5">
        <div className="flex font-bold text-lightGreen-200">
          <div className="mr-6 px-8 py-3 bg-lightGreen-100 rounded-lg">
            入社タスク
          </div>
          <span className="border-r-2"></span>
          <button
            onClick={() => taskListObj.screenSwitchHandle()}
            className="ml-6 px-8 py-3"
          >
            退職タスク
          </button>
        </div>
      </div>
      <div className="pb-10">
        <span className="w-full border-b text-lightGreen-200 font-bold py-8 inline-block">
          タスク一覧
        </span>

        {taskListObj.taskList.employment.tasks.map((task) => {
          return [
            <div
              key={task.id}
              className="flex justify-between items-center  border-b py-6 first:border-t"
            >
              <div className="flex justify-between items-center">
                {task.display === true ? (
                  <input
                    defaultChecked={true}
                    type="checkbox"
                    className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block border-lightGreen-200"
                  />
                ) : (
                  <input
                    type="checkbox"
                    className="accent-lightGreen-300 cursor-pointer transform scale-150 inline-block border-lightGreen-200"
                  />
                )}
                <span className="pl-5">{task.name}</span>
              </div>
              <button className="w-[72px] text-lightGreen-200 border-lightGreen-200 border py-2 px-4 rounded-lg text-center text-lg font-bold hover:text-white hover:bg-lightGreen-200 text-lightGreen-200">
                削除
              </button>
            </div>,
          ];
        })}
      </div>
    </div>
  );
});
