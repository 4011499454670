import React, { FC } from "react";

type Props = {
  cx: number;
  cy: number;
  stroke: string;
};

export const CustomizedDotOverall: FC<Props> = (props) => {
  const { cx, cy, stroke } = props;

  return (
    <svg x={cx - 15} y={cy - 15} width={30} height={30} fill="green">
      <circle
        stroke={stroke}
        strokeWidth="3"
        cx="15"
        cy="15"
        r="13"
        fill="#FADD9F"
      />
    </svg>
  );
};
