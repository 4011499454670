import React, { FC } from "react";

type Props = {
  onClickNavButton: (mailType: string) => void;
  children: string;
  buttonFlag: boolean | undefined;
};

export const SendMailButton: FC<Props> = (props) => {
  const { onClickNavButton, children, buttonFlag } = props;

  return (
    <>
      <button
        onClick={() => onClickNavButton(children)}
        className={
          !buttonFlag
            ? "flex justify-center items-center border border-lightGray-200 rounded-md px-[160px] py-[25px]"
            : "flex justify-center items-center border border-lightGreen-200 rounded-md px-[160px] py-[25px]"
        }
        disabled={!buttonFlag}
      >
        <span
          className={
            !buttonFlag
              ? "font-bold text-2xl text-lightGray-200"
              : "font-bold text-2xl text-lightGreen-200"
          }
        >
          {children}
        </span>
      </button>
    </>
  );
};
