import React, { memo } from "react";

import { useFetchDepartmentTransition } from "../../../hooks/histories/useFetchDepartmentTransition";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { DepartmentTransitionList } from "../../templates/histories/DepartmentTransitionList";

export const DepartmentTransition = memo(() => {
  const { isLoading, transitionList } = useFetchDepartmentTransition();
  const HistoryDate = transitionList.organization_history_frames;
  const HistoryRecords = transitionList.department_history_records;

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <DepartmentTransitionList
          HistoryDate={HistoryDate}
          HistoryRecords={HistoryRecords}
        />
      )}
    </>
  );
});
