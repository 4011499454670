import React, { FC } from "react";

type Props = {
  onChangeModalFlag: () => void;
  name: string;
  modalClassName: string;
};

export const OpenDepartmentModalButton: FC<Props> = (props) => {
  const { onChangeModalFlag, name, modalClassName } = props;
  return (
    <button
      onClick={onChangeModalFlag}
      className={`${modalClassName} hover:text-white hover:bg-lightGreen-200 text-lightGreen-200 text-lg`}
    >
      <span className="text-lg font-w6 leading-relaxed">{name}</span>
    </button>
  );
};
