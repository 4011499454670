import React, { memo } from "react";

import { useFetchAdminReaderList } from "../../../hooks/settings/useFetchAdminReaderList";
import { usePaginate } from "../../../hooks/usePaginate";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { EmployeeRollList } from "../../templates/settings/EmployeeRollList";

export const EmployeeRolls = memo(() => {
  const { isLoading, adminReaderList } = useFetchAdminReaderList();
  const paginateObj = usePaginate();

  if (isLoading) {
    return <LoadingTemplate />;
  }

  return (
    <>
      <EmployeeRollList
        adminReaderList={adminReaderList}
        paginateObj={paginateObj}
      />
    </>
  );
});
