import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import ExcellentIcon from "../../images/excellent-2.png";
import GoodIcon from "../../images/good-2.png";
import UsuallyIcon from "../../images/usually-2.png";
import BadIcon from "../../images/bad-2.png";
import WorstIcon from "../../images/worst-2.png";
import { GroupAnalyticsReportResponseType } from "../../types/api/groupAnalytics";
import { getGroupAnalyticsReportList } from "../../api/apiGroupAnalyticsReport";
import {
  provisionalDepartmentList,
  provisionalOfficeList,
} from "../../utils/provisionalGroupAnalyticsList";

export const useGroupAnalytics = () => {
  const [groupAnalyticsList, setGroupAnalyticsList] =
    useState<GroupAnalyticsReportResponseType>();
  const navigate = useNavigate();

  const faceIconHandleChange = (evaluate: number, thresholds: number[][]) => {
    if (thresholds[0][0] <= evaluate && evaluate <= thresholds[0][1]) {
      return WorstIcon;
    } else if (thresholds[1][0] <= evaluate && evaluate <= thresholds[1][1]) {
      return BadIcon;
    } else if (thresholds[2][0] <= evaluate && evaluate <= thresholds[2][1]) {
      return UsuallyIcon;
    } else if (thresholds[3][0] <= evaluate && evaluate <= thresholds[3][1]) {
      return GoodIcon;
    } else if (thresholds[4][0] <= evaluate && evaluate <= thresholds[4][1]) {
      return ExcellentIcon;
    } else {
      return ExcellentIcon;
    }
  };

  const isCautionScore = (score: number, thresholds: number[][]) => {
    if (score < thresholds[0][0] || thresholds[1][1] < score) {
      return "w-[72px] h-[26px] y-[30px] block m-auto inline-block whitespace-nowrap";
    } else if (thresholds[0][0] <= score && score <= thresholds[0][1]) {
      return "w-[72px] h-[26px] y-[30px] block m-auto inline-block font-bold text-white bg-cautionColor-red whitespace-nowrap";
    } else if (thresholds[1][0] <= score && score <= thresholds[1][1]) {
      return "w-[72px] h-[26px] y-[30px] block m-auto inline-block font-bold text-white bg-cautionColor-pink whitespace-nowrap]";
    }
  };

  // 集団分析レポート取得（バックエンド状況により修正）
  const fetchGroupAnalyticsReport = useCallback(
    async (
      stressCheckId: string | undefined,
      tabName: string,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      setLoading(true);
      // タブの名前からapiリクエスト先を変更
      const switchGroup = (tabName: string) => {
        switch (tabName) {
          case "部署":
            return "department";
          case "性別":
            return "sex";
          case "役職":
            return "position";
          case "職種":
            return "occupation";
          default:
            return "office";
        }
      };

      // API実装後調整
      // const res = await getGroupAnalyticsReportList(
      //   stressCheckId,
      //   switchGroup(tabName)
      // );

      // if (res.status !== 200) {
      //   alert("集団分析レポートの一覧データ取得に失敗しました");
      //   setLoading(false);
      //   return navigate(`/stress-checks-management/${stressCheckId}`);
      // }

      // setGroupAnalyticsList(res.data.data);
      // setLoading(false)

      // 擬似的な読み込み状態&データ取得（削除予定）
      setTimeout(() => {
        setLoading(false);
        if (tabName === "部署") {
          setGroupAnalyticsList(provisionalDepartmentList);
        } else {
          setGroupAnalyticsList(provisionalOfficeList);
        }
      }, 200);
    },
    []
  );

  return {
    faceIconHandleChange,
    isCautionScore,
    fetchGroupAnalyticsReport,
    groupAnalyticsList,
  };
};
