import { useCallback, useState } from "react";

import {
  getStressCheckDisclosureResponse,
  putStressCheckDisclosureResponse,
} from "../../api/apiStressCheck";
import { StressCheckDisclosureResponseType } from "../../types/api/stressCheck";

export const useStressCheckDisclosureResponse = () => {
  const [disclosureResponse, setDisclosureResponse] =
    useState<StressCheckDisclosureResponseType>("");

  const fetchStressCheckDisclosureResponse = useCallback(
    async (stress_check_frame_id: number) => {
      const res = await getStressCheckDisclosureResponse(stress_check_frame_id);

      if (res.status !== 200) {
        return alert(
          "ストレスチェックの情報開示同意ステータスの取得に失敗しました"
        );
      }

      setDisclosureResponse(res.data.disclosure_response);
    },
    []
  );

  const updateStressCheckDisclosureResponse = useCallback(
    async (data: {
      stress_check_frame_id: number;
      disclosure_response: string;
    }) => {
      const stringData = {
        stress_check_frame_id: data.stress_check_frame_id.toString(),
        disclosure_response: data.disclosure_response,
      };
      const res = await putStressCheckDisclosureResponse(stringData);
      if (res.status !== 204) {
        return alert("ストレスチェックの情報開示同意情報の登録に失敗しました");
      }
    },
    []
  );

  return {
    disclosureResponse,
    fetchStressCheckDisclosureResponse,
    updateStressCheckDisclosureResponse,
  };
};
