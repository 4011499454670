// 事業所情報の取得
type Department = {
  department_id: number;
  name: string;
  responsibility_type: 0 | 1;
  concurrent: boolean;
};

const combinationEmployeeDepartmentList = (departments: Department[]): string =>
  departments
    .sort((a, b) => {
      if (a.concurrent === true && b.concurrent === false) {
        return 1;
      } else {
        return -1;
      }
    })
    .reduce((n, m, i) => {
      if (i === 0) {
        return `${n}${m.name}`;
      } else {
        return `${n}/${m.name}(兼任)`;
      }
    }, "");

export default combinationEmployeeDepartmentList;
