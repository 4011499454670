import React, { useState } from "react";

export const useBirthday = () => {
  const [age, setAge] = useState(""); // birthYear, Month, Dayをまとめたもの
  const [birthYear, setBirthYear] = useState(new Date().getFullYear());
  const [birthMonth, setBirthMonth] = useState(new Date().getMonth() + 1);
  const [birthDay, setBirthDay] = useState("");

  // 生年月日入力欄生成
  const setYear = () => {
    // eslint-disable-next-line
    let list = [];
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      list.push(i);
    }
    return list;
  };
  const setMonth = () => {
    // eslint-disable-next-line
    let list = [];
    for (let i = 1; i <= 12; i++) {
      list.push(i);
    }
    return list;
  };
  const setDay = () => {
    // eslint-disable-next-line
    let list = [];
    const lastDay = new Date(
      Number(birthYear),
      Number(birthMonth),
      0
    ).getDate();
    for (let i = 1; i <= lastDay; i++) {
      list.push(i);
    }
    return list;
  };

  return {
    age,
    setYear,
    setMonth,
    setDay,
    birthYear,
    birthMonth,
    birthDay,
  };
};
