import React, { FC, memo } from "react";

type Props = {
  children: string;
};

export const StressCheckToAnswerHeading: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <>
      <div className="section flex justify-center items-center wx-auto h-[100px] mb-[40px] border border-lightGreen-100 bg-lightGreen-100">
        <span className="font-bold text-2xl text-lightGreen-200">
          {children}
        </span>
      </div>
    </>
  );
});
