import React, { memo, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import EmployeeManagement from "../../../images/employee.png";
import { NavigateButton } from "../../atoms/commons/NavigateButton";
import EmployeeIcon from "../../../images/figure-icon.png";
import EmployeesIcon from "../../../images/figure-icon.png";
import { HistoryTreeObj } from "../../atoms/departments/HistoryTreeObj";
import { DepartmentHistoryTreeOutputType } from "../../../types/api/organizationHistory";

export const DepartmentPastTree = memo(
  (props: {
    DepartmentPastObject: {
      pastHistoryId: string | undefined;
      loading: boolean;
      setLoading: React.Dispatch<React.SetStateAction<boolean>>;
      pastHistoryList: DepartmentHistoryTreeOutputType;
      fetchPastOrganizationHistoryList: (
        organizationFrameId: string | undefined,
        setLoading: React.Dispatch<React.SetStateAction<boolean>>
      ) => Promise<void | {
        pastHistoryList: DepartmentHistoryTreeOutputType;
      }>;
    };
  }) => {
    const { DepartmentPastObject } = props;
    const params = useParams();
    const departmentFrameId = params.id;
    useEffect(() => {
      DepartmentPastObject.fetchPastOrganizationHistoryList(
        DepartmentPastObject.pastHistoryId,
        DepartmentPastObject.setLoading
      );

      if (DepartmentPastObject.pastHistoryList.id !== 0) {
      }
    }, [DepartmentPastObject.loading]);
    return (
      <>
        <div className="flex content-center justify-between">
          <div>
            <Title src={EmployeeManagement}>組織図</Title>
          </div>
        </div>
        <div className="flex py-7 border-y mt-[35px]">
          <span className="font-bold text-lg text-lightGreen-200">組織図</span>
          <span className="text-lg pl-20">
            {DepartmentPastObject.pastHistoryList.save_date}
          </span>
        </div>
        {DepartmentPastObject.pastHistoryList.department_history_trees.map(
          (parent) => (
            <div key={parent.id}>
              <div className="py-[20px] h-[800px] sticky overflow-x-scroll border-b border-lightGray-200">
                <div className="absolute">
                  <div className="flex relative">
                    <div>
                      <div className="min-w-[300px] max-w-[300px]">
                        <div className="py-[10px] flex justify-center items-center border rounded-lg border-lightGreen-200">
                          <div className="flex items-center">
                            <img
                              className="w-10 min-w-10"
                              src={
                                parent.employee_numbers > 1
                                  ? EmployeesIcon
                                  : EmployeeIcon
                              }
                            ></img>
                            <span className="ml-[5px] text-left min-w-[173px] w-[173px] text-lightGreen-200 text-[16px] font-semibold leading-5">
                              {parent.name}
                            </span>
                          </div>
                          <Link
                            to={`/departments/histories/${departmentFrameId}/${parent.id}/pastemployees`}
                          >
                            <div className="min-w-[60px] py-[5px] px-1 text-center rounded-lg bg-lightGreen-100 hover:opacity-50">
                              <span className="text-lightGreen-200 font-semibold text-[18px] leading-5">
                                {parent.employee_numbers}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {parent.children === null ? (
                      ""
                    ) : (
                      <HistoryTreeObj
                        tree={parent.children}
                        departmentFrameId={departmentFrameId}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </>
    );
  }
);
