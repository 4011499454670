import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { postStressCheckAnswers } from "../../api/apiStressCheck";

export const useSaveStressCheckAnswers = () => {
  const navigate = useNavigate();

  const saveStressCheckAnswers = useCallback(async (stressCheckId, data) => {
    const res = await postStressCheckAnswers(stressCheckId, data);
    if (res.status === 200) {
      alert("ストレスチェック回答データ登録に成功しました");
      navigate(`/stress-checks/${stressCheckId}/result`);
    } else {
      alert("ストレスチェック回答データ登録に失敗しました");
    }
  }, []);

  return {
    saveStressCheckAnswers,
  };
};
