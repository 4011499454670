import Employee from "../../../images/employee.png";
import StressCheck from "../../../images/stress-check.png";
import StressCheckManagement from "../../../images/stress-check-management.png";

export const ReaderSidebarList = [
  {
    id: 1,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/employees",
    children: "社員紹介",
    disable: false,
  },
  {
    id: 2,
    iconImage: StressCheck,
    iconAlt: "stress-check.png",
    path: "/stress-checks",
    children: "ストレスチェック",
    disable: false,
  },
  {
    id: 3,
    iconImage: StressCheckManagement,
    iconAlt: "stress-check-management.png",
    path: "/stress-checks-management",
    children: "ストレスチェック管理",
    disable: false,
  },
  {
    id: 4,
    iconImage: Employee,
    iconAlt: "employee.png",
    path: "/departments",
    children: "組織図",
    disable: false,
  },
];
